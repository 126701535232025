import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "d-flex align-center ga-8 w-100 ma-auto px-4",
  style: {"position":"relative"},
  id: "menu"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "d-flex align-center justify-space-between w-100" }
const _hoisted_4 = { class: "d-flex align-center" }

import { useRouter } from "vue-router";
import { ref, watch } from "vue";

import UserMenu from "./UserMenu/UserMenu.vue";
import AppsMenu from "./AppsMenu/AppsMenu.vue";

import imgShield from "@/assets/logo_shield.svg";

import { routeNames } from "@/router";
import { useTheme } from "vuetify/lib/framework.mjs";


export default /*@__PURE__*/_defineComponent({
  __name: 'OneMenu',
  props: {
    showLogo: { type: Boolean, default: true }
  },
  setup(__props: any) {

const router = useRouter();

const { name: themeName } = useTheme();


const appsMenu = ref(false);
const userMenu = ref(false);

const handleLogoClick = () => {
  if (window.scrollY > 0) {
    window.scrollTo({ top: 0, behavior: "smooth" });
  } else {
    router.push({ name: routeNames.transactionView });
  }
};

watch(appsMenu, () => {
  if (appsMenu.value) {
    userMenu.value = false;
  }
});

watch(userMenu, () => {
  if (userMenu.value) {
    appsMenu.value = false;
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("header", {
    class: _normalizeClass(
      (_unref(themeName) as string) === 'dark' ? 'bg-cs_surface_container_low' : 'bg-cs_on_primary'
    )
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.showLogo)
        ? _renderSlot(_ctx.$slots, "logo", { key: 0 }, () => [
            _createElementVNode("img", {
              src: _unref(imgShield),
              alt: "Clearsale Logo",
              class: "logo__img",
              height: "48px",
              onClick: handleLogoClick
            }, null, 8, _hoisted_2)
          ])
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _renderSlot(_ctx.$slots, "default", {}, () => [
            _cache[2] || (_cache[2] = _createElementVNode("div", null, null, -1))
          ])
        ]),
        _createElementVNode("nav", null, [
          _renderSlot(_ctx.$slots, "nav", {}, () => [
            _createElementVNode("div", _hoisted_4, [
              _renderSlot(_ctx.$slots, "links", {}, () => [
                _createVNode(AppsMenu, {
                  modelValue: appsMenu.value,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((appsMenu).value = $event))
                }, null, 8, ["modelValue"]),
                _createVNode(UserMenu, {
                  modelValue: userMenu.value,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((userMenu).value = $event))
                }, null, 8, ["modelValue"])
              ])
            ])
          ])
        ])
      ])
    ])
  ], 2))
}
}

})