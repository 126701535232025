<template>
  <ButtonFilterMobile
    :label="'Date'"
    :active="isValid"
    :height="40"
    @close-filter="closeBottomSheet"
    @clear-filter="clearAllFilter"
  >
    <div class="w-100 d-flex flex-column justify-start align-start">
      <h1 class="bg-on-surface text-left text-h5 mb-3">Date</h1>
      <ChipSelectable
        v-model="selectedTimePeriodMemory"
        :chips="timePeriodOptions"
        isMobile
      ></ChipSelectable>
    </div>
  </ButtonFilterMobile>
  <DatePicker
    v-if="dialogModel"
    v-model:dialog="dialogModel"
    :dates="datePickerModel"
    @cancel="cancelDatePicker"
    @submit="submitDatePicker"
    range
  />
</template>

<script setup lang="ts">
import ButtonFilterMobile from "@/components/shared/ButtonFilterMobile/ButtonFilterMobile.vue";
import ChipSelectable from "@/components/shared/ChipSelectable/ChipSelectable.vue";
import DatePicker from "@/components/shared/DatePicker/DatePicker.vue";

import { useButtonFilterDate } from "./useButtonFilterDateMobile";

const {
  isValid,
  dialogModel,
  datePickerModel,
  cancelDatePicker,
  submitDatePicker,
  submitButtonFilter,
  clearAllFilter,
  selectedTimePeriodMemory,
  timePeriodOptions,
} = useButtonFilterDate();

function closeBottomSheet() {
  submitButtonFilter();
}
</script>
