<template>
  <div
    class="transaction_card_list"
    :data-testid="generateTestId(TransactionCardListElement)"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
import { generateTestId } from "@/helpers/generateTestId";
import { onBeforeUpdate, onMounted } from "vue";
import { TransactionCardListElement } from "./constants/TransactionCardListId";
import { useDisplay } from "vuetify";

const { mobile } = useDisplay();

const emits = defineEmits<{ onLoadLastItem: [] }>();


let lastTransactionCardItem: Element | null = null;

onMounted(() => {
    trackNewElement();
    createOrUpdateObserver();
})

onBeforeUpdate(() => {
    trackNewElement();
    createOrUpdateObserver();
})

function trackNewElement() {
    let target = mobile.value ? '#container' : '#transaction-card';
    let transactionCardQuery = document.querySelectorAll(target);
    lastTransactionCardItem = transactionCardQuery.item(transactionCardQuery.length - 1);
}

function createOrUpdateObserver(): void {
  if (!lastTransactionCardItem) return;

  let options = {
    root: null,
    rootMargin: "0px",
    threshold: 1,
  };

  let intersectionObserver = new IntersectionObserver(handleIntersect, options);
  intersectionObserver.observe(lastTransactionCardItem);
}

function handleIntersect(
  entries: IntersectionObserverEntry[],
  observer: IntersectionObserver
) {
  entries.forEach((e) => {
    if (e.isIntersecting) {
      lastTransactionCardItem = null;

      observer.unobserve(e.target);
      emits("onLoadLastItem");
    }
  });
}
</script>

<style scoped lang="scss">
.transaction_card_list {
  display: grid;
  gap: 8px;
}
</style>
