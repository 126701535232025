const ActionBarContent = 'action-bar'
const ActionBarButtonVariations = [
  {
    label: 'Force approval',
    icon: 'mdi-check'
  },
  {
    label: 'Reanalyze',
    icon: 'mdi-repeat'
  },
  {
    label: 'Prioritize',
    icon: 'mdi-exclamation'
  },
  {
    label: 'Request chargeback',
    icon: 'mdi-rotate-left'
  },
  {
    label: 'Cancel order',
    icon: 'mdi-stop'
  }
]
const ActionBarHeight = '2.5rem'

export const ActionBarElements = {
  ActionBarContent,
  ActionBarButtonVariations,
  ActionBarHeight
}
