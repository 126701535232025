import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { useDisplay, useTheme } from "vuetify";

import { generateTestId } from "@/helpers/generateTestId";
import { ExportButtonContentElements } from "./constants/ExportButtonContent";
import { ExportButtonStyleMobile } from "./ExportButton.style";


export default /*@__PURE__*/_defineComponent({
  __name: 'ExportButton',
  setup(__props) {

const { mdAndUp } = useDisplay();
const { name: themeName } = useTheme();

return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createBlock(_component_v_btn, {
    icon: _unref(ExportButtonContentElements).ExportButtonIcon,
    class: "export-button text-primary",
    color: _unref(themeName) === 'dark' ? 'surface' : 'surface-variant-bright',
    "data-testeid": 
      _unref(generateTestId)(_unref(ExportButtonContentElements).ExportButtonContent)
    ,
    elevation: !_unref(mdAndUp) ? 1 : 0,
    style: _normalizeStyle(_unref(mdAndUp) ? {} : _unref(ExportButtonStyleMobile)),
    position: _unref(mdAndUp) ? 'relative' : 'fixed'
  }, null, 8, ["icon", "color", "data-testeid", "elevation", "style", "position"]))
}
}

})