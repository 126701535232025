<template>
  <div ref="refPinnedContainer" :class="pinnedContainerClass">
    <div
      ref="refSlotContainer"
      :class="slotContainerClass"
      class="d-flex flex-column justify-end bg-transparent"
    >
      <h2
        class="font-weight-regular on-tertiary-container pl-3"
        :style="`font-size: ${fontSize}`"
      >
      {{ t(label.TransactionDetailsPage.Actions) }}
      </h2>
      <div class="d-flex flex-column ga-2">
        <v-btn
          v-for="(option, i) in options"
          :key="i"
          density="comfortable"
          class="justify-start"
          :prepend-icon="option.icon"
          style="text-transform: none"
          color="tertiary"
          elevation="0"
          variant="text"
          @click.stop="() => emits('click', option.id)"
        >
        {{ t(`${label.Shared.TransactionActions._}.${ActionTransactionType[option.id]}`) }}
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ActionItem, ActionTransactionType } from "@/model/actionsDetails";
import { onMounted, ref } from "vue";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useI18n } from "vue-i18n";
import { tokens } from "@clearsale/tokens-ds";
import { label } from "@/plugins/locales/global";

const { t } = useI18n();
defineProps<{ options: ActionItem[] }>();
const emits = defineEmits<(e: "click", id: ActionTransactionType) => void>();

onMounted(() => {
  const t = setTimeout(() => {
    attachScrollAnimation();
    clearTimeout(t);
  }, 300);
});

const refPinnedContainer = ref<HTMLDivElement | null>(null);
const pinnedContainerClass = "pinned-container";

const refSlotContainer = ref<HTMLDivElement | null>(null);
const slotContainerClass = "slot-container";

const fontSize = tokens.typography["typescale-title-large-size"];

function attachScrollAnimation() {
  if (refPinnedContainer.value && refSlotContainer.value) {
    ScrollTrigger.create({
      start: "top-=100px top",
      end: () => document.body.scrollHeight,
      markers: false,
      pin: true,
      pinSpacing: false,
      trigger: refPinnedContainer.value,
    });
  }
}
</script>
