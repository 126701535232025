export interface JoinedListItem {
  label: string;
  classLabel: string;
  date: Date;
}

export function useJoinedList() {

  function formatDate(date: Date): string {
    const receivedDate = new Date(date);

    const weekday = receivedDate.toLocaleString("en-EN", { weekday: "long" });
    const monthAndDay = receivedDate.toLocaleDateString("en-EN", {
      month: "short",
      day: "numeric",
    });
    const time = receivedDate.toLocaleDateString("en-EN", {
      hour: "numeric",
      minute: "numeric",
    });

    return `${weekday}, ${monthAndDay}, ${time}`;
  }

  return {
    formatDate
  }
}
