<template>
  <div
    :style="{
      height: mdAndUp ? '220px' : '120px',
      padding: `${mdAndUp ? '0px 40px' : '0px'}`,
    }"
  >
    <CustomHero
      :class="{ 'd-flex align-end justify-space-between': mdAndUp }"
      :label="label"
      :srcImage="srcImage"
    >
      <ContainerSwiper>
        <div class="status-cards d-flex flex-row ga-2">
          <SummaryCard
            v-if="!mdAndUp"
            @click="(e: unknown) => console.log(e)"
            background-image="transaction"
            width="186px"
            justify="flex-end"
          >
            <h2>Orders</h2>
          </SummaryCard>

          <template
            v-for="(transaction, key, index) in transactionTotals"
            :key="index"
          >
            <SummaryCard
              @click="(e: unknown) => console.log(`transaction ${key} -- ${transaction}`)"
              :status="Number(key)"
              justify="flex-end"
            >
              <span>
                <v-icon
                  :icon="transactionStatusIcon[key as ITransactionStatus]"
                  size="8px"
                ></v-icon>
                {{ transactionStatusLabel[key] }}
              </span>
              <p
                ref="animateElement"
                :class="themeName === 'light' ? 'text-black' : 'text-white'"
              >
                <span>{{
                  formatNumber(transaction ?? 0, "en-US").currency
                }}</span>
                {{
                  transaction > 0
                    ? formatNumber(
                        transaction ?? animatedNumber.dataValue,
                        "en-US"
                      ).format
                    : animatedNumber.dataValue === 10000 && !transaction
                    ? 0
                    : formatNumber(
                        !transaction
                          ? animatedNumber.dataValue
                          : transaction ?? 0,
                        "en-US"
                      ).format
                }}
              </p>
            </SummaryCard>
          </template>
        </div>
      </ContainerSwiper>
    </CustomHero>
  </div>
</template>

<script setup lang="ts">
import { useDisplay, useTheme } from "vuetify";

import { transactionStatusIcon } from "@/model/transaction";
import ContainerSwiper from "@/components/shared/ContainerSwiper/ContainerSwiper.vue";
import SummaryCard from "@/components/shared/SummaryCard/SummaryCard.vue";
import CustomHero from "../shared/CustomHero/CustomHero.vue";
import { useTransactionStore } from "@/store/TransactionStore";
import { onMounted, reactive, ref, watch, computed } from "vue";
import gsap from "gsap";
import { ITransactionStatus } from "@/api/resources/Transactions/transactionResource";

import imgDesktopLight from "@/assets/backgrounds/transaction-desktop-light.png";
import imgDesktopDark from "@/assets/backgrounds/transaction-desktop-dark.png";

const { mdAndUp } = useDisplay();

const { name: themeName } = useTheme();

const label = computed(() => (mdAndUp.value ? "Orders" : ""));
const srcImage = computed(() => {
  if (mdAndUp.value) {
    if (themeName.value === "dark") return imgDesktopDark;
    else return imgDesktopLight;
  } else return "";
});

const transactionStore = useTransactionStore();

type Language = "en-US" | "pt-BR";

function formatNumber(number: number, language: Language) {
  const currencySymbol = {
    "en-US": "$",
    "pt-BR": "R$",
  };

  const suffixes = {
    "en-US": ["K", "M", "B", "T", "Q"],
    "pt-BR": ["mil", "mi", "bi", "tri", "qua"],
  };

  if (number < 1000) {
    return {
      format: number.toString(),
      value: number,
      currency: currencySymbol[language],
    };
  }

  const languageSuffixes = suffixes[language];

  for (let i = languageSuffixes.length - 1; i >= 0; i--) {
    const divisor = Math.pow(10, 3 * (i + 1));
    const quotient = number / divisor;

    if (quotient >= 1) {
      const formattedNumber = quotient.toFixed(3).replace(/\.?0+$/, "");
      return {
        format: `${formattedNumber}${languageSuffixes[i]}`,
        value: number,
        currency: currencySymbol[language],
      };
    }
  }
  return {
    format: number.toString(),
    value: number,
    currency: currencySymbol[language],
  };
}

const transactionTotals = ref({
  [ITransactionStatus.Approved]: 0,
  [ITransactionStatus.Denied]: 0,
  [ITransactionStatus.Analyzing]: 0,
  [ITransactionStatus.Canceled]: 0,
});

const transactionStatusLabel = {
  [ITransactionStatus.Approved]: "Approved",
  [ITransactionStatus.Denied]: "Denied",
  [ITransactionStatus.Analyzing]: "Analyzing",
  [ITransactionStatus.Canceled]: "Canceled",
};

const animatedNumber = reactive({
  dataValue: 0,
});

onMounted(() => {
  gsap.to(animatedNumber, {
    dataValue: 10000,
    duration: 1,
  });
});

watch(
  () => transactionStore.getSummaryTransaction,
  () => {
    transactionTotals.value = {
      [ITransactionStatus.Approved]:
        transactionStore.getSummaryTransaction[ITransactionStatus.Approved],
      [ITransactionStatus.Denied]:
        transactionStore.getSummaryTransaction[ITransactionStatus.Denied],
      [ITransactionStatus.Analyzing]:
        transactionStore.getSummaryTransaction[ITransactionStatus.Analyzing],
      [ITransactionStatus.Canceled]:
        transactionStore.getSummaryTransaction[ITransactionStatus.Canceled],
    };
  }
);
</script>

<style scoped lang="scss">
.status-cards {
  & h2 {
    font-size: 36px;
    font-weight: 400;
  }

  .v-card-text {
    padding: 0;
    flex: 0;

    & > span {
      font-size: 14px;

      & v-icon {
        font-size: 8px;
      }
    }

    & > p {
      margin: 0;
      font-size: 28px;
      font-weight: 400;
      color: black;

      & span {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}
</style>
