<template>
  <WidthScrollContainer
    width="600px"
    :height="scrollContainerWidth.to"
    @scroll-on-top="(onTop) => (isOnTop = onTop)"
  >
    <div class="d-flex ga-3 pt-1 rounded-b-xl" :class="classTheme">
      <v-btn
        variant="flat"
        density="comfortable"
        :size="isOnTop ? scrollContainerWidth.to : scrollContainerWidth.from"
        style="transition: width ease-in-out 0.3s, height ease-in-out 0.3s"
        class="bg-surface-container text-surface-variant"
        icon="mdi-arrow-left"
        to="./"
      />
      <TabsSwitch
        class="d-flex flex-1-1 align-center bg-surface-container rounded-xl"
        :options="options"
        @selected="(id) => emits('click', id)"
      />
    </div>
  </WidthScrollContainer>
</template>
<script setup lang="ts">
import { useClassByTheme } from "@/components/hooks/useClassByTheme";
import TabsSwitch from "@/components/shared/TabsSwitch/TabsSwitch.vue";
import WidthScrollContainer from "@/components/shared/WidthScrollContainer/WidthScrollContainer.vue";

import { TabsSwitchItem } from "@/model/tabSwitch";
import { DetailTransactionTabs } from "@/model/transaction";
import { ref } from "vue";

const scrollContainerWidth = { from: "48px", to: "40px" };
const containerClass = {
  light: "bg-cs_on_primary",
  dark: "bg-cs_surface_container_low",
};

defineProps<{ options: TabsSwitchItem<DetailTransactionTabs>[] }>();
const emits = defineEmits<(e: "click", id: DetailTransactionTabs) => void>();

const isOnTop = ref(false);

const classTheme = useClassByTheme(containerClass);
</script>
