import {ref} from 'vue'
import {Chip} from "@/model/chip"
import { useTransactionStore } from "@/store/TransactionStore";
import { ITransactionStatus } from '@/api/resources/Transactions/transactionResource';

export interface PayloadSubmitButtonFilter {
  chipId: ITransactionStatus;
  label: string
}

const statusOptions = [
  {
    label: "Approved",
    chipId: ITransactionStatus.Approved,
  },
  {
    label: "Denied",
    chipId: ITransactionStatus.Denied,
  },
  {
    label: "Analyzing",
    chipId: ITransactionStatus.Analyzing,
  },
  {
    label: "Canceled",
    chipId: ITransactionStatus.Canceled,
  },
]

export function useButtonFilterStatus() {
  const status = ref(statusOptions);
  const selectedStatus = ref<Chip[] | [] | undefined>([]);
  const selectedStatusMemory = ref<Chip[] | [] | undefined>()

  const transactionStore = useTransactionStore();

  const modelStatus = ref((selectedStatusMemory.value ?? []).length > 0 ? selectedStatusMemory : selectedStatus)

  function handleSubmitStatus(submitFilterFn: () => void) {
    selectedStatusMemory.value = selectedStatus.value
    const status = (selectedStatusMemory.value ?? []).map(item => item.chipId)
    submitFilterFn();
    transactionStore.$patch({
      filters: {
        status: status as ITransactionStatus[]
      }
    })
    transactionStore.$patch({ page: 1, offset: 20 });
  }

  function handleClearAll() {
    selectedStatus.value = [];
    selectedStatusMemory.value = []
    transactionStore.$patch({filters: {status: [] as ITransactionStatus[]}})
    transactionStore.$patch({ page: 1, offset: 20 });
  }

  function handleCloseFilter() {
    if((selectedStatusMemory.value ?? []).length > 0) {
      selectedStatus.value = selectedStatusMemory.value
      return
    }
    selectedStatus.value = []
  }

  return {
    status,
    selectedStatus,
    selectedStatusMemory,
    handleSubmitStatus,
    handleClearAll,
    handleCloseFilter,
    modelStatus
  }
}
