import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "status-icon checkbox" }
const _hoisted_3 = { class: "id" }
const _hoisted_4 = {
  key: 0,
  class: "client-name"
}
const _hoisted_5 = { class: "value" }
const _hoisted_6 = { class: "status" }
const _hoisted_7 = { class: "date" }
const _hoisted_8 = { class: "actions" }

import TransactionCard from "@/components/shared/TransactionCard/TransactionCard.vue";
import TransactionCardList from "@/components/shared/TransactionCardList/TransactionCardList.vue";

import { transactionStatusIcon } from "@/model/transaction";
import { useDate, useTheme } from "vuetify";
import { ref } from "vue";
import {
  ITransaction,
  ITransactionStatus,
} from "@/api/resources/Transactions/transactionResource";
import {
  useClassByThemeStatus,
  UseClassByThemeStatusProps,
} from "../hooks/useClassByTheme";
import { useI18n } from "vue-i18n";
import { label } from "@/plugins/locales/global";


export default /*@__PURE__*/_defineComponent({
  __name: 'ListDesktopTransactions',
  props: {
    data: {}
  },
  emits: ["iconClick", "containerClick", "fetchData"],
  setup(__props: any, { emit: __emit }) {



const { t,d } = useI18n();
const emits = __emit;

const { name: themeName } = useTheme();

function isTodayDate(date: string | Date) {
  const dateNow = new Date(date);
  const todayDate = new Date();
  todayDate.setHours(0, 0, 0, 0);
  dateNow.setHours(0, 0, 0, 0);

  return dateNow.getTime() === todayDate.getTime();
}

const checkedList = ref<{ [x: number]: boolean }>([]);

function ellipsisText(input: string): string {
  const INPUT_LENGTH = 14;
  return input.length > INPUT_LENGTH
    ? `${input.substring(0, INPUT_LENGTH)}...`
    : input;
}

function show(evt: MouseEvent, index: number) {
  const statusIconElement = getStatusIconElement(evt.target as HTMLElement);
  const checkboxElement = getCheckboxElement(evt.target as HTMLElement);
  const dateElement = getDateElement(evt.target as HTMLElement);
  const actionsElement = getActionsElement(evt.target as HTMLElement);

  const anyChecked = Object.values(checkedList.value).find(
    (checked) => checked
  );

  if (evt.type == "mouseenter") {
    handleMouseEnter(
      dateElement,
      actionsElement,
      statusIconElement,
      checkboxElement,
      anyChecked
    );
  }

  if (evt.type == "mouseleave") {
    handleMouseLeave(
      dateElement,
      actionsElement,
      statusIconElement,
      checkboxElement,
      index
    );
  }
}

function getStatusIconElement(target: HTMLElement): HTMLElement | null {
  return target.querySelector<HTMLElement>(".status-icon.checkbox > .v-icon");
}

function getCheckboxElement(target: HTMLElement): HTMLElement | null {
  return target.querySelector<HTMLElement>(
    ".status-icon.checkbox > .v-checkbox"
  );
}

function getDateElement(target: HTMLElement): HTMLElement | null {
  return target.querySelector<HTMLElement>(".date");
}

function getActionsElement(target: HTMLElement): HTMLElement | null {
  return target.querySelector<HTMLElement>(".actions");
}

function handleMouseEnter(
  dateElement: HTMLElement | null,
  actionsElement: HTMLElement | null,
  statusIconElement: HTMLElement | null,
  checkboxElement: HTMLElement | null,
  anyChecked: boolean | undefined
) {
  if (dateElement && actionsElement && statusIconElement && checkboxElement) {
    if (!anyChecked) {
      dateElement.style.display = "none";
      actionsElement.style.display = "flex";
    }

    statusIconElement.style.display = "none";
    checkboxElement.style.display = "flex";
  }
}

function handleMouseLeave(
  dateElement: HTMLElement | null,
  actionsElement: HTMLElement | null,
  statusIconElement: HTMLElement | null,
  checkboxElement: HTMLElement | null,
  index: number
) {
  if (dateElement && actionsElement && statusIconElement && checkboxElement) {
    statusIconElement.style.display = checkedList.value[index]
      ? "none"
      : "flex";
    checkboxElement.style.display = checkedList.value[index] ? "flex" : "none";
    dateElement.style.display = "initial";
    actionsElement.style.display = "none";
  }
}

function iconClick(transaction: ITransaction, index: number) {
  emits("iconClick", transaction);
  if (!checkedList.value[index]) {
    checkedList.value[index] = true;
    return;
  }

  checkedList.value[index] = !checkedList.value[index];
}

function containerClick(transaction: ITransaction) {
  emits("containerClick", transaction);
}

function fetchData(): void {
  emits("fetchData");
}

const classThemeWholeDefault = {
  [ITransactionStatus.Approved]: "bg-success-container text-success",
  [ITransactionStatus.Denied]: "bg-error-container text-error",
  [ITransactionStatus.Analyzing]: "bg-information-container text-information",
  [ITransactionStatus.Canceled]: "bg-surface-dim text-on-surface-variant",
  [ITransactionStatus.None]: "",
};

const classThemeWhole: UseClassByThemeStatusProps = {
  light: {
    ...classThemeWholeDefault,
  },
  dark: {
    ...classThemeWholeDefault,
    [ITransactionStatus.Approved]:
      "bg-cs_ref_palette_success80 text-cs_ref_palette_success10",
    [ITransactionStatus.Denied]:
      "bg-cs_ref_palette_error95 text-cs_ref_palette_error10",
    [ITransactionStatus.Analyzing]:
      "bg-cs_ref_palette_information90 text-cs_ref_palette_information10",
  },
};

const classNameWhole = useClassByThemeStatus(classThemeWhole);

const classThemeTextDefault = {
  [ITransactionStatus.Approved]: "text-success",
  [ITransactionStatus.Denied]: "text-error",
  [ITransactionStatus.Analyzing]: "text-information",
  [ITransactionStatus.Canceled]: "text-dim",
  [ITransactionStatus.None]: "",
};

const classThemeText = {
  light: {
    ...classThemeTextDefault,
  },
  dark: {
    ...classThemeTextDefault,
  },
};

const classNameText = useClassByThemeStatus(classThemeText);

return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_ctx.data.length)
    ? (_openBlock(), _createBlock(TransactionCardList, {
        key: 0,
        onOnLoadLastItem: fetchData
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (transaction, index) => {
            return (_openBlock(), _createBlock(TransactionCard, {
              key: index,
              variant: "flat",
              class: _normalizeClass([_unref(themeName), checkedList.value[index] ? 'checked' : '', 'rounded-pill']),
              onMouseenter: (e) => show(e, index),
              onMouseleave: (e) => show(e, index),
              onClick: _withModifiers(($event: any) => (containerClick(transaction)), ["prevent","stop"]),
              onTouchStart: _withModifiers(($event: any) => (containerClick(transaction)), ["prevent","stop"])
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_v_icon, {
                      icon: _unref(transactionStatusIcon)[transaction.status],
                      class: _normalizeClass([_unref(classNameWhole)[transaction.status], 'whole'])
                    }, null, 8, ["icon", "class"]),
                    _createVNode(_component_v_checkbox, {
                      "model-value": checkedList.value[index],
                      width: "32px",
                      color: "information",
                      onClick: _withModifiers(($event: any) => (iconClick(transaction, index)), ["prevent","stop"]),
                      onTouchStart: _withModifiers(($event: any) => (iconClick(transaction, index)), ["prevent","stop"])
                    }, null, 8, ["model-value", "onClick", "onTouchStart"])
                  ]),
                  _createElementVNode("div", _hoisted_3, [
                    _cache[5] || (_cache[5] = _createElementVNode("span", null, "#", -1)),
                    _createElementVNode("span", null, _toDisplayString(ellipsisText(transaction.code)), 1)
                  ]),
                  (transaction.entityName)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createElementVNode("span", null, _toDisplayString(ellipsisText(transaction.entityName)), 1)
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_5, _toDisplayString(transaction.totalAmount.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })), 1),
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", {
                      class: _normalizeClass([_unref(themeName), _unref(classNameText)[transaction.status], 'text'])
                    }, [
                      _createVNode(_component_v_icon, {
                        icon: _unref(transactionStatusIcon)[transaction.status]
                      }, null, 8, ["icon"]),
                      _createElementVNode("span", null, _toDisplayString(_unref(t)(`${_unref(label).Shared.TransactionStatus._}.${_unref(ITransactionStatus)[transaction.status]}`)), 1)
                    ], 2)
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("span", null, _toDisplayString(_unref(d)(transaction.consumerRequestedAt, 'short')), 1)
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_v_btn, {
                      variant: "text",
                      icon: "mdi-check",
                      color: "information",
                      onClick: _cache[0] || (_cache[0] = _withModifiers(() => console.log('mdi-check'), ["prevent","stop"]))
                    }),
                    _createVNode(_component_v_btn, {
                      variant: "text",
                      icon: "mdi-repeat",
                      color: "information",
                      onClick: _cache[1] || (_cache[1] = _withModifiers(() => console.log('mdi-repeat'), ["prevent","stop"]))
                    }),
                    _createVNode(_component_v_btn, {
                      variant: "text",
                      icon: "mdi-priority-high",
                      color: "information",
                      onClick: _cache[2] || (_cache[2] = _withModifiers(() => console.log('mdi-priority-high'), ["prevent","stop"]))
                    }),
                    _createVNode(_component_v_btn, {
                      variant: "text",
                      icon: "mdi-rotate-left",
                      color: "information",
                      onClick: _cache[3] || (_cache[3] = _withModifiers(() => console.log('mdi-rotate-left'), ["prevent","stop"]))
                    }),
                    _createVNode(_component_v_btn, {
                      variant: "text",
                      icon: "mdi-square",
                      color: "information",
                      onClick: _cache[4] || (_cache[4] = _withModifiers(() => console.log('mdi-square'), ["prevent","stop"]))
                    })
                  ])
                ])
              ]),
              _: 2
            }, 1032, ["class", "onMouseenter", "onMouseleave", "onClick", "onTouchStart"]))
          }), 128))
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})