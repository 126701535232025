<template>
  <ButtonFilterMobile
    :label="'Status'"
    :active="isValid"
    :height="30"
    @close-filter="handleSubmitFilter"
    @clear-filter="handleClearAll"
  >
    <div
      :data-testid="generateTestId(ButtonFilterMobileStatus)"
      class="w-100 d-flex flex-column justify-start align-start"
    >
      <h1 class="bg-on-surface text-left text-h5 mb-6">Status</h1>
      <ChipSelectable
        v-model="statusActive"
        :chips="statusOptions"
        multiple
        isMobile
      ></ChipSelectable>
    </div>
  </ButtonFilterMobile>
</template>

<script setup lang="ts">
import ButtonFilterMobile from "@/components/shared/ButtonFilterMobile/ButtonFilterMobile.vue";
import ChipSelectable from "@/components/shared/ChipSelectable/ChipSelectable.vue";

import { useButtonFilterStatus } from "./hooks/useButtonFilterStatusMobile";
import { generateTestId } from "@/helpers/generateTestId";
import { ButtonFilterMobileStatus } from "./constants/ButtonFilterStatusMobileIds";

const {
  isValid,
  statusActive,
  statusOptions,
  handleClearAll,
  handleSubmitFilter,
} = useButtonFilterStatus();
</script>

<style scoped lang="scss"></style>
