import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "left" }
const _hoisted_2 = { class: "id" }
const _hoisted_3 = { class: "status" }
const _hoisted_4 = { class: "status-icon select" }
const _hoisted_5 = {
  key: 0,
  class: "client-name"
}
const _hoisted_6 = { class: "date" }
const _hoisted_7 = { class: "value" }
const _hoisted_8 = { class: "right" }

import { label } from "@/plugins/locales/global";
import TransactionCard from "@/components/shared/TransactionCard/TransactionCard.vue";
import TransactionCardList from "@/components/shared/TransactionCardList/TransactionCardList.vue";

import { transactionStatusIcon } from "@/model/transaction";
import { useDate, useTheme } from "vuetify";
import { onMounted, ref } from "vue";
import {
  ITransaction,
  ITransactionStatus,
} from "@/api/resources/Transactions/transactionResource";
import {
  useClassByThemeStatus,
  UseClassByThemeStatusProps,
} from "../hooks/useClassByTheme";
import { useI18n } from "vue-i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'ListMobileTransactions',
  props: {
    data: {}
  },
  emits: ["iconClick", "containerClick", "fetchData"],
  setup(__props: any, { emit: __emit }) {

                    //isTodayDate(transaction.consumerRequestedAt)
                      //? d.format(transaction.consumerRequestedAt, "fullTime12h")
                      //: d.format(transaction.consumerRequestedAt, "fullDate")
const { t,d } = useI18n();

const props = __props;

function isTodayDate(date: Date) {
  const dateNow = new Date(date);
  const todayDate = new Date();
  todayDate.setHours(0, 0, 0, 0);
  dateNow.setHours(0, 0, 0, 0);

  return dateNow.getTime() === todayDate.getTime();
}

const emits = __emit;

const { name: themeName } = useTheme();

//const d = useDate();

const checkedList = ref<{ [x: number]: boolean }>({});

function onSomeChecked(): boolean {
  return Object.values(checkedList.value).some((value) => value);
}

function ellipsisText(input: string): string {
  const INPUT_LENGTH = 14;
  return input.length > INPUT_LENGTH
    ? `${input.substring(0, INPUT_LENGTH)}...`
    : input;
}

function iconClick(transaction: ITransaction, index: number) {
  emits("iconClick", transaction);
  if (!checkedList.value[index]) {
    checkedList.value[index] = true;
    return;
  }

  checkedList.value[index] = !checkedList.value[index];
}

function containerClick(transaction: ITransaction) {
  emits("containerClick", transaction);
}

function fetchData(): void {
  emits("fetchData");
}

const classThemeWholeDefault = {
  [ITransactionStatus.Approved]: "bg-success-container text-success",
  [ITransactionStatus.Denied]: "bg-error-container text-error",
  [ITransactionStatus.Analyzing]: "bg-information-container text-information",
  [ITransactionStatus.Canceled]: "bg-surface-dim text-on-surface-variant",
  [ITransactionStatus.None]: "",
};

const classThemeWhole: UseClassByThemeStatusProps = {
  light: {
    ...classThemeWholeDefault,
  },
  dark: {
    ...classThemeWholeDefault,
    [ITransactionStatus.Approved]:
      "bg-cs_ref_palette_success80 text-cs_ref_palette_success10",
    [ITransactionStatus.Denied]:
      "bg-cs_ref_palette_error95 text-cs_ref_palette_error10",
    [ITransactionStatus.Analyzing]:
      "bg-cs_ref_palette_information90 text-cs_ref_palette_information10",
  },
};

const classNameWhole = useClassByThemeStatus(classThemeWhole);

onMounted(() => {
  props.data.forEach((_, index) => {
    checkedList.value[index] = false;
  });
});

return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_ctx.data.length)
    ? (_openBlock(), _createBlock(TransactionCardList, {
        key: 0,
        onOnLoadLastItem: fetchData
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (transaction, index) => {
            return (_openBlock(), _createBlock(TransactionCard, {
              key: index,
              class: _normalizeClass([_unref(themeName), checkedList.value[index] ? 'checked' : '']),
              onClick: _withModifiers(($event: any) => (containerClick(transaction)), ["prevent"]),
              onTouchStart: _withModifiers(($event: any) => (containerClick(transaction)), ["prevent"]),
              active: onSomeChecked()
            }, {
              left: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("span", null, "#" + _toDisplayString(ellipsisText(transaction.code)), 1)
                    ]),
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("div", {
                        class: _normalizeClass([
                  _unref(themeName),
                  _unref(ITransactionStatus)[transaction.status],
                  'text',
                ])
                      }, [
                        _createVNode(_component_v_icon, {
                          icon: _unref(transactionStatusIcon)[transaction.status]
                        }, null, 8, ["icon"]),
                        _createElementVNode("span", null, _toDisplayString(_unref(t)(`${_unref(label).Shared.TransactionStatus._}.${_unref(ITransactionStatus)[transaction.status]}`)), 1)
                      ], 2)
                    ])
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_v_btn, {
                        variant: "text",
                        color: "",
                        class: _normalizeClass([
                  _unref(themeName),
                  _unref(classNameWhole)[transaction.status],
                  'whole',
                  checkedList.value[index] ? 'checked' : '',
                ]),
                        onClick: _withModifiers(($event: any) => (iconClick(transaction, index)), ["prevent"]),
                        onTouchstart: _withModifiers(($event: any) => (iconClick(transaction, index)), ["prevent","stop"])
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, {
                            icon: 
                    !checkedList.value[index]
                      ? _unref(transactionStatusIcon)[transaction.status]
                      : 'mdi-circle'
                  
                          }, null, 8, ["icon"])
                        ]),
                        _: 2
                      }, 1032, ["class", "onClick", "onTouchstart"])
                    ]),
                    _createElementVNode("div", null, [
                      _createElementVNode("div", null, [
                        (transaction.entityName)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                              _createElementVNode("span", null, _toDisplayString(ellipsisText(transaction.entityName)), 1)
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_6, [
                          _createElementVNode("span", null, _toDisplayString(_unref(d)(transaction.consumerRequestedAt, 'short')), 1)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_7, _toDisplayString(transaction.totalAmount.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })), 1)
                    ])
                  ])
                ])
              ]),
              right: _withCtx(() => [
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_v_btn, {
                    variant: "text",
                    icon: "mdi-check",
                    color: "information"
                  }),
                  _createVNode(_component_v_btn, {
                    variant: "text",
                    icon: "mdi-repeat",
                    color: "information"
                  }),
                  _createVNode(_component_v_btn, {
                    variant: "text",
                    icon: "mdi-priority-high",
                    color: "information"
                  }),
                  _createVNode(_component_v_btn, {
                    variant: "text",
                    icon: "mdi-rotate-left",
                    color: "information"
                  }),
                  _createVNode(_component_v_btn, {
                    variant: "text",
                    icon: "mdi-square",
                    color: "information"
                  })
                ])
              ]),
              _: 2
            }, 1032, ["class", "onClick", "onTouchStart", "active"]))
          }), 128))
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})