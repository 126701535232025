export const BASE_URL = process.env.VUE_APP_BASE_URL_API ?? ""

export enum ITransactionStatus {
  None = 0,
  Approved = 1,
  Denied = 2,
  Analyzing = 3,
  Canceled = 4
}

export enum CardType {
  Diners = 1,
  MasterCard = 2,
  Visa = 3,
  Others = 4,
  AmericanExpress = 5,
  HiperCard = 6,
  Aura = 7,
  Carrefour = 8,
  CartaoElo = 10,
  LeaderCard = 50,
  Fortbrasil = 100,
  Sorocred = 101,
  AVista = 102,
  CartaoMais = 103,
  CartaoCA = 105
}

export interface ITransactionPaymentDetails {
  id: string;
  type: IPaymentType;
  name: string;
  number?: string;
  cardType?: CardType;
  amount: number;
  currency?: number;
}

export enum IPaymentType {
  CreditCard = 1,
  BoletoBancario = 2,
  DebitoBancario = 3,
  DebitoBancarioDinheiro = 4,
  DebitoBancarioCheque = 5,
  TransferenciaBancaria = 6,
  SedexCobrar = 7,
  Cheque = 8,
  Cash = 9,
  Financiamento = 10,
  Fatura = 11,
  Cupom = 12,
  Multicheque = 13,
  Others = 14,
  Vale = 16,
  DebitoParcelado = 17,
  ValeDesconto = 18,
  CartaoDebito = 19,
  ValeAlimentacao = 23,
  Pix = 27,
  Cashback = 28,
  GiftCard = 1041,
  CartaoDebitoTransferencia = 4011
}

export interface ITransaction {
  id: string,
  code: string,
  entityName: string,
  totalAmount: number,
  status: ITransactionStatus,
  consumerRequestedAt: Date | string
}

export interface ITransactionDetails_Summary {
  id: string,
  code: string,
  entityName: string,
  totalAmount: number,
  currency: number,
  status: ITransactionStatus,
  entityDate: Date,
  chargebackStatus?: ITransactionStatus,
  consumerRequestedAt: Date,
  clearSaleDate?: Date,
  email: string
}

export interface ITransactionDetails_Contacts {
  billing?: {
    phone?: string;
    name?: string;
    addressLine1?: string;
    addressLine2?: string;
    country?: string;
    city?: string;
    zipCode?: string;
  },
  shipping?: {
    phone?: string;
    name?: string;
    addressLine1?: string;
    addressLine2?: string;
    country?: string;
    city?: string;
    zipCode?: string;
  }
}
export interface ITransactionDetails_History {
  label: string,
  classLabel: string,
  date?: Date
}

export interface ITransactionDetails {
  summary: ITransactionDetails_Summary,
  contacts: ITransactionDetails_Contacts,
  history: ITransactionDetails_History[]
}

export interface ITransactionDevice {
  id: string,
  label: string,
  ipAddress: string,
}

export interface ITransactionItem {
  id: string,
  name: string,
  quantity: number,
  price: number
}

type ResponseDataTransactionList = {
  result: ITransaction[],
}

export type RequestDataTransactionList = {
  entityIds: string[],
  codes: string[],
  amountFrom: number,
  amountTo: number,
  status: ITransactionStatus[],
  dateFrom: Date | null,
  dateTo: Date | null,
  page?: number,
  offset?: number
}

type ResponseDataTotalTransactionStatus = {
  countStatus: Record<ITransactionStatus, number>,
  valueTransactionStatus: Record<ITransactionStatus, number>

}
export enum SuggestionType {
  None = 0,
  Transaction = 1,
  Store = 2
}
export type RequestDataSuggestion = {
  type: SuggestionType,
  value: string,

  limit: number
}

export type SuggestionItem = {
  id: string,
  value: string
  subValue: string,
}

export type ResponseDataSuggestion = {
  result: SuggestionItem[]
}

export function getTransactionAPIPOST(body: RequestDataTransactionList): Promise<ResponseDataTransactionList> {
  return fetch(
    BASE_URL + `/shield/transactions`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  ).then(response => response.json())
}

export function getTotalTransactionStatusAPI(): Promise<ResponseDataTotalTransactionStatus> {
  return fetch(BASE_URL + `/shield/total-transactions-status`).then(response => response.json())
}

export function getSuggestionAPIPOST(body: RequestDataSuggestion): Promise<ResponseDataSuggestion> {
  return fetch(
    BASE_URL + `/shield/suggestions`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  ).then(response => response.json())
}
