<template>
  <header
    :class="
      (themeName as string) === 'dark' ? 'bg-cs_surface_container_low' : 'bg-cs_on_primary'
    "
  >
    <div
      class="d-flex align-center ga-8 w-100 ma-auto px-4"
      style="position: relative"
      id="menu"
    >
      <slot name="logo" v-if="showLogo">
        <img
          :src="imgShield"
          alt="Clearsale Logo"
          class="logo__img"
          height="48px"
          @click="handleLogoClick"
        />
      </slot>
      <div class="d-flex align-center justify-space-between w-100">
        <div>
          <slot> <div></div> </slot>
        </div>
        <nav>
          <slot name="nav">
            <div class="d-flex align-center">
              <slot name="links">
                <AppsMenu v-model="appsMenu" />
                <UserMenu v-model="userMenu" />
              </slot>
            </div>
          </slot>
        </nav>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import { useRouter } from "vue-router";
import { ref, watch } from "vue";

import UserMenu from "./UserMenu/UserMenu.vue";
import AppsMenu from "./AppsMenu/AppsMenu.vue";

import imgShield from "@/assets/logo_shield.svg";

import { routeNames } from "@/router";
import { useTheme } from "vuetify/lib/framework.mjs";

const router = useRouter();

const { name: themeName } = useTheme();

withDefaults(defineProps<{ showLogo?: boolean }>(), { showLogo: true });
const appsMenu = ref(false);
const userMenu = ref(false);

const handleLogoClick = () => {
  if (window.scrollY > 0) {
    window.scrollTo({ top: 0, behavior: "smooth" });
  } else {
    router.push({ name: routeNames.transactionView });
  }
};

watch(appsMenu, () => {
  if (appsMenu.value) {
    userMenu.value = false;
  }
});

watch(userMenu, () => {
  if (userMenu.value) {
    appsMenu.value = false;
  }
});
</script>

<style lang="scss" scoped>
.logo__img:hover {
  cursor: pointer;
}
</style>
