import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"background-color":"white","min-height":"100vh","display":"flex","flex-direction":"column"}
}

import { onMounted, ref, onUnmounted, onBeforeMount } from "vue";
import { useClassByTheme } from "./components/hooks/useClassByTheme";
import { validateSession } from "./services/auth";
import OneMenu from "@/components/one/NewMenu/OneMenu.vue";
import { useRouter } from "vue-router";
import { enableMocking } from "./api/mocks/browser";

import imgShield from "@/assets/logo_shield.svg";
import { MergeHead, ActiveHeadEntry, useHead, UseHeadInput } from "@unhead/vue";
import { useTheme } from "vuetify/lib/framework.mjs";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const { global } = useTheme();

const classTheme = {
  light: "bg-cs_on_primary",
  dark: "bg-cs_surface_container_low",
};

let theme = ref();

const headInfo = useHead({
  title: "Shield",
  link: [{ rel: "icon", href: imgShield, type: "image/svg+xml" }],
}) as ActiveHeadEntry<UseHeadInput<MergeHead>>;

const activeSSO = process.env.VUE_APP_ENV !== "development";

const router = useRouter();
const className = useClassByTheme(classTheme);

const showApp = ref(false);
const load = ref(false);

addEventListener("access-token", () => {
  showApp.value = true;
});

onBeforeMount(() => {
  theme.value = localStorage.getItem("theme");
  global.name.value = theme.value !== "dark" ? "light" : "dark";
});

onMounted(() => {
  if (activeSSO && !validateSession()) {
    showApp.value = false;
    return;
  }

  showApp.value = true;
});

onUnmounted(() => {
  headInfo.dispose();
});

router.beforeResolve((to, from, next) => {
  enableMocking().finally(() => {
    next();
    load.value = true;
  });
});

onMounted(() => {
  if (activeSSO && !validateSession()) {
    showApp.value = false;
    return;
  }
  showApp.value = true;
});

return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_v_theme_provider = _resolveComponent("v-theme-provider")!

  return (showApp.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_v_theme_provider, null, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(_unref(className)),
              style: {"flex":"1","display":"flex","flex-direction":"column"}
            }, [
              (load.value)
                ? (_openBlock(), _createBlock(OneMenu, {
                    key: 0,
                    class: "py-4"
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_RouterView)
            ], 2)
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}
}

})