<template>
  <ContainerFixedTop style="z-index: 9">
    <ContainerSwiper>
      <div
        style="
          padding-top: 4px;
          transition: padding-top ease-in-out 0.3s,
            padding-bottom ease-in-out 0.3s;
        "
        class="w-100 d-flex flex-row ga-2"
      >
        <v-btn
          ref="refIcon"
          variant="flat"
          density="comfortable"
          size="40px"
          class="bg-surface-container text-surface-variant"
          icon="mdi-arrow-left"
          to="./"
        />
        <TabsSwitch
          class="d-flex flex-1-1 align-center bg-surface-container rounded-xl"
          :options="options"
          @selected="(id) => emits('click', id)"
        />
      </div>
    </ContainerSwiper>
  </ContainerFixedTop>
</template>
<script setup lang="ts">
import TabsSwitch from "@/components/shared/TabsSwitch/TabsSwitch.vue";
import ContainerSwiper from "@/components/shared/ContainerSwiper/ContainerSwiper.vue";
import ContainerFixedTop from "@/components/shared/ContainerFixedTop/ContainerFixedTop.vue";

import { TabsSwitchItem } from "@/model/tabSwitch";
import { DetailTransactionTabs } from "@/model/transaction";
import type { VBtn } from "vuetify/lib/components/index.mjs";

defineProps<{ options: TabsSwitchItem<DetailTransactionTabs>[] }>();
const emits = defineEmits<(e: "click", id: DetailTransactionTabs) => void>();
</script>
