import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"display":"grid","grid-template-rows":"1fr","grid-template-columns":"1fr minmax(100px, 600px) 1fr","justify-content":"space-evenly","padding-top":"10px"} }
const _hoisted_2 = { style: {"grid-column":"2 / 3"} }

import { onMounted, ref, computed, reactive, watch } from "vue";
import { useDisplay, useTheme } from "vuetify";

import ActionDetails from "@/components/one/ActionDetailsComponent/ActionDetails.vue";
import CustomHero from "@/components/shared/CustomHero/CustomHero.vue";
import TabsSwitch from "@/components/one/TabsSwitchComponent/TabsSwitch.vue";
import TransactionSummary from "@/components/transactions/details/TransactionSummary/TransactionSummary.vue";
import TransactionHistory from "@/components/transactions/details/TransactionHistory/TransactionHistory.vue";
import TransactionItems from "@/components/transactions/details/TransactionItems/TransactionItems.vue";
import TransactionPayments from "@/components/transactions/details/TransactionPayments/TransactionPayments.vue";
import TransactionContacts from "@/components/transactions/details/TransactionDetailsContacts/TransactionDetailsContacts.vue";

import imgDesktopLight from "@/assets/backgrounds/transaction-desktop-light.png";
import imgDesktopDark from "@/assets/backgrounds/transaction-desktop-dark.png";

import { DetailTransactionTabs } from "@/model/transaction";
import { TabsSwitchItem } from "@/model/tabSwitch";
import { ActionItem, ActionTransactionType } from "@/model/actionsDetails";
import { useRoute } from "vue-router";
import {
  ITransaction,
  ITransactionDetails,
} from "@/api/resources/Transactions/transactionResource";
import { transactionsDB } from "@/api/mocks/server/transactionDBMock";
import { useI18n } from "vue-i18n";
import { label } from "@/plugins/locales/global";


export default /*@__PURE__*/_defineComponent({
  __name: 'TransactionDetailsView',
  setup(__props) {

const { t } = useI18n();
const display = useDisplay();
const { name } = useTheme();
const route = useRoute();

const currentTab = ref(DetailTransactionTabs.Summary);

const transactionDetails = reactive<Partial<ITransactionDetails>>({});

const tabsSwitchOptions: TabsSwitchItem<DetailTransactionTabs>[] = reactive([
  {
    id: DetailTransactionTabs.Summary,
    label: label.TransactionDetailsPage.DetailTransactionTabs.Summary.title,
    active: false,
  },
  {
    id: DetailTransactionTabs.History,
    label: label.TransactionDetailsPage.DetailTransactionTabs.History.title,
    active: false,
  },
  {
    id: DetailTransactionTabs.Items,
    label: label.TransactionDetailsPage.DetailTransactionTabs.Items.title,
    active: false,
  },
  {
    id: DetailTransactionTabs.Payment,
    label: label.TransactionDetailsPage.DetailTransactionTabs.Payment.title,
    active: false,
  },
  {
    id: DetailTransactionTabs.Contacts,
    label: label.TransactionDetailsPage.DetailTransactionTabs.Contacts.title,
    active: false,
  },
]);

onMounted(async () => {
  const { transactionId } = route.params;
  await fetchTransactionDetails(transactionId as string);
});

const srcImage = computed(() => {
  if (name.value == "light") {
    return imgDesktopLight;
  } else {
    return imgDesktopDark;
  }
});

watch(
  currentTab,
  (newValue) => {
    tabsSwitchOptions.forEach(
      (v) => (v.active = v.id == newValue ? true : false)
    );
  },
  { immediate: true }
);

const actionDetailsOptions: ActionItem<ActionTransactionType>[] = [
  {
    id: ActionTransactionType.ForceApproval,
    icon: "mdi-check",
  },
  {
    id: ActionTransactionType.Reanalyze,
    icon: "mdi-repeat",
  },
  {
    id: ActionTransactionType.Prioritize,
    icon: "mdi-priority-high",
  },
  {
    id: ActionTransactionType.RequestChargeback,
    icon: "mdi-rotate-left",
  },
  {
    id: ActionTransactionType.CancelOrder,
    icon: "mdi-square",
  },
];

async function fetchTransactionDetails(transactionId: string) {
  const dateResult = await fetch(`transactions/${transactionId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then(async (response) => {
    return response.json();
  });

  transactionDetails.summary = dateResult.summary;
  transactionDetails.contacts = dateResult.contacts;
  transactionDetails.history = dateResult.history;
  transactionDetails.items = dateResult.items;
  transactionDetails.device = dateResult.device;
  transactionDetails.payments = dateResult.payments;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({ padding: `${_unref(display).mdAndUp ? '0px 40px' : '0px 16px'}` })
  }, [
    _createElementVNode("div", {
      style: _normalizeStyle({ height: _unref(display).mdAndUp ? '220px' : '120px' })
    }, [
      _createVNode(CustomHero, {
        class: "d-flex align-end justify-space-between",
        label: _unref(t)(_unref(label).TransactionDetailsPage.title),
        "src-image": srcImage.value
      }, null, 8, ["label", "src-image"])
    ], 4),
    _createVNode(TabsSwitch, {
      options: tabsSwitchOptions,
      onClick: _cache[0] || (_cache[0] = (id) => (currentTab.value = id))
    }, null, 8, ["options"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (currentTab.value == _unref(DetailTransactionTabs).Summary)
          ? (_openBlock(), _createBlock(TransactionSummary, {
              key: 0,
              summary: transactionDetails?.summary
            }, null, 8, ["summary"]))
          : _createCommentVNode("", true),
        (currentTab.value == _unref(DetailTransactionTabs).History)
          ? (_openBlock(), _createBlock(TransactionHistory, {
              key: 1,
              history: transactionDetails?.history
            }, null, 8, ["history"]))
          : _createCommentVNode("", true),
        (currentTab.value == _unref(DetailTransactionTabs).Items)
          ? (_openBlock(), _createBlock(TransactionItems, {
              key: 2,
              items: transactionDetails?.items
            }, null, 8, ["items"]))
          : _createCommentVNode("", true),
        (currentTab.value == _unref(DetailTransactionTabs).Payment)
          ? (_openBlock(), _createBlock(TransactionPayments, {
              key: 3,
              device: transactionDetails?.device,
              payments: transactionDetails?.payments ?? []
            }, null, 8, ["device", "payments"]))
          : _createCommentVNode("", true),
        (currentTab.value == _unref(DetailTransactionTabs).Contacts)
          ? (_openBlock(), _createBlock(TransactionContacts, {
              key: 4,
              billing: transactionDetails.contacts?.billing,
              shipping: transactionDetails.contacts?.shipping
            }, null, 8, ["billing", "shipping"]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(ActionDetails, {
        style: {"justify-self":"flex-end"},
        options: actionDetailsOptions,
        onClick: _cache[1] || (_cache[1] = (id) => console.log(id))
      })
    ])
  ], 4))
}
}

})