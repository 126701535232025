<template>
  <Teleport to="body">
    <div
      ref="refOverlay"
      class="position-fixed"
      style="
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        overflow: hidden;
        z-index: 999;
      "
      :style="{
        ...overlayStyleAccordingTheme,
        display: toggle ? 'initial' : 'none',
      }"
      @click="animate"
    />
  </Teleport>
  <div class="position-fixed" style="bottom: 24px; right: 15px; z-index: 1000">
    <div
      v-for="(option, i) in options"
      :key="i"
      :id="ActionDetailsMobileOptionButtonContainer"
      class="d-flex flex-row ga-2 align-center position-fixed"
      style="right: 15px; margin-bottom: 8px"
      :data-testid="
        generateTestId(
          `${ActionDetailsMobileOptionButtonContainer}-${option.id}`
        )
      "
    >
      <v-btn
        rounded="xl"
        elevation="1"
        style="text-transform: none"
        color=""
        :style="buttonStyleAccordingTheme"
        :data-testid="generateTestId(ActionDetailsMobileOptionButtonLeft)"
        @click="emitAndRipple(option.id)"
      >
        {{ option.label }}
      </v-btn>
      <v-btn
        rounded="lg"
        size="40px"
        class="pa-0"
        color=""
        :style="buttonStyleAccordingTheme"
        :data-testid="generateTestId(ActionDetailsMobileOptionButtonRight)"
        @click="emitAndRipple(option.id)"
      >
        <v-icon size="24px">{{ option.icon }}</v-icon>
      </v-btn>
    </div>
    <v-btn
      :data-testid="generateTestId(ActionDetailsMobileMainButton)"
      :prepend-icon="toggle ? 'mdi-close' : 'mdi-table-of-contents'"
      :elevation="1"
      color=""
      style="text-transform: none; height: 56px"
      :style="buttonStyleAccordingTheme"
      @click="animate"
    >
      Actions
    </v-btn>
  </div>
</template>
<script setup lang="ts">
import { onMounted, reactive, ref, watch } from "vue";
import { useTheme } from "vuetify";
import { generateTestId } from "@/helpers/generateTestId";
import { ActionItem, ActionTransactionType } from "@/model/actionsDetails";
import gsap from "@/plugins/gsap";

import {
  ActionDetailsMobileMainButton,
  ActionDetailsMobileOptionButtonContainer,
  ActionDetailsMobileOptionButtonLeft,
  ActionDetailsMobileOptionButtonRight,
} from "./constants/ActionDetailsMobileIds";
import { useStyleByTheme } from "@/components/hooks/useStyleByTheme";

const ACTION_BUTTON_HEIGHT = 80; //actual button height + bottom from position-fixed;

const props = defineProps<{ options: ActionItem[] }>();
const emits = defineEmits<(e: "click", id: ActionTransactionType) => void>();

const { name: themeName } = useTheme();

const refOverlay = ref<HTMLElement | undefined>(undefined);
const actionDetailsButtons: Element[] = reactive([]);
const toggle = ref(false);

const overlayStyleAccordingTheme = useStyleByTheme({
  light: { backgroundColor: "rgba(var(--v-ref-palette-neutral10), 0.5)" },
  dark: { backgroundColor: "rgba(0, 0, 0, 0.4)" },
});

const buttonStyle = {
  light: {
    backgroundColor: "rgb(var(--v-ref-palette-information95))",
    color: "rgb(var(--v-ref-palette-tertiary40))",
  },
  dark: {
    backgroundColor: "rgb(var(--v-ref-palette-information30))",
    color: "rgb(var(--v-ref-palette-tertiary80))",
  },
};

const buttonStyleAccordingTheme = useStyleByTheme(buttonStyle);

watch(
  () => props.options,
  (newOptions, oldOptions) => {
    actionDetailsButtons.splice(0, actionDetailsButtons.length);
    actionDetailsButtons.push(
      ...gsap.utils.toArray<Element>(
        `#${ActionDetailsMobileOptionButtonContainer}`
      )
    );
  }
);

onMounted(() => {
  actionDetailsButtons.push(
    ...gsap.utils.toArray<Element>(
      `#${ActionDetailsMobileOptionButtonContainer}`
    )
  );

  gsap.set(actionDetailsButtons, { autoAlpha: 0 });
});

function emitAndRipple(id: number) {
  emits("click", id);
  animate();
}

function animate() {
  toggle.value = !toggle.value;

  gsap.to(actionDetailsButtons, {
    bottom: (i) => (toggle.value ? 50 * i + ACTION_BUTTON_HEIGHT : 0),
    ease: "back.out(1)",
    autoAlpha: +toggle.value,
  });
}
</script>
