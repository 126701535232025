<template>
  <transaction-card-list v-if="data.length" :onOnLoadLastItem="fetchData">
    <transaction-card
      v-for="(transaction, index) in data"
      :key="index"
      :class="[themeName, checkedList[index] ? 'checked' : '']"
      @click.prevent="containerClick(transaction)"
      @touchStart.prevent="containerClick(transaction)"
      :active="onSomeChecked()"
    >
      <template #left>
        <div class="left">
          <div>
            <div class="id">
              <span>#{{ ellipsisText(transaction.code) }}</span>
            </div>
            <div class="status">
              <div
                :class="[
                  themeName,
                  ITransactionStatus[transaction.status],
                  'text',
                ]"
              >
                <v-icon :icon="transactionStatusIcon[transaction.status]" />
                <span>{{ t(`${label.Shared.TransactionStatus._}.${ITransactionStatus[transaction.status]}`) }}</span>
              </div>
            </div>
          </div>
          <div>
            <div class="status-icon select">
              <v-btn
                variant="text"
                color=""
                :class="[
                  themeName,
                  classNameWhole[transaction.status],
                  'whole',
                  checkedList[index] ? 'checked' : '',
                ]"
                @click.prevent="iconClick(transaction, index)"
                @touchstart.prevent.stop="iconClick(transaction, index)"
              >
                <v-icon
                  :icon="
                    !checkedList[index]
                      ? transactionStatusIcon[transaction.status]
                      : 'mdi-circle'
                  "
                />
              </v-btn>
            </div>
            <div>
              <div>
                <div v-if="transaction.entityName" class="client-name">
                  <span>{{ ellipsisText(transaction.entityName) }}</span>
                </div>
                <div class="date">
                  <span>{{ d(transaction.consumerRequestedAt, 'short') }}</span>
                </div>
              </div>
              <div class="value">
                {{
                  transaction.totalAmount.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })
                }}
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #right>
        <div class="right">
          <v-btn variant="text" icon="mdi-check" color="information" />
          <v-btn variant="text" icon="mdi-repeat" color="information" />
          <v-btn variant="text" icon="mdi-priority-high" color="information" />
          <v-btn variant="text" icon="mdi-rotate-left" color="information" />
          <v-btn variant="text" icon="mdi-square" color="information" />
        </div>
      </template>
    </transaction-card>
  </transaction-card-list>
</template>

<script setup lang="ts">
                    //isTodayDate(transaction.consumerRequestedAt)
                      //? d.format(transaction.consumerRequestedAt, "fullTime12h")
                      //: d.format(transaction.consumerRequestedAt, "fullDate")
import { label } from "@/plugins/locales/global";
import TransactionCard from "@/components/shared/TransactionCard/TransactionCard.vue";
import TransactionCardList from "@/components/shared/TransactionCardList/TransactionCardList.vue";

import { transactionStatusIcon } from "@/model/transaction";
import { useDate, useTheme } from "vuetify";
import { defineEmits, defineProps, onMounted, ref } from "vue";
import {
  ITransaction,
  ITransactionStatus,
} from "@/api/resources/Transactions/transactionResource";
import {
  useClassByThemeStatus,
  UseClassByThemeStatusProps,
} from "../hooks/useClassByTheme";
import { useI18n } from "vue-i18n";

const { t,d } = useI18n();

const props = defineProps<{ data: ITransaction[] }>();

function isTodayDate(date: Date) {
  const dateNow = new Date(date);
  const todayDate = new Date();
  todayDate.setHours(0, 0, 0, 0);
  dateNow.setHours(0, 0, 0, 0);

  return dateNow.getTime() === todayDate.getTime();
}

const emits = defineEmits<{
  (e: "iconClick", transaction: ITransaction): void;
  (e: "containerClick", transaction: ITransaction): void;
  (e: "fetchData"): void;
}>();

const { name: themeName } = useTheme();

//const d = useDate();

const checkedList = ref<{ [x: number]: boolean }>({});

function onSomeChecked(): boolean {
  return Object.values(checkedList.value).some((value) => value);
}

function ellipsisText(input: string): string {
  const INPUT_LENGTH = 14;
  return input.length > INPUT_LENGTH
    ? `${input.substring(0, INPUT_LENGTH)}...`
    : input;
}

function iconClick(transaction: ITransaction, index: number) {
  emits("iconClick", transaction);
  if (!checkedList.value[index]) {
    checkedList.value[index] = true;
    return;
  }

  checkedList.value[index] = !checkedList.value[index];
}

function containerClick(transaction: ITransaction) {
  emits("containerClick", transaction);
}

function fetchData(): void {
  emits("fetchData");
}

const classThemeWholeDefault = {
  [ITransactionStatus.Approved]: "bg-success-container text-success",
  [ITransactionStatus.Denied]: "bg-error-container text-error",
  [ITransactionStatus.Analyzing]: "bg-information-container text-information",
  [ITransactionStatus.Canceled]: "bg-surface-dim text-on-surface-variant",
  [ITransactionStatus.None]: "",
};

const classThemeWhole: UseClassByThemeStatusProps = {
  light: {
    ...classThemeWholeDefault,
  },
  dark: {
    ...classThemeWholeDefault,
    [ITransactionStatus.Approved]:
      "bg-cs_ref_palette_success80 text-cs_ref_palette_success10",
    [ITransactionStatus.Denied]:
      "bg-cs_ref_palette_error95 text-cs_ref_palette_error10",
    [ITransactionStatus.Analyzing]:
      "bg-cs_ref_palette_information90 text-cs_ref_palette_information10",
  },
};

const classNameWhole = useClassByThemeStatus(classThemeWhole);

onMounted(() => {
  props.data.forEach((_, index) => {
    checkedList.value[index] = false;
  });
});
</script>

<style scoped lang="scss">
@use "@/styles/mixin";
@use "@/styles/status";

:deep(#container) {
  &:not(:last-of-type) {
    margin-bottom: 8px;
  }
}

:deep(#transaction-card) {
  border-radius: var(--mdui-shape-corner-extra-large);
  box-shadow: var(--mdui-elevation-level1);
}

.v-card {
  & .left {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    padding: 12px;
    box-sizing: border-box;
    border-radius: var(--mdui-shape-corner-medium);

    & > :first-of-type {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
      font-size: var(--mdui-typescale-body-medium-size);

      & > .status {
        text-align: right;
      }
    }

    & > :last-of-type {
      display: flex;
      flex-direction: row;

      & > :first-of-type {
        margin-right: 16px;
      }

      & > :last-of-type {
        flex: 1;
        display: flex;
        flex-direction: row;
      }
    }

    &:not(:last-of-type) {
      margin-bottom: 8px;
    }

    &.checked {
      @include mixin.backgroundColorScheme("information", 95);
    }

    .id,
    .client-name,
    .value,
    .date {
      @include mixin.colorScheme("neutral-variant", 30);
    }

    .status-icon > .v-btn {
      $size: 36px;
      min-width: $size;
      height: $size;

      padding: 0px;

      border-radius: var(--mdui-shape-corner-large);

      &.checked {
        @include mixin.wholeScheme("tertiary", 95, 10);
      }

      &.checked .mdi-circle {
        font-size: 1rem;
      }
    }

    .status {
      & > div {
        display: inline-flex;
        align-items: center;

        & .v-icon {
          font-size: 0.5rem;
          margin-right: 2px;
        }
      }
    }

    .client-name {
      font-weight: var(--mdui-typescale-label-large-weight);
    }

    .date {
      font-size: var(--mdui-typescale-body-small-size);
    }

    .value {
      font-size: var(--mdui-typescale-title-large-size);
      color: var(--mdui-ref-palette-neutral10);
      flex: 1;
      text-align: right;
      align-self: center;
    }
  }

  & .right {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
