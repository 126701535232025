import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-testid"]

import { generateTestId } from "@/helpers/generateTestId";
import { onBeforeUpdate, onMounted } from "vue";
import { TransactionCardListElement } from "./constants/TransactionCardListId";
import { useDisplay } from "vuetify";


export default /*@__PURE__*/_defineComponent({
  __name: 'TransactionCardList',
  emits: ["onLoadLastItem"],
  setup(__props, { emit: __emit }) {

const { mobile } = useDisplay();

const emits = __emit;

let lastTransactionCardItem: Element | null = null;

onMounted(() => {
  trackNewElement();
  createOrUpdateObserver();
});

onBeforeUpdate(() => {
  trackNewElement();
  createOrUpdateObserver();
});

function trackNewElement() {
  let target = mobile.value ? "#container" : "#transaction-card";
  let transactionCardQuery = document.querySelectorAll(target);
  lastTransactionCardItem =
    transactionCardQuery.length >= 10
      ? transactionCardQuery.item(transactionCardQuery.length - 1)
      : null;
}

function createOrUpdateObserver(): void {
  if (!lastTransactionCardItem) return;

  let options = {
    root: null,
    rootMargin: "0px",
    threshold: 1,
  };

  let intersectionObserver = new IntersectionObserver(handleIntersect, options);
  intersectionObserver.observe(lastTransactionCardItem);
}

function handleIntersect(
  entries: IntersectionObserverEntry[],
  observer: IntersectionObserver
) {
  entries.forEach((e) => {
    if (e.isIntersecting) {
      lastTransactionCardItem = null;

      observer.unobserve(e.target);
      emits("onLoadLastItem");
    }
  });
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "transaction_card_list",
    "data-testid": _unref(generateTestId)(_unref(TransactionCardListElement))
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 8, _hoisted_1))
}
}

})