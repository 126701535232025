export const label = {
  TopMenu: {
    Search: "Procurar na ClearSale"
  },
  Shared: {
    TransactionStatus: {
      Approved: "Aprovado",
      Denied: "Negado",
      Analyzing: "Analisando",
      Canceled: "Cancelado",
      _: "label.Shared.TransactionStatus"
    },
    TransactionActions: {
      ForceApproval: "Forçar aprovação",
      Reanalyze: "Reanalisar",
      Prioritize: "Priorizar",
      RequestChargeback: "Solicitar reembolso",
      CancelOrder: "Cancelar pedido",
      _: "label.Shared.TransactionActions"
    }
  },
  SummaryCard: {
    title: "Pedidos"
  },
  AddOrder: "Adicionar",
  ButtonFilter: {
    ID: {
      title: "ID",
      placeholder: "Digite os IDs"
    },
    Date: {
      title: "Data",
      Week: "1 semana",
      MTD: "Mês corrente",
      Month: "1 mês",
      ThreeMonths: "3 meses",
      SixMonths: "6 meses",
      OneYear: "1 ano",
      Custom: "Customizado"
    },
    Store: {
      title: "Loja",
      placeholder: "Digite os nomes das lojas"
    },
    Status: "Status",
    Value: {
      title: "Valor",
      From: "De:",
      To: "Até:"
    },
    Advanced: "Avançado",
    BottomSheet: {
      suggestions: "Sugestões",
      Date: {
        CustomPeriod: "Customizado"
      }
    }
  },
  TransactionDetailsPage: {
    title: "Detalhes do pedido",
    Actions: "Ações",
    DetailTransactionTabs: {
      Summary: {
        title: "Resumo",
        Order: {
          title: "Pedido",
          ID: "ID",
          Store: "Loja",
          Value: "Valor"
        },
        Status: {
          title: "Status",
          TransactionStatus: "Status do pedido",
          ChargebackStatus: "Status do reembolso"
        },
        Costumer: {
          title: "Cliente",
          Email: "Email"
        },
        Dates: {
          title: "Datas",
          TransactionDate: "Data do pedido",
          ClearSaleDate: "Data ClearSale"
        }
      },
      History: {
        title: "Histórico",
        OrderHistory: {
          title: "Histórico do pedido"
        },
        TransactionReceived: "Pedido recebido",
        Analysis: "Análise",
        Risks: "Riscos",
        FraudScore: "Score de fraude",
        Results: "Resultados",
        TransactionDenied: "Pedido recusado",
        ReanalysisRequested: "Reanálise",
        User: "Usuário",
        Attachments: "Anexos",
        Comments: "Comentários",
        TransactionApproved: "Pedido aprovado",
        ChargebackRequested: "Reembolso solicitado",
        ChargebackApproved: "Reembolso aprovado",
        ChargebackPaid: "Reembolso pago",
        ShowMore: "Ver mais",
        ShowLess: "Fechar"
      },
      Items: {
        title: "Itens",
        Breakdown: {
          title: "Detalhamento",
          Total: "Total",
          Categories: "Categorias"
        }
      },
      Payment: {
        title: "Pagamento",
        Device: {
          title: "Dispositivo",
          IPAddress: "Endereço IP"
        },
        PaymentType: {
          Name: "Nome",
          Number: "Número",
          Amount: "Valor"
        }
      },
      Contacts: {
        title: "Contatos",
        Billing: {
          title: "Endereço de cobrança"
        },
        Phone: "Telefone",
        Address: "Endereço",
        Shipping: {
          title: "Endereço de envio"
        },
        ViewMap: "Ver no mapa"
      }
    }
  }
}