<template>
  <div ref="refPinnedContainer" :class="pinnedContainerClass">
    <div
      ref="refSlotContainer"
      :class="slotContainerClass"
      class="d-flex flex-column justify-end bg-transparent"
    >
      <h2
        class="font-weight-regular on-tertiary-container pl-3"
        style="font-size: 22px"
      >
        Actions
      </h2>
      <div class="d-flex flex-column ga-2">
        <v-btn
          v-for="(option, i) in options"
          :key="i"
          density="comfortable"
          class="justify-start"
          :prepend-icon="option.icon"
          style="text-transform: none"
          color="tertiary"
          elevation="0"
          variant="text"
          @click.stop="() => emits('click', option.id)"
        >
          {{ option.label }}
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ActionItem, ActionTransactionType } from "@/model/actionsDetails";
import { onMounted, ref } from "vue";
import { ScrollTrigger } from "gsap/ScrollTrigger";

defineProps<{ options: ActionItem[] }>();
const emits = defineEmits<(e: "click", id: ActionTransactionType) => void>();

onMounted(() => {
  const t = setTimeout(() => {
    attachScrollAnimation();
    clearTimeout(t);
  }, 300);
});

const refPinnedContainer = ref<HTMLDivElement | null>(null);
const pinnedContainerClass = "pinned-container";

const refSlotContainer = ref<HTMLDivElement | null>(null);
const slotContainerClass = "slot-container";

function attachScrollAnimation() {
  if (refPinnedContainer.value && refSlotContainer.value) {
    ScrollTrigger.create({
      start: "top-=100px top",
      end: () => document.body.scrollHeight,
      markers: false,
      pin: true,
      pinSpacing: false,
      trigger: refPinnedContainer.value,
    });
  }
}
</script>
