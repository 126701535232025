import { computed, DefineProps, onMounted, ref, watch } from "vue";

export type DatepickerProps = { range?: boolean; dates?: Date[]; }
export type DatepickerEmits = {
  (e: "cancel"): void;
  (e: "submit", date: Date[]): void;
}

export function useDatepicker(props: DatepickerProps, emits: DatepickerEmits) {
  const dateModel = ref<Date[]>(props.dates || []);

  const showToDate = computed(() => {
    if (dateModel.value.length) {
      if (props.range) {
        return dateModel.value.length >= 2
          ? [dateModel.value[0], dateModel.value[dateModel.value.length - 1]]
          : [dateModel.value[0]];
      }
      return [dateModel.value[0]];
    }
    return [];
  });

  function formatDate(date: Date | null, short: boolean) {
    if (!date) {
      return '';
    }

    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    const daysShortOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    const monthsOfYear = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const monthsShortOfYear = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];


    const dayName = short
      ? daysShortOfWeek[date.getDay()]
      : daysOfWeek[date.getDay()];
    const monthName = short
      ? monthsShortOfYear[date.getMonth()]
      : monthsOfYear[date.getMonth()];
    const dayNumber = date.getDate();
    return `${dayName}, ${monthName} ${dayNumber}`;
  }

  function mapFormatDate(dates: Date[], short = false) {
    if (dates.length === 0) {
      return [];
    }

    return dates.map((date) => formatDate(date, short));
  }

  function handleCancelDate() {
    emits("cancel");
    dateModel.value = props.dates || [];
  }

  function handleSubmit() {
    if (dateModel.value.length === 0) {
      return;
    }

    emits(
      "submit",
      dateModel.value.length === 1
        ? [dateModel.value[0], dateModel.value[0]]
        : dateModel.value
    );
  }

  return { formatDate, dateModel, showToDate, mapFormatDate, handleCancelDate, handleSubmit };
}
