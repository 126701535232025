import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { SuggestionItem } from "@/api/resources/Transactions/transactionResource";
import { generateTestId } from "@/helpers/generateTestId";
import { ButtonFilterTextConstants } from "./constants/ButtonFilterIDs";


export default /*@__PURE__*/_defineComponent({
  __name: 'ButtonFilterTextSuggestionBox',
  props: {
    options: {},
    show: { type: Boolean },
    name: {},
    loadingSuggestions: { type: Boolean },
    handleAddSuggestion: { type: Function }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item_subtitle = _resolveComponent("v-list-item-subtitle")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!

  return (_ctx.show)
    ? (_openBlock(), _createBlock(_component_v_list, {
        key: 0,
        height: _ctx.options.length < 4 ? 'auto' : '208px',
        width: "max-content",
        "data-testid": 
      _unref(generateTestId)(
        _unref(ButtonFilterTextConstants).ButtonFilterTextSuggestionsBox,
        (_ctx.name ?? '') + `-active-${_ctx.show}`
      )
    
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
            return (_openBlock(), _createBlock(_component_v_list_item, {
              style: {"cursor":"pointer"},
              key: option.id,
              onClick: ($event: any) => (_ctx.handleAddSuggestion(option)),
              disabled: _ctx.loadingSuggestions
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_list_item_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(option.value), 1)
                  ]),
                  _: 2
                }, 1024),
                (option.subValue)
                  ? (_openBlock(), _createBlock(_component_v_list_item_subtitle, { key: 0 }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(option.subValue), 1)
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["onClick", "disabled"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["height", "data-testid"]))
    : _createCommentVNode("", true)
}
}

})