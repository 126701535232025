<template>
  <ContainerFixedTop style="z-index: 9">
    <ContainerSwiper>
      <div
        style="
          padding-top: 4px;
          transition: padding-top ease-in-out 0.3s,
            padding-bottom ease-in-out 0.3s;
        "
        class="d-flex flex-row ga-2"
      >
        <v-btn
          ref="refIcon"
          variant="flat"
          density="comfortable"
          size="40px"
          :style="computedReturnButtonStyle"
          color=""
          icon="mdi-arrow-left"
          to="./"
        />
        <TabsSwitch
          style="width: 545px"
          :options="options"
          @selected="(id) => emits('click', id)"
        />
      </div>
    </ContainerSwiper>
  </ContainerFixedTop>
</template>
<script setup lang="ts">
import TabsSwitch from "@/components/shared/TabsSwitch/TabsSwitch.vue";
import ContainerSwiper from "@/components/shared/ContainerSwiper/ContainerSwiper.vue";
import ContainerFixedTop from "@/components/shared/ContainerFixedTop/ContainerFixedTop.vue";

import { TabsSwitchItem } from "@/model/tabSwitch";
import { DetailTransactionTabs } from "@/model/transaction";
import { useStyleByTheme } from "@/components/hooks/useStyleByTheme";

const returnButtonStyle = {
  light: {
    backgroundColor: "rgba(var(--v-ref-palette-neutral95))",
    color: "rgba(var(--v-ref-palette-neutral-variant30))",
  },
  dark: {
    backgroundColor: "rgba(var(--v-theme-surface-container-high))",
    color: "rgba(var(--v-theme-on-surface-variant))",
  },
};

defineProps<{ options: TabsSwitchItem<DetailTransactionTabs>[] }>();
const emits = defineEmits<(e: "click", id: DetailTransactionTabs) => void>();

const computedReturnButtonStyle = useStyleByTheme(returnButtonStyle);
</script>
