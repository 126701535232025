<template>
  <cs-menu
    v-if="mdAndUp"
    ref="csMenuRef"
    baseUrl="/shield"
    :themeActive="themeName"
    :imgOption="imgClearsaleOptions"
    :menuItems="menuItemsEvent"
  />
  <header-navigation @openMenu="openMenu" />
</template>

<script lang="ts" setup>
import { navigateToUrl } from "single-spa";
import { onMounted, ref, watch } from "vue";
import { CSMenu } from "@clearsale/material-ds/dist/types/components/cs-menu/cs-menu";
import { useDisplay, useTheme } from "vuetify";

import imgClearsale from "@/assets/logo_mobile.png";

import HeaderNavigation from "../NavigationHeader/NavigationHeader.vue";
import { navigationItems } from "./navigation-data";

const menuItemsEvent = ref(navigationItems);

const OPEN_MENU_EVENT = new CustomEvent("openMenu");

const csMenuRef = ref<(CSMenu & HTMLElement) | null>(null);

const { mdAndUp } = useDisplay();
const { name: themeName } = useTheme();

const imgClearsaleOptions = ref({
  src: imgClearsale,
  alt: "Logo Clear Sale",
});

onMounted(() => {
  if (csMenuRef.value) {
    csMenuRef.value.addEventListener("activeItem", handleChangeRouteTo);
  }
});

function handleChangeRouteTo(evt: Event) {
  navigateToUrl((evt as CustomEvent).detail.linkTo);
}

function openMenu() {
  if (csMenuRef.value) document.dispatchEvent(OPEN_MENU_EVENT);
}

watch(csMenuRef, (value) => {
  if (value && csMenuRef.value) {
    csMenuRef.value.addEventListener("activeItem", handleChangeRouteTo);
  }
});
</script>
