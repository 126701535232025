import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import ComponentPreview from '../views/ComponentPreview.vue'
import TransactionView from '@/views/TransactionView.vue'
import TransactionDetailsView from '@/views/TransactionDetailsView.vue'
import { useOIDCCallback } from '@/hooks/useOIDCCallback'
import { redirectToLogin, validateSession } from '@/services/auth'
import { useLocalStorage } from "@/utils/localstorage/localstorage"

export const routeNames = {
  transactionView: 'ShieldView',
  transactionsDetails: 'transactionDetailView',
  preview: 'preview'
}

const activeSSO = process.env.VUE_APP_ENV !== "development"

const routes: Array<RouteRecordRaw> = [
  {
    path: '/shield',
    component: { template: '', beforeCreate() { this.$router.push({ name: routeNames.transactionView }) } }
  },
  {
    path: '/shield/transactions',
    name: routeNames.transactionView,
    component: TransactionView,
  },
  {
    path: '/shield/:transactionId',
    name: routeNames.transactionsDetails,
    component: TransactionDetailsView
  },
  {
    path: '/shield/preview',
    name: routeNames.preview,
    component: ComponentPreview
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  function redirect() {
    next({
      path: useLocalStorage().getStorage('before-route-path') as string,
      replace: true
    })

    useLocalStorage().removeStorage("before-route-path")
  }

  if (activeSSO) {

    if (to.query.code) {
      useOIDCCallback(to.query.code as string, redirect);
      return
    }

    if (!validateSession()) {
      redirectToLogin(to.fullPath);
    }
  }

  next()
})


export default router
