<template>
  <v-chip-group
    :data-testid="generateTestId(ChipSelectableElements.ChipGroupContent)"
    selected-class="bg-secondary-container text-shadow border-none"
    v-model="model"
    :multiple="multiple"
    column
  >
    <div :class="{ 'd-flex ga-4': !isMobile }">
      <v-chip
        v-for="chip in chips"
        :data-testid="
          generateTestId(
            (ChipSelectableElements.ChipGroupContent,
            `${ChipSelectableElements.ChipSelectableContent}-${chip.chipId}`)
          )
        "
        class="rounded-lg on-surface-variant"
        :class="{ 'ma-0': !isMobile }"
        variant="outlined"
        style="font-weight: bold"
        :value="chip"
        :key="chip.chipId"
        filter
        :filter-icon="ChipSelectableElements.ChipSelectedIcon"
        @click="handleSelectChip(chip.chipId)"
      >
        {{ chip.label }}
      </v-chip>
    </div>
  </v-chip-group>
</template>

<script lang="ts" setup>
import { defineModel } from "vue";
import { generateTestId } from "@/helpers/generateTestId";
import { Chip } from "@/model/chip";
import { ChipSelectableElements } from "./constants/ChipSelectableElements";
import {
  SelectChipEmits,
  useSelectChip,
} from "./constants/ChipSelectableEvents";

withDefaults(
  defineProps<{ chips: Chip[]; multiple?: boolean; isMobile?: boolean }>(),
  { multiple: false, isMobile: false }
);

const model = defineModel();

const emit = defineEmits<SelectChipEmits>();

const selectChip = useSelectChip(emit);

const handleSelectChip = (id: string | number) =>
  selectChip.handleSelectChip(id);
</script>
