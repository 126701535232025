import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-testid"]
const _hoisted_2 = ["data-testid"]

import { generateTestId } from "@/helpers/generateTestId";
import { ContainerFixedTopElements } from "./constants/ContainerFixedTopId";
import { useContainerFixedTop } from "./useContainerFixedTop";

import { useTheme } from "vuetify/lib/framework.mjs";
import { useClassByTheme } from "@/components/hooks/useClassByTheme";


export default /*@__PURE__*/_defineComponent({
  __name: 'ContainerFixedTop',
  props: {
    name: {}
  },
  emits: ["handleTop"],
  setup(__props: any, { emit: __emit }) {



const emit = __emit;

const { containerFixedTop } = useContainerFixedTop(emit);

const containerClass = {
  light: "bg-cs_on_primary",
  dark: "bg-cs_surface_container_low",
};

const classTheme = useClassByTheme(containerClass);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    style: {
      position: 'sticky',
      top: 0,
      width: '100%',
    },
    class: _normalizeClass(_unref(classTheme)),
    "data-testid": 
      _unref(generateTestId)(_unref(ContainerFixedTopElements).ContainerFixedTop, _ctx.name)
    ,
    ref_key: "containerFixedTop",
    ref: containerFixedTop
  }, [
    _createElementVNode("div", {
      "data-testid": 
        _unref(generateTestId)(_unref(ContainerFixedTopElements).ContainerFixedTopContent, _ctx.name)
      
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 8, _hoisted_2)
  ], 10, _hoisted_1))
}
}

})