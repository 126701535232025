<template>
  <div class="d-flex align-center flex-1-1">
    <v-btn
      v-for="({ id, label }, i) in options"
      :key="i"
      class="flex-1-1 h-100 text-capitalize"
      variant="text"
      color="surface-variant"
      @click="() => emits('selected', id)"
      >{{ label }}</v-btn
    >
  </div>
</template>
<script setup lang="ts">
import { TabsSwitchItem } from "@/model/tabSwitch";

const props = defineProps<{ options: Array<TabsSwitchItem> }>();
const emits = defineEmits<(e: "selected", id: TabsSwitchItem["id"]) => void>();
</script>
