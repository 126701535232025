import { h, createApp } from 'vue';
import singleSpaVue from 'single-spa-vue';
import "@clearsale/material-ds/dist/clearsale-material-ds/clearsale-material-ds.css"
import { defineCustomElements } from '@clearsale/material-ds/dist/loader'

import vuetify from '@/plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import { pinia } from './plugins/store';

import App from './App.vue';
import router from './router';


loadFonts()

defineCustomElements(window);

const vueLifecycles = singleSpaVue({
  createApp,
  appOptions: {

    render() {
      return h(App, {
        // single-spa props are available on the "this" object. Forward them to your component as needed.
        // https://single-spa.js.org/docs/building-applications#lifecycle-props
        // if you uncomment these, remember to add matching prop definitions for them in your App.vue file.
        /*
        name: this.name,
        mountParcel: this.mountParcel,
        singleSpa: this.singleSpa,
        */
      });
    },
  },
  handleInstance(app) {
    app.use(vuetify)
    app.use(pinia)
    app.use(router);
  },
});


async function enableMocking() {
  if (process.env.VUE_APP_ENV !== 'development') {
    return
  }

  const module = await import('./services/msw')

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return module.worker.start()
}

export const bootstrap = async () => {
  await enableMocking()
  return vueLifecycles.bootstrap
};
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
