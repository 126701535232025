<template>
  <div
    style="
      background-color: white;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
    "
    v-if="showApp"
  >
    <v-theme-provider>
      <div
        :class="className"
        style="flex: 1; display: flex; flex-direction: column"
      >
        <OneMenu v-if="load" class="py-4"></OneMenu>
        <RouterView />
      </div>
    </v-theme-provider>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref, onUnmounted, onBeforeMount } from "vue";
import { useClassByTheme } from "./components/hooks/useClassByTheme";
import { validateSession } from "./services/auth";
import OneMenu from "@/components/one/NewMenu/OneMenu.vue";
import { useRouter } from "vue-router";
import { enableMocking } from "./api/mocks/browser";

import imgShield from "@/assets/logo_shield.svg";
import { MergeHead, ActiveHeadEntry, useHead, UseHeadInput } from "@unhead/vue";
import { useTheme } from "vuetify/lib/framework.mjs";

const { global } = useTheme();

const classTheme = {
  light: "bg-cs_on_primary",
  dark: "bg-cs_surface_container_low",
};

let theme = ref();

const headInfo = useHead({
  title: "Shield",
  link: [{ rel: "icon", href: imgShield, type: "image/svg+xml" }],
}) as ActiveHeadEntry<UseHeadInput<MergeHead>>;

const activeSSO = process.env.VUE_APP_ENV !== "development";

const router = useRouter();
const className = useClassByTheme(classTheme);

const showApp = ref(false);
const load = ref(false);

addEventListener("access-token", () => {
  showApp.value = true;
});

onBeforeMount(() => {
  theme.value = localStorage.getItem("theme");
  global.name.value = theme.value !== "dark" ? "light" : "dark";
});

onMounted(() => {
  if (activeSSO && !validateSession()) {
    showApp.value = false;
    return;
  }

  showApp.value = true;
});

onUnmounted(() => {
  headInfo.dispose();
});

router.beforeResolve((to, from, next) => {
  enableMocking().finally(() => {
    next();
    load.value = true;
  });
});

onMounted(() => {
  if (activeSSO && !validateSession()) {
    showApp.value = false;
    return;
  }
  showApp.value = true;
});
</script>

<style></style>
