<template>
  <ButtonFilter
    label="Date"
    @submit-filter="submitButtonFilter"
    @clear-filter="clearAllFilter"
    @close-filter="handleCloseFilter"
    :active="
      selectedTimePeriodModel !== undefined && selectedTimePeriodModel !== null
    "
  >
    <div style="display: flex; gap: 16px">
      <ChipSelectable
        :chips="timePeriodOptions"
        v-model="selectedTimePeriodModel"
      ></ChipSelectable>
    </div>
    <DatePicker
      v-if="dialogModel"
      v-model:dialog="dialogModel"
      :dates="datePickerModel"
      @cancel="cancelDatePicker"
      @submit="submitDatePicker"
      range
    />
  </ButtonFilter>
</template>

<script setup lang="ts">
import ButtonFilter from "@/components/shared/ButtonFilter/ButtonFilter.vue";
import ChipSelectable from "@/components/shared/ChipSelectable/ChipSelectable.vue";
import DatePicker from "@/components/shared/DatePicker/DatePicker.vue";

import { useButtonFilterDate } from "./useButtonFilterDate";
import { useTransactionStore } from "@/store/TransactionStore";

const { $state } = useTransactionStore();
const {
  cancelDatePicker,
  clearAllFilter,
  datePickerModel,
  dialogModel,
  selectedTimePeriodMemory,
  selectedTimePeriodModel,
  submitButtonFilter,
  submitDatePicker,
  timePeriodOptions,
  handleCloseFilter,
} = useButtonFilterDate();
</script>
