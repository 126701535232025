import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "d-flex" }
const _hoisted_2 = { class: "d-flex flex-row ga-2" }
const _hoisted_3 = {
  key: 2,
  class: "d-flex justify-end align-center ga-2"
}
const _hoisted_4 = {
  key: 0,
  class: "list mobile"
}
const _hoisted_5 = {
  key: 1,
  class: "list"
}
const _hoisted_6 = { style: {"display":"flex","justify-content":"center","align-items":"center","padding":"8px 0px"} }
const _hoisted_7 = ["src", "alt"]

import { useDisplay } from "vuetify";
import { Ref, onMounted, reactive, ref, watch } from "vue";
import gsap from "gsap";

import ListDesktopTransactions from "@/components/transactions/ListDesktopTransactions.vue";
import ListMobileTransactions from "@/components/transactions/ListMobileTransactions.vue";
import SummaryCardTransaction from "@/components/transactions/SummaryCardTransaction.vue";
import ExportButton from "@/components/shared/ExportButton/ExportButton.vue";
import AddButton from "@/components/shared/AddButton/AddButton.vue";
import ActionBar from "@/components/shared/ActionBar/ActionBar.vue";
import ContainerFixedTop from "@/components/shared/ContainerFixedTop/ContainerFixedTop.vue";
import ContainerSwiper from "@/components/shared/ContainerSwiper/ContainerSwiper.vue";

import ButtonFilterContainer from "@/components/shared/ButtonFilterContainer/ButtonFilterContainer.vue";
//desktop
import ButtonFilterDate from "@/components/shared/ButtonFilterDate/ButtonFilterDate.vue";
import ButtonFilterValue from "@/components/shared/ButtonFilterValue/ButtonFilterValue.vue";
import ButtonFilterStatus from "@/components/shared/ButtonFilterStatus/ButtonFilterStatus.vue";
import ButtonFilterID from "@/components/transactions/ButtonFilterID/ButtonFilterID.vue";
import ButtonFilterStore from "@/components/transactions/ButtonFilterStore/ButtonFilterStore.vue";
//mobile
import ButtonFilterDateMobile from "@/components/shared/ButtonFilterDateMobile/ButtonFilterDateMobile.vue";
import ButtonFilterValueMobile from "@/components/shared/ButtonFilterValueMobile/ButtonFilterValueMobile.vue";
import ButtonFilterStatusMobile from "@/components/shared/ButtonFilterStatusMobile/ButtonFilterStatusMobile.vue";
import ButtonFilterIDMobile from "@/components/transactions/ButtonFilterIDMobile/ButtonFilterIDMobile.vue";
import ButtonFilterStoreMobile from "@/components/transactions/ButtonFilterStoreMobile/ButtonFilterStoreMobile.vue";

import ListDesktopTransactionsSkeleton from "@/components/transactions/ListDesktopTransactionsSkeleton.vue";
import ListMobileTransactionsSkeleton from "@/components/transactions/ListMobileTransactionsSkeleton.vue";

import { useTransactionStore } from "@/store/TransactionStore";
import {
  getTotalTransactionStatusAPI,
  getTransactionAPIPOST,
  ITransaction,
} from "@/api/resources/Transactions/transactionResource";
import imgClearsale from "@/assets/logo_mobile.png";
import { useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'TransactionView',
  setup(__props) {

const router = useRouter();
const transactionStore = useTransactionStore();
const { mdAndUp } = useDisplay();
const topControllIsActive = ref(false);
const hiddenButtonsOnScrollTop = ref(true);
const transactionSelected: Ref<ITransaction[]> = ref([]);

const finished = ref(false);
const loading = ref(false);

const transactionListRef = ref<HTMLElement | null>(null);
const actionBarRef = ref<HTMLElement | null>(null);
const animatedNumber = reactive({
  height: 0,
  opacity: 0,
});

const imgClearsaleOptions = ref({
  src: imgClearsale,
  alt: "Logo Clear Sale",
});

function handleTransactionSelected(transaction: ITransaction) {
  const index = transactionSelected.value.findIndex(
    (o) => o.id === transaction.id
  );
  const removeOneTransaction = 1;
  const transactionIsSelected = index !== -1;

  if (transactionIsSelected) {
    transactionSelected.value.splice(index, removeOneTransaction);
    return;
  }
  transactionSelected.value.push(transaction);
}

async function handleRouteDetails(transaction: ITransaction) {
  await router.push({
    name: "transactionDetailView",
    params: { transactionId: transaction.id },
  });
}

function scrollInfinityFetchAPI() {
  if (!finished.value)
    transactionStore.$patch({
      page: transactionStore.$state.page + 1,
    });
}

watch(
  () => transactionStore.$state.filters,
  async () => {
    loading.value = true;
    getTransactionAPIPOST({
      ...transactionStore.$state.filters,
      page: transactionStore.$state.page,
      offset: transactionStore.$state.offset,
    })
      .then((data) => {
        finished.value =
          data.result.length < transactionStore.$state.offset ||
          data.result.length === 0;

        transactionStore.updateTransactionList(data.result);
      })
      .finally(() => {
        loading.value = false;
      });
  },
  {
    deep: true,
  }
);

watch(
  () => transactionStore.$state.page,
  async () => {
    loading.value = true;
    getTransactionAPIPOST({
      ...transactionStore.$state.filters,
      page: transactionStore.$state.page,
      offset: transactionStore.$state.offset,
    })
      .then((data) => {
        if (finished.value) return;

        const copyTransactionData = structuredClone(
          transactionStore.transactionList
        );

        copyTransactionData.push(...data.result);
        transactionStore.updateTransactionList(copyTransactionData);

        finished.value =
          data.result.length < transactionStore.$state.offset ||
          data.result.length === 0;
      })
      .finally(() => {
        loading.value = false;
      });
  },
  {
    deep: true,
  }
);

onMounted(() => {
  getTransactionAPIPOST({
    ...transactionStore.$state.filters,
    page: transactionStore.$state.page,
    offset: transactionStore.$state.offset,
  })
    .then((data) => {
      transactionStore.updateTransactionList(data.result);
    })
    .finally(() => {
      loading.value = false;
    });

  getTotalTransactionStatusAPI().then((data) => {
    transactionStore.updateSummaryTransaction(data.valueTransactionStatus);
  });
});

watch(
  transactionSelected,
  (value) => {
    if (value.length === 0) {
      gsap.to(animatedNumber, {
        duration: 0.5,
        opacity: 0,
        ease: "power1.inOut",
      });

      gsap.to(actionBarRef.value, {
        duration: 0.5,
        opacity: 0,
        height: 0,
        ease: "power1.out",
      });
      animatedNumber.height = 0;
      return;
    }

    gsap.to(actionBarRef.value, {
      duration: 0.5,
      opacity: 1,
      height: 48,
      ease: "power1.out",
      onStart: () => {
        setTimeout(() => {
          animatedNumber.height = 40;
        }, 100);
      },
    });

    gsap.to(animatedNumber, {
      duration: 0.5,
      opacity: 1,
      ease: "power1.inOut",
    });
  },
  {
    deep: true,
  }
);

watch(topControllIsActive, (value) => {
  hiddenButtonsOnScrollTop.value = mdAndUp.value || !value;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(SummaryCardTransaction),
    _createElementVNode("div", {
      id: "transactions-page",
      class: _normalizeClass({ mobile: _unref(mdAndUp) }),
      style: _normalizeStyle([{"margin-top":"16px"}, { padding: `${_unref(mdAndUp) ? '8px 40px' : '8px 16px'}` }])
    }, [
      _createVNode(ContainerFixedTop, {
        style: {"z-index":"9"},
        onHandleTop: _cache[0] || (_cache[0] = 
        (isTop) => {
          topControllIsActive.value = isTop;
        }
      )
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_unref(mdAndUp))
              ? (_openBlock(), _createBlock(ButtonFilterContainer, { key: 0 }, {
                  default: _withCtx(() => [
                    _createVNode(ButtonFilterID),
                    _createVNode(ButtonFilterDate),
                    _createVNode(ButtonFilterStore),
                    _createVNode(ButtonFilterStatus),
                    _createVNode(ButtonFilterValue)
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createBlock(ContainerSwiper, { key: 1 }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _createVNode(ButtonFilterIDMobile),
                      _createVNode(ButtonFilterDateMobile),
                      _createVNode(ButtonFilterStoreMobile),
                      _createVNode(ButtonFilterStatusMobile),
                      _createVNode(ButtonFilterValueMobile)
                    ])
                  ]),
                  _: 1
                })),
            (hiddenButtonsOnScrollTop.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(AddButton),
                  _createVNode(ExportButton)
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", {
            style: _normalizeStyle([{"position":"relative"}, { opacity: animatedNumber.opacity }]),
            ref_key: "actionBarRef",
            ref: actionBarRef
          }, [
            _createVNode(ActionBar, {
              style: {"position":"absolute"},
              height: +animatedNumber.height.toFixed(0),
              lenght: transactionSelected.value.length
            }, null, 8, ["height", "lenght"])
          ], 4)
        ]),
        _: 1
      }),
      _createElementVNode("div", {
        ref_key: "transactionListRef",
        ref: transactionListRef,
        style: {"margin-top":"24px","flex":"1"}
      }, [
        (!_unref(mdAndUp))
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (loading.value || _unref(transactionStore).transactionList.length < 1)
                ? (_openBlock(), _createBlock(ListMobileTransactionsSkeleton, { key: 0 }))
                : _createCommentVNode("", true),
              _createVNode(ListMobileTransactions, {
                data: _unref(transactionStore).transactionList,
                onFetchData: scrollInfinityFetchAPI,
                onIconClick: handleTransactionSelected,
                onContainerClick: handleRouteDetails
              }, null, 8, ["data"])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_5, [
              (loading.value || _unref(transactionStore).transactionList.length < 1)
                ? (_openBlock(), _createBlock(ListDesktopTransactionsSkeleton, {
                    key: 0,
                    lines: 20
                  }))
                : _createCommentVNode("", true),
              _createVNode(ListDesktopTransactions, {
                data: _unref(transactionStore).transactionList,
                onFetchData: scrollInfinityFetchAPI,
                onIconClick: handleTransactionSelected,
                onContainerClick: handleRouteDetails
              }, null, 8, ["data"])
            ]))
      ], 512)
    ], 6),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("img", {
        style: {"width":"24px"},
        src: imgClearsaleOptions.value.src,
        alt: imgClearsaleOptions.value.alt
      }, null, 8, _hoisted_7)
    ])
  ], 64))
}
}

})