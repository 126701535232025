import { CardType, IPaymentType, ITransaction, ITransactionStatus } from "@/api/resources/Transactions/transactionResource";
import { label } from "@/plugins/locales/global";

export const transactionsDB: ITransaction[] = [
  {
    id: "1",
    code: "1",
    consumerRequestedAt: "2024-07-18T15:24:00",
    entityName: "Store name",
    status: ITransactionStatus.Approved,
    chargebackStatus: ITransactionStatus.Canceled,
    totalAmount: 1000,
  },
  {
    id: "123",
    code: "112",
    clearSaleDate: "2024-07-17T15:24:00",
    consumerRequestedAt: "2024-07-17T15:24:00",
    entityName: "Store name",
    status: ITransactionStatus.Approved,
    chargebackStatus: ITransactionStatus.Denied,
    totalAmount: 1000,
  },
  {
    id: "111",
    code: "1345",
    consumerRequestedAt: "2024-07-11T15:24:00",
    entityName: "",
    status: ITransactionStatus.Approved,
    totalAmount: 1000,
  },
  {
    id: "11221",
    code: "13245",
    consumerRequestedAt: "2024-06-18T15:24:00",
    entityName: "",
    status: ITransactionStatus.Approved,
    totalAmount: 1000,
  },
  {
    id: "121",
    code: "1245",
    consumerRequestedAt: "2024-04-18T15:24:00",
    entityName: "",
    status: ITransactionStatus.Approved,
    totalAmount: 1000,
  },
  {
    id: "12144",
    code: "124544",
    consumerRequestedAt: "2024-01-18T15:24:00",
    entityName: "",
    status: ITransactionStatus.Approved,
    totalAmount: 1000,
  },
  {
    id: "212144",
    code: "2124544",
    consumerRequestedAt: "2023-07-18T15:24:00",
    entityName: "",
    status: ITransactionStatus.Approved,
    totalAmount: 1000,
  },
  {
    id: "2",
    code: "2",
    consumerRequestedAt: "2021-01-02T15:24:00",
    entityName: "",
    status: ITransactionStatus.Denied,
    totalAmount: 2000,
  },
  {
    id: "3",
    code: "3",
    consumerRequestedAt: "2021-01-03T15:24:00",
    entityName: "",
    status: ITransactionStatus.Analyzing,
    totalAmount: 3000,
  },
  {
    id: "4",
    code: "4",
    consumerRequestedAt: "2021-01-04T15:24:00",
    entityName: "",
    status: ITransactionStatus.Canceled,
    totalAmount: 4000,
  },
  {
    id: "5",
    code: "5",
    consumerRequestedAt: "2021-01-05T15:24:00",
    entityName: "",
    status: ITransactionStatus.Approved,
    totalAmount: 5000,
  },
  {
    id: "6",
    code: "6",
    consumerRequestedAt: "2021-01-06T15:24:00",
    entityName: "",
    status: ITransactionStatus.Denied,
    totalAmount: 6000,
  },
  {
    id: "7",
    code: "7",
    consumerRequestedAt: "2021-01-07T15:24:00",
    entityName: "",
    status: ITransactionStatus.Analyzing,
    totalAmount: 7000,
  },
  {
    id: "8",
    code: "8",
    consumerRequestedAt: "2021-01-08T15:24:00",
    entityName: "",
    status: ITransactionStatus.Canceled,
    totalAmount: 8000,
  },
  {
    id: "9",
    code: "9",
    consumerRequestedAt: "2021-01-09T15:24:00",
    entityName: "",
    status: ITransactionStatus.Approved,
    totalAmount: 9000,
  },
  {
    id: "10",
    code: "10",
    consumerRequestedAt: "2021-01-10T15:24:00",
    entityName: "",
    status: ITransactionStatus.Denied,
    totalAmount: 10000,
  },
  {
    id: "11",
    code: "11",
    consumerRequestedAt: "2021-01-11T15:24:00",
    entityName: "",
    status: ITransactionStatus.Analyzing,
    totalAmount: 11000,
  },
  {
    id: "12",
    code: "12",
    consumerRequestedAt: "2021-01-12T15:24:00",
    entityName: "",
    status: ITransactionStatus.Canceled,
    totalAmount: 12000,
  },
  {
    id: "13",
    code: "13",
    consumerRequestedAt: "2021-01-13T15:24:00",
    entityName: "",
    status: ITransactionStatus.Approved,
    totalAmount: 13000,
  },
  {
    id: "14",
    code: "14",
    consumerRequestedAt: "2021-01-14T15:24:00",
    entityName: "",
    status: ITransactionStatus.Denied,
    totalAmount: 14000,
  },
  {
    id: "15",
    code: "15",
    consumerRequestedAt: "2021-01-15T15:24:00",
    entityName: "",
    status: ITransactionStatus.Analyzing,
    totalAmount: 15000,
  },
  {
    id: "16",
    code: "16",
    consumerRequestedAt: "2021-01-16T15:24:00",
    entityName: "",
    status: ITransactionStatus.Canceled,
    totalAmount: 16000,
  },
  {
    id: "17",
    code: "17",
    consumerRequestedAt: "2021-01-17T15:24:00",
    entityName: "",
    status: ITransactionStatus.Approved,
    totalAmount: 17000,
  },
  {
    id: "18",
    code: "18",
    consumerRequestedAt: "2021-01-18T15:24:00",
    entityName: "",
    status: ITransactionStatus.Denied,
    totalAmount: 18000,
  },
  {
    id: "19",
    code: "19",
    consumerRequestedAt: "2021-01-19T15:24:00",
    entityName: "",
    status: ITransactionStatus.Analyzing,
    totalAmount: 19000,
  },
  {
    id: "20",
    code: "20",
    consumerRequestedAt: "2021-01-20T15:24:00",
    entityName: "",
    status: ITransactionStatus.Canceled,
    totalAmount: 20000,
  },
  {
    id: "21",
    code: "21",
    consumerRequestedAt: "2021-01-21T15:24:00",
    entityName: "",
    status: ITransactionStatus.Approved,
    totalAmount: 21000,
  },
  {
    id: "22",
    code: "22",
    consumerRequestedAt: "2021-01-22T15:24:00",
    entityName: "",
    status: ITransactionStatus.Denied,
    totalAmount: 22000,
  },
  {
    id: "23",
    code: "23",
    consumerRequestedAt: "2021-01-23T15:24:00",
    entityName: "",
    status: ITransactionStatus.Analyzing,
    totalAmount: 23000,
  },
  {
    id: "24",
    code: "24",
    consumerRequestedAt: "2021-01-24T15:24:00",
    entityName: "",
    status: ITransactionStatus.Canceled,
    totalAmount: 24000,
  },
  {
    id: "25",
    code: "25",
    consumerRequestedAt: "2021-01-25T15:24:00",
    entityName: "",
    status: ITransactionStatus.Approved,
    totalAmount: 25000,
  },
  {
    id: "26",
    code: "26",
    consumerRequestedAt: "2021-01-26T15:24:00",
    entityName: "",
    status: ITransactionStatus.Denied,
    totalAmount: 26000,
  },
  {
    id: "27",
    code: "27",
    consumerRequestedAt: "2021-01-27T15:24:00",
    entityName: "",
    status: ITransactionStatus.Analyzing,
    totalAmount: 27000,
  },
  {
    id: "28",
    code: "28",
    consumerRequestedAt: "2021-01-28T15:24:00",
    entityName: "",
    status: ITransactionStatus.Canceled,
    totalAmount: 28000,
  },
  {
    id: "29",
    code: "29",
    consumerRequestedAt: "2021-01-29T15:24:00",
    entityName: "",
    status: ITransactionStatus.Approved,
    totalAmount: 29000,
  },
  {
    id: "30",
    code: "30",
    consumerRequestedAt: "2021-01-30T15:24:00",
    entityName: "",
    status: ITransactionStatus.Denied,
    totalAmount: 30000,
  },
  {
    id: "31",
    code: "31",
    consumerRequestedAt: "2021-01-31T15:24:00",
    entityName: "",
    status: ITransactionStatus.Analyzing,
    totalAmount: 31000,
  },
  {
    id: "32",
    code: "32",
    consumerRequestedAt: "2021-02-01T15:24:00",
    entityName: "",
    status: ITransactionStatus.Canceled,
    totalAmount: 32000,
  },
  {
    id: "33",
    code: "33",
    consumerRequestedAt: "2021-02-02T15:24:00",
    entityName: "",
    status: ITransactionStatus.Approved,
    totalAmount: 33000,
  },
  {
    id: "34",
    code: "34",
    consumerRequestedAt: "2021-02-03T15:24:00",
    entityName: "",
    status: ITransactionStatus.Denied,
    totalAmount: 34000,
  },
  {
    id: "35",
    code: "35",
    consumerRequestedAt: "2021-02-04T15:24:00",
    entityName: "",
    status: ITransactionStatus.Analyzing,
    totalAmount: 35000,
  },
]

export const suggestionTransactionStoreDB = transactionsDB
  .map((transaction) => ({ id: transaction.id, value: transaction.entityName, subValue: '' }));

export const suggestionTransactionIDsDB = transactionsDB
  .map((transaction) => ({ id: transaction.id, value: transaction.code, subValue: transaction.entityName }));

export const transactionDetailsDB = transactionsDB.map((transaction, index) => ({
  summary: {
    ...transaction,
    chargebackStatus: index % 2 == 0 ? transaction.status : null,
    entityDate: Date.now(),
    clearSaleDate: Date.now(),
    currency: transaction.totalAmount,
    email: 'email@clear.sale'
  },
  contacts: {
    billing: {
      phone: "+55 13 91234-4321",
      name: "Jorge da Silva dos Santos",
      addressLine1: "Av. Marcos Penteado de Ulhôa Rodrigues, 939",
      addressLine2: "Torre Jacarandá, Conjuntos 301 e 302, Bairro Alphaville",
      country: "Brazil",
      city: "Barueri",
      zipCode: "06460-040",
    },
    shipping: {
      phone: "+55 13 91234-4321",
      name: "Jorge da Silva dos Santos",
      addressLine1: "Av. Marcos Penteado de Ulhôa Rodrigues, 939",
      addressLine2: "Torre Jacarandá, Conjuntos 301 e 302, Bairro Alphaville",
      country: "Brazil",
      city: "Barueri",
      zipCode: "06460-040",
    }
  },
  history: [{
    label: label.TransactionDetailsPage.DetailTransactionTabs.History.ChargebackApproved,
    classLabel: 'success',
    date: transaction.consumerRequestedAt
  },
  {
    label: label.TransactionDetailsPage.DetailTransactionTabs.History.TransactionDenied,
    classLabel: 'error',
    date: transaction.consumerRequestedAt
  },
  {
    label: label.TransactionDetailsPage.DetailTransactionTabs.History.Risks,
    classLabel: 'warning',
    date: transaction.consumerRequestedAt
  },
  {
    label: label.TransactionDetailsPage.DetailTransactionTabs.History.Analysis,
    classLabel: 'info',
    date: transaction.consumerRequestedAt
  }],
  items: [
    {
      id: '1',
      name: 'IPhone X Max',
      quantity: 4,
      price: 1000
    },
    {
      id: '2',
      name: 'Playstation 5 Pro',
      quantity: 1,
      price: 699
    },
    {
      id: '3',
      name: 'Tesla Pro',
      quantity: 2,
      price: 123456789.9876543
    },
  ],
  device: {
    id: '1',
    label: 'IPhone X Pro',
    ipAddress: ''
  },
  payments: [
    {
      id: '1',
      type: IPaymentType.CreditCard,
      name: 'John Doe',
      number: '1234*********5678',
      cardType: CardType.Carrefour,
      amount: 123,
      currency: 123,
    }
  ]
}))
