import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock } from "vue"

import { useClassByTheme } from "@/components/hooks/useClassByTheme";
import { useStyleByTheme } from "@/components/hooks/useStyleByTheme";
import { TabsSwitchItem } from "@/model/tabSwitch";
import { DetailTransactionTabs } from "@/model/transaction";
import { computed } from "vue";
import { useI18n } from "vue-i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'TabsSwitch',
  props: {
    options: {}
  },
  emits: ["selected"],
  setup(__props: any, { emit: __emit }) {

const containerClass = {
  light: "bg-surface-container",
  dark: "bg-surface-container-high",
};

const buttonClass = {
  light: "text-secondary",
  dark: "on-surface-variant",
};

const buttonActiveClass = {
  light: { backgroundColor: "var(--bg-ref-palette-neutral92)" },
  dark: {
    backgroundColor: "rgba(33, 31, 38, 1)",
    color: "rgba(var(--v-theme-ref-palette-secondary80)) !important",
  },
};

const { t } = useI18n();

const computedContainerClass = useClassByTheme(containerClass);
const computedButtonClass = useClassByTheme(buttonClass);

const computedButtonActiveStyle = useStyleByTheme(buttonActiveClass);



const emits =
  __emit;

return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      _unref(computedContainerClass),
      'd-flex',
      'align-center',
      'flex-1-1',
      'rounded-xl',
    ])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, ({ id, label, active }, i) => {
      return (_openBlock(), _createBlock(_component_v_btn, {
        key: i,
        class: _normalizeClass([
        _unref(computedButtonClass),
        'flex-1-1',
        'h-100',
        'text-capitalize',
        'pa-0',
      ]),
        style: _normalizeStyle(active ? _unref(computedButtonActiveStyle) : undefined),
        variant: "text",
        color: "",
        onClick: () => emits('selected', id),
        active: active
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_unref(t)(label)), 1)
        ]),
        _: 2
      }, 1032, ["class", "style", "onClick", "active"]))
    }), 128))
  ], 2))
}
}

})