import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  class: "position-fixed",
  style: {"bottom":"24px","right":"15px","z-index":"1000"}
}
const _hoisted_2 = ["id", "data-testid"]

import { onMounted, reactive, ref, watch } from "vue";
import { useTheme } from "vuetify";
import { generateTestId } from "@/helpers/generateTestId";
import { ActionItem, ActionTransactionType } from "@/model/actionsDetails";
import gsap from "@/plugins/gsap";

import {
  ActionDetailsMobileMainButton,
  ActionDetailsMobileOptionButtonContainer,
  ActionDetailsMobileOptionButtonLeft,
  ActionDetailsMobileOptionButtonRight,
} from "./constants/ActionDetailsMobileIds";
import { useStyleByTheme } from "@/components/hooks/useStyleByTheme";
import { useI18n } from "vue-i18n";
import { label } from "@/plugins/locales/global";

const ACTION_BUTTON_HEIGHT = 80; //actual button height + bottom from position-fixed;


export default /*@__PURE__*/_defineComponent({
  __name: 'ActionDetailsMobile',
  props: {
    options: {}
  },
  emits: ["click"],
  setup(__props: any, { emit: __emit }) {

const { t } = useI18n();
const props = __props;
const emits = __emit;

const { name: themeName } = useTheme();

const refOverlay = ref<HTMLElement | undefined>(undefined);
const actionDetailsButtons: Element[] = reactive([]);
const toggle = ref(false);

const overlayStyleAccordingTheme = useStyleByTheme({
  light: { backgroundColor: "rgba(var(--v-ref-palette-neutral10), 0.5)" },
  dark: { backgroundColor: "rgba(0, 0, 0, 0.4)" },
});

const buttonStyle = {
  light: {
    backgroundColor: "rgb(var(--v-ref-palette-information95))",
    color: "rgb(var(--v-ref-palette-tertiary40))",
  },
  dark: {
    backgroundColor: "rgb(var(--v-ref-palette-information30))",
    color: "rgb(var(--v-ref-palette-tertiary80))",
  },
};

const buttonStyleAccordingTheme = useStyleByTheme(buttonStyle);

watch(
  () => props.options,
  (newOptions, oldOptions) => {
    actionDetailsButtons.splice(0, actionDetailsButtons.length);
    actionDetailsButtons.push(
      ...gsap.utils.toArray<Element>(
        `#${ActionDetailsMobileOptionButtonContainer}`
      )
    );
  }
);

onMounted(() => {
  actionDetailsButtons.push(
    ...gsap.utils.toArray<Element>(
      `#${ActionDetailsMobileOptionButtonContainer}`
    )
  );

  gsap.set(actionDetailsButtons, { autoAlpha: 0 });
});

function emitAndRipple(id: number) {
  emits("click", id);
  animate();
}

function animate() {
  toggle.value = !toggle.value;

  gsap.to(actionDetailsButtons, {
    bottom: (i) => (toggle.value ? 50 * i + ACTION_BUTTON_HEIGHT : 0),
    ease: "back.out(1)",
    autoAlpha: +toggle.value,
  });
}

return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createElementVNode("div", {
        ref_key: "refOverlay",
        ref: refOverlay,
        class: "position-fixed",
        style: _normalizeStyle([{"top":"0","bottom":"0","left":"0","right":"0","height":"100%","width":"100%","overflow":"hidden","z-index":"999"}, {
        ..._unref(overlayStyleAccordingTheme),
        display: toggle.value ? 'initial' : 'none',
      }]),
        onClick: animate
      }, null, 4)
    ])),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, i) => {
        return (_openBlock(), _createElementBlock("div", {
          key: i,
          id: _unref(ActionDetailsMobileOptionButtonContainer),
          class: "d-flex flex-row ga-2 align-center position-fixed",
          style: {"right":"15px","margin-bottom":"8px"},
          "data-testid": 
        _unref(generateTestId)(
          `${_unref(ActionDetailsMobileOptionButtonContainer)}-${option.id}`
        )
      
        }, [
          _createVNode(_component_v_btn, {
            rounded: "xl",
            elevation: "1",
            style: _normalizeStyle([{"text-transform":"none"}, _unref(buttonStyleAccordingTheme)]),
            color: "",
            "data-testid": _unref(generateTestId)(_unref(ActionDetailsMobileOptionButtonLeft)),
            onClick: ($event: any) => (emitAndRipple(option.id))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_unref(t)(`${_unref(label).Shared.TransactionActions._}.${_unref(ActionTransactionType)[option.id]}`)), 1)
            ]),
            _: 2
          }, 1032, ["style", "data-testid", "onClick"]),
          _createVNode(_component_v_btn, {
            rounded: "lg",
            size: "40px",
            class: "pa-0",
            color: "",
            style: _normalizeStyle(_unref(buttonStyleAccordingTheme)),
            "data-testid": _unref(generateTestId)(_unref(ActionDetailsMobileOptionButtonRight)),
            onClick: ($event: any) => (emitAndRipple(option.id))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, { size: "24px" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(option.icon), 1)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1032, ["style", "data-testid", "onClick"])
        ], 8, _hoisted_2))
      }), 128)),
      _createVNode(_component_v_btn, {
        "data-testid": _unref(generateTestId)(_unref(ActionDetailsMobileMainButton)),
        "prepend-icon": toggle.value ? 'mdi-close' : 'mdi-table-of-contents',
        elevation: 1,
        color: "",
        style: _normalizeStyle([{"text-transform":"none","height":"56px"}, _unref(buttonStyleAccordingTheme)]),
        onClick: animate
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_unref(t)(_unref(label).TransactionDetailsPage.Actions)), 1)
        ]),
        _: 1
      }, 8, ["data-testid", "prepend-icon", "style"])
    ])
  ], 64))
}
}

})