import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "d-flex flex-column ga-2" }

import { ActionItem, ActionTransactionType } from "@/model/actionsDetails";
import { onMounted, ref } from "vue";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useI18n } from "vue-i18n";
import { tokens } from "@clearsale/tokens-ds";
import { label } from "@/plugins/locales/global";

const pinnedContainerClass = "pinned-container";

const slotContainerClass = "slot-container";


export default /*@__PURE__*/_defineComponent({
  __name: 'ActionDetailsDesktop',
  props: {
    options: {}
  },
  emits: ["click"],
  setup(__props: any, { emit: __emit }) {

const { t } = useI18n();

const emits = __emit;

onMounted(() => {
  const t = setTimeout(() => {
    attachScrollAnimation();
    clearTimeout(t);
  }, 300);
});

const refPinnedContainer = ref<HTMLDivElement | null>(null);
const refSlotContainer = ref<HTMLDivElement | null>(null);
const fontSize = tokens.typography["typescale-title-large-size"];

function attachScrollAnimation() {
  if (refPinnedContainer.value && refSlotContainer.value) {
    ScrollTrigger.create({
      start: "top-=100px top",
      end: () => document.body.scrollHeight,
      markers: false,
      pin: true,
      pinSpacing: false,
      trigger: refPinnedContainer.value,
    });
  }
}

return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("div", {
    ref_key: "refPinnedContainer",
    ref: refPinnedContainer,
    class: _normalizeClass(pinnedContainerClass)
  }, [
    _createElementVNode("div", {
      ref_key: "refSlotContainer",
      ref: refSlotContainer,
      class: _normalizeClass([slotContainerClass, "d-flex flex-column justify-end bg-transparent"])
    }, [
      _createElementVNode("h2", {
        class: "font-weight-regular on-tertiary-container pl-3",
        style: _normalizeStyle(`font-size: ${_unref(fontSize)}`)
      }, _toDisplayString(_unref(t)(_unref(label).TransactionDetailsPage.Actions)), 5),
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, i) => {
          return (_openBlock(), _createBlock(_component_v_btn, {
            key: i,
            density: "comfortable",
            class: "justify-start",
            "prepend-icon": option.icon,
            style: {"text-transform":"none"},
            color: "tertiary",
            elevation: "0",
            variant: "text",
            onClick: _withModifiers(() => emits('click', option.id), ["stop"])
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_unref(t)(`${_unref(label).Shared.TransactionActions._}.${_unref(ActionTransactionType)[option.id]}`)), 1)
            ]),
            _: 2
          }, 1032, ["prepend-icon", "onClick"]))
        }), 128))
      ])
    ], 512)
  ], 512))
}
}

})