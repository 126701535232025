import { ITransactionStatus } from "@/api/resources/Transactions/transactionResource";

export const transactionStatusIcon = {
  [ITransactionStatus.Approved]: "mdi-check",
  [ITransactionStatus.Denied]: "mdi-close",
  [ITransactionStatus.Analyzing]: "mdi-arrow-right",
  [ITransactionStatus.Canceled]: "mdi-square",
  [ITransactionStatus.None]: "",
}

export enum DetailTransactionTabs {
  None = 0,
  Summary = 1,
  History = 2,
  Items = 3,
  Payment = 4,
  Contacts = 5
}
