import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "on-surface-variant" }
const _hoisted_2 = { class: "elevation-1 rounded-xl pa-6" }
const _hoisted_3 = { class: "d-flex justify-space-between" }
const _hoisted_4 = { class: "on-surface-variant" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "card elevation-1 rounded-xl pa-6" }
const _hoisted_7 = { class: "d-flex justify-space-between" }
const _hoisted_8 = { class: "d-flex justify-space-between" }
const _hoisted_9 = { class: "d-flex justify-space-between" }

import { IPaymentType } from "@/api/resources/Transactions/transactionResource";
import {
  TransactionPaymentProps,
  useTransactionPayments,
} from "./useTransactionPayments";
import { useDisplay } from "vuetify";
import { computed } from "vue";
import { useCardColors } from "@/components/hooks/useCardColors";
import { useI18n } from "vue-i18n";
import { label } from "@/plugins/locales/global";


export default /*@__PURE__*/_defineComponent({
  __name: 'TransactionPayments',
  props: {
    device: {},
    payments: {}
  },
  setup(__props: any) {

const props = __props;
const { computedPaymentsMap } = useTransactionPayments(props.payments ?? []);
const { titleComputedClass, contentComputedClass } = useCardColors();

const { t } = useI18n();
const display = useDisplay();
const displaySizeClass = computed(() => (display.mdAndUp.value ? "md" : "sm"));

return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['content', displaySizeClass.value])
  }, [
    (_ctx.device)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("h2", _hoisted_1, _toDisplayString(_unref(t)(
            _unref(label).TransactionDetailsPage.DetailTransactionTabs.Payment.Device
              .title
          )), 1),
          _createElementVNode("section", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("span", {
                class: _normalizeClass(_unref(titleComputedClass))
              }, _toDisplayString(_unref(t)(
              _unref(label).TransactionDetailsPage.DetailTransactionTabs.Payment.Device
                .IPAddress
            )), 3),
              _createElementVNode("span", {
                class: _normalizeClass(_unref(contentComputedClass))
              }, _toDisplayString(_ctx.device.ipAddress), 3)
            ])
          ])
        ], 64))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(computedPaymentsMap), ([, payments], i) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(payments, (payment, j) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: payment.id
          }, [
            _createElementVNode("h2", _hoisted_4, [
              _createTextVNode(_toDisplayString(_unref(IPaymentType)[payment.type]) + " ", 1),
              ((payments?.length ?? 0) > 1)
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, " #" + _toDisplayString(j + 1), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("section", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("span", {
                  class: _normalizeClass(_unref(titleComputedClass))
                }, _toDisplayString(_unref(t)(
                _unref(label).TransactionDetailsPage.DetailTransactionTabs.Payment
                  .PaymentType.Name
              )), 3),
                _createElementVNode("span", {
                  class: _normalizeClass(_unref(contentComputedClass))
                }, _toDisplayString(payment.name), 3)
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("span", {
                  class: _normalizeClass(_unref(titleComputedClass))
                }, _toDisplayString(_unref(t)(
                _unref(label).TransactionDetailsPage.DetailTransactionTabs.Payment
                  .PaymentType.Number
              )), 3),
                _createElementVNode("span", {
                  class: _normalizeClass(_unref(contentComputedClass))
                }, [
                  (payment.type == _unref(IPaymentType).CartaoDebito)
                    ? (_openBlock(), _createBlock(_component_v_icon, {
                        key: 0,
                        icon: "mdi-exit-to-app"
                      }))
                    : _createCommentVNode("", true),
                  (payment.type == _unref(IPaymentType).CreditCard)
                    ? (_openBlock(), _createBlock(_component_v_icon, {
                        key: 1,
                        icon: "mdi-credit-card-outline"
                      }))
                    : _createCommentVNode("", true),
                  _createTextVNode(" " + _toDisplayString(payment.number), 1)
                ], 2)
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("span", {
                  class: _normalizeClass(_unref(titleComputedClass))
                }, _toDisplayString(_unref(t)(
                _unref(label).TransactionDetailsPage.DetailTransactionTabs.Payment
                  .PaymentType.Amount
              )), 3),
                _createElementVNode("span", {
                  class: _normalizeClass(_unref(contentComputedClass))
                }, _toDisplayString(payment.amount.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })), 3)
              ])
            ])
          ], 64))
        }), 128))
      ], 64))
    }), 128))
  ], 2))
}
}

})