import { Ref, computed, inject, onMounted, ref, toValue, watch } from "vue";
import { ButtonFilterContainerAnimationContext } from "../../ButtonFilterContainer/constants/ButtonFilterContainer";
import gsap, { GsapTimeline } from "@/plugins/gsap";
import * as btnClasses from "../constants/ButtonFilterClasses";
import { ICON_SIZE } from "../constants/ButtonFilterValues";
import { Flip } from "gsap/Flip";

export type ButtonFilterAnimationContext = {
  onOpenFilter: () => GsapTimeline;
  onOpenSlot: () => GsapTimeline;
  onCloseFilter: () => GsapTimeline;
  onCloseSlot: () => GsapTimeline;
};

export type ButtonFilterEmits = {
  (e: "submitFilter", submitFilterFn: () => void): void;
  (e: "closeFilter"): void;
  (e: "clearFilter"): void;
  (e: "openFilter"): void;
};

export type ButtonFilterProps = {
  label?: string;
  open?: boolean;
  active?: boolean;
};

export function useButtonFilter(
  refButtonFilter: Ref<Element>,
  emits: ButtonFilterEmits,
  props: ButtonFilterProps
) {
  const timeline = gsap.timeline({ defaults: { duration: 0.3 } });

  const btnInitialized = ref(true);
  const btnOpen = ref(false);
  const btnActive = ref(false);

  const btnHidden = {
    opacity: 0,
    visibility: "hidden",
    width: 0,
  };

  const buttonFilterContainerCtx = inject<
    Ref<ButtonFilterContainerAnimationContext>
  >(
    "button-filter__container--context"
  ) as Ref<ButtonFilterContainerAnimationContext>;
  let buttonContext: ButtonFilterAnimationContext;

  onMounted(() => {
    if (props.open) btnOpen.value = props.open;
    if (props.active) btnActive.value = props.active;

    buttonContext = buildButtonFilterContext(refButtonFilter);
  });

  watch(() => props.active, () => {
    if (typeof props.active !== 'undefined')
      btnActive.value = props.active;
  }, { immediate: true });

  watch([btnOpen, btnActive], ([newOpen, newActive], [oldOpen, oldActive]) => {
    if (timeline.isActive()) {
      timeline.pause();
      timeline.kill();
    }

    if (newOpen) {
      handleOpenFilterAnimation();
    } else {
      handleCloseFilterAnimation();
    }
  });

  function buildCssClasses(themeName: string): string[] {
    const baseClasses = [
      btnClasses.ButtonFilterContainerClass,
      "rounded-xl",
      "bg-surface-container",
      "on-surface-variant",
      "position-relative",
      "d-flex",
    ];

    if (toValue(btnActive) && !toValue(btnOpen)) {
      if (themeName == "light") baseClasses.push("bg-surface-variant-bright");
      else if (themeName == "dark")
        baseClasses.push("bg-surface-container-lowest");

      baseClasses.push("text-primary");
    }

    if (!toValue(btnOpen)) baseClasses.push("cursor-pointer");

    return baseClasses;
  }

  const displayNormalIcon = computed(() => {
    if (btnOpen.value) {
      return { opacity: 0, visibility: "hidden" };
    } else {
      if (btnActive.value) {
        return { display: "none" };
      } else {
        return {};
      }
    }
  });

  const displayClearButton = computed(() => {
    if (btnActive.value) {
      if (btnOpen.value) {
        return { display: "none" };
      } else {
        return {};
      }
    } else {
      return { display: "none" };
    }
  });

  function buildButtonFilterContext(
    refButtonFilter: Ref<Element>
  ): ButtonFilterAnimationContext {
    const ctx = gsap.context((self) => {
      self.add("onOpenFilter", () => {
        const tl = gsap.timeline({ defaults: { duration: 0.3 } });

        tl.set(
          `.${btnClasses.ButtonFilterLabelClass}`,
          { marginRight: 16, marginLeft: 24 },
          "<"
        )
          .set(`.${btnClasses.ButtonFilterContainerClass}`, { padding: 0 }, "<")
          .set(
            `.${btnClasses.ButtonFilterButtonSubmitClass}`,
            { marginRight: 8 },
            "<"
          )
          .to(
            `.${btnClasses.ButtonFilterButtonSubmitClass}`,
            { width: 24, autoAlpha: 1 },
            "<"
          )
          .to(
            `.${btnClasses.ButtonFilterButtonSubmitClass}`,
            { width: 24, autoAlpha: 1 },
            "<"
          )
          .to(
            `.${btnClasses.ButtonFilterButtonCloseClass}`,
            { width: 40, x: 48 },
            "<"
          )
          .to(
            `.${btnClasses.ButtonFilterButtonCloseClass}`,
            { autoAlpha: 1 },
            "<"
          );

        return tl;
      });

      self.add("onOpenSlot", () => {
        const state = Flip.getState(
          `.${btnClasses.ButtonFilterSlotContainerClass}`,
          "display, visibility, opacity, width, height"
        );

        const query = gsap.utils.selector(
          `.${btnClasses.ButtonFilterContainerClass}`
        );

        const element = query<HTMLDivElement>(
          `.${btnClasses.ButtonFilterSlotContainerClass}`
        )[0];

        element.style.display = "block";

        return Flip.from(state, {
          onEnter: (element: unknown) => {
            gsap.fromTo(
              element as Element,
              { opacity: 0, visibility: "hidden" },
              { opacity: 1, visibility: "visible", delay: 1 }
            );
          },
          duration: 0.3,
          scale: true,
          absolute: true,
          ease: "power1.inOut",
        });
      });

      self.add("onCloseFilter", () => {
        const tl = gsap.timeline({ defaults: { duration: 0.3 } });

        const iconToShow = toValue(btnActive)
          ? btnClasses.ButtonFilterButtonClearClass
          : btnClasses.ButtonFilterIconNormalClass;

        tl.set(`.${btnClasses.ButtonFilterLabelClass}`, { marginRight: 0 })
          /* .set(`.${iconToShow}`, {
            autoAlpha: 1,
            width: ICON_SIZE,
            height: ICON_SIZE,
            fontSize: ICON_SIZE,
          }) */
          .set(`.${btnClasses.ButtonFilterButtonCloseClass}`, {
            opacity: 0,
            x: 0,
          })
          .set(`.${btnClasses.ButtonFilterButtonSubmitClass}`, { opacity: 0 })
          .set(
            [
              `.${btnClasses.ButtonFilterButtonCloseClass}`,
              `.${btnClasses.ButtonFilterButtonSubmitClass}`,
            ],
            { width: 0 }
          )
          .set(
            [
              `.${btnClasses.ButtonFilterButtonCloseClass}`,
              `.${btnClasses.ButtonFilterButtonSubmitClass}`,
            ],
            { visibility: "hidden" }
          );

        return tl;
      });

      self.add("onCloseSlot", () => {
        const state = Flip.getState(
          `.${btnClasses.ButtonFilterSlotContainerClass}`,
          "display, visibility, opacity, width, height"
        );

        const query = gsap.utils.selector(
          `.${btnClasses.ButtonFilterContainerClass}`
        );

        const element = query<HTMLDivElement>(
          `.${btnClasses.ButtonFilterSlotContainerClass}`
        )[0];

        element.style.display = "none";

        return Flip.from(state, {
          onLeave: (element: unknown) => {
            gsap.fromTo(
              element as Element,
              { opacity: 1, visibility: "visible" },
              { opacity: 0, visibility: "hidden" }
            );
          },
          duration: 0.05,
          scale: true,
          absolute: true,
          ease: "power1.inOut",
        });
      });
    }, toValue(refButtonFilter)) as unknown;

    return ctx as ButtonFilterAnimationContext;
  }

  function handleSubmitFilter(): void {
    emits("submitFilter", () => {
      btnOpen.value = false;
    });
  }

  function handleOpenFilter(): void {
    if (!timeline.isActive()) {
      btnOpen.value = true;

      emits("openFilter");
    }
  }

  function handleOpenFilterAnimation(): void {
    if (toValue(buttonFilterContainerCtx) && toValue(refButtonFilter)) {
      timeline
        .add(
          toValue(buttonFilterContainerCtx).hideOtherFilters(
            toValue(refButtonFilter)
          )
        )
        .add(buttonContext.onOpenFilter(), "-=100%")
        .add(buttonContext.onOpenSlot())
        .play();
    }
  }

  function handleCloseFilter(): void {
    if (!timeline.isActive()) {
      btnOpen.value = false;

      emits("closeFilter");
    }
  }

  function handleCloseFilterAnimation(): void {
    if (toValue(buttonFilterContainerCtx) && toValue(refButtonFilter)) {
      timeline
        .add(
          toValue(buttonFilterContainerCtx).appearOtherFilters(
            toValue(refButtonFilter)
          )
        )
        .add(buttonContext.onCloseFilter(), "<")
        .add(buttonContext.onCloseSlot())
        .play();
    }
  }

  function handleClearFilter(): void {
    emits("clearFilter");
  }

  return {
    btnInitialized,
    btnOpen,
    btnActive,
    btnHidden,
    buildCssClasses,
    displayNormalIcon,
    displayClearButton,
    buildButtonFilterContext,
    handleSubmitFilter,
    handleCloseFilter,
    handleClearFilter,
    handleOpenFilter,
  };
}
