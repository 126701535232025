import { StyleValue } from "vue";

const ButtonFilterValueInput = {
  display: "flex",
  lineHeight: "1.5rem",
  gap: "0.5rem",
};

const ButtonFilterValueContent: StyleValue = {
  display: "flex",
  flexDirection: "row",
  fontSize: "0.875rem",
  lineHeight: "1.5rem",
};

export const ButtonFilterValueStyle = {
  ButtonFilterValueInput,
  ButtonFilterValueContent,
};
