<template>
  <div
    :class="[
      computedContainerClass,
      'd-flex',
      'align-center',
      'flex-1-1',
      'rounded-xl',
    ]"
  >
    <v-btn
      v-for="({ id, label, active }, i) in options"
      :key="i"
      :class="[
        computedButtonClass,
        'flex-1-1',
        'h-100',
        'text-capitalize',
        'pa-0',
      ]"
      :style="active ? computedButtonActiveStyle : undefined"
      variant="text"
      color=""
      @click="() => emits('selected', id)"
      :active="active"
      >{{ t(label) }}</v-btn
    >
  </div>
</template>
<script setup lang="ts">
import { useClassByTheme } from "@/components/hooks/useClassByTheme";
import { useStyleByTheme } from "@/components/hooks/useStyleByTheme";
import { TabsSwitchItem } from "@/model/tabSwitch";
import { DetailTransactionTabs } from "@/model/transaction";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const containerClass = {
  light: "bg-surface-container",
  dark: "bg-surface-container-high",
};

const buttonClass = {
  light: "text-secondary",
  dark: "on-surface-variant",
};

const buttonActiveClass = {
  light: { backgroundColor: "var(--bg-ref-palette-neutral92)" },
  dark: {
    backgroundColor: "rgba(33, 31, 38, 1)",
    color: "rgba(var(--v-theme-ref-palette-secondary80)) !important",
  },
};

const { t } = useI18n();

const computedContainerClass = useClassByTheme(containerClass);
const computedButtonClass = useClassByTheme(buttonClass);

const computedButtonActiveStyle = useStyleByTheme(buttonActiveClass);

defineProps<{ options: TabsSwitchItem<DetailTransactionTabs>[] }>();

const emits =
  defineEmits<
    (e: "selected", id: TabsSwitchItem<DetailTransactionTabs>["id"]) => void
  >();
</script>
