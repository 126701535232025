import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["data-testid"]
const _hoisted_2 = { id: "content" }
const _hoisted_3 = {
  key: 0,
  id: "row",
  class: "d-flex align-start justify-space-between mb-2"
}
const _hoisted_4 = ["data-testid"]
const _hoisted_5 = {
  id: "row",
  class: "d-flex ga-2 align-start justify-space-between"
}
const _hoisted_6 = ["data-testid"]
const _hoisted_7 = ["data-testid"]
const _hoisted_8 = ["data-testid"]
const _hoisted_9 = ["data-testid"]
const _hoisted_10 = ["data-testid"]
const _hoisted_11 = ["data-testid"]
const _hoisted_12 = { id: "content" }
const _hoisted_13 = {
  key: 0,
  id: "row",
  class: "d-flex align-start justify-space-between mb-2"
}
const _hoisted_14 = ["data-testid"]
const _hoisted_15 = {
  id: "row",
  class: "d-flex ga-2 align-start justify-space-between"
}
const _hoisted_16 = ["data-testid"]
const _hoisted_17 = ["data-testid"]
const _hoisted_18 = ["data-testid"]
const _hoisted_19 = ["data-testid"]
const _hoisted_20 = ["data-testid"]

import { generateTestId } from "@/helpers/generateTestId";
import {
  TransactionDetails_Contacts_Billing,
  TransactionDetails_Contacts_Properties,
  TransactionDetails_Contacts_Shipping,
} from "./constants/TransactionDetailsContactsContentMobileIds";
import { useTransactionDetailsContacts } from "./hooks/useTransactionDetailsContacts";
import { useClassByTheme } from "@/components/hooks/useClassByTheme";
import { TransactionDetailsContactsProps } from "@/model/transactionDetailsContacts";


export default /*@__PURE__*/_defineComponent({
  __name: 'TransactionDetailsContactsContentMobile',
  props: {
    billing: {},
    shipping: {}
  },
  setup(__props: any) {



const titleClass = {
  light: "text-cs_ref_palette_neutral50",
  dark: "text-cs_ref_palette_neutral60",
};

const contentClass = {
  light: "text-cs_ref_palette_neutral_variant30",
  dark: "text-cs_ref_palette_neutral_variant80",
};

const titleComputedClass = useClassByTheme(titleClass);
const contentComputedClass = useClassByTheme(contentClass);

const { fullCityCountry, anyProperties } = useTransactionDetailsContacts();

return (_ctx: any,_cache: any) => {
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_unref(anyProperties)(_ctx.billing))
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("h2", {
            "data-testid": 
          _unref(generateTestId)(
            _unref(TransactionDetails_Contacts_Billing),
            _unref(TransactionDetails_Contacts_Properties).Title
          )
        ,
            class: "on-surface-variant font-weight-medium pl-2 pb-1"
          }, " Billing ", 8, _hoisted_1),
          _createVNode(_component_v_card, {
            "data-testid": 
          _unref(generateTestId)(
            _unref(TransactionDetails_Contacts_Billing),
            _unref(TransactionDetails_Contacts_Properties).Card
          )
        ,
            color: "transparent",
            class: "mb-2"
          }, {
            text: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                (_ctx.billing?.phone)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createElementVNode("span", {
                        class: _normalizeClass(_unref(titleComputedClass))
                      }, "Phone", 2),
                      _createElementVNode("span", {
                        "data-testid": 
                  _unref(generateTestId)(
                    _unref(TransactionDetails_Contacts_Billing),
                    _unref(TransactionDetails_Contacts_Properties).Phone
                  )
                ,
                        class: _normalizeClass(_unref(contentComputedClass))
                      }, _toDisplayString(_ctx.billing?.phone), 11, _hoisted_4)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("span", {
                    class: _normalizeClass([_unref(titleComputedClass), "flex-1-0"])
                  }, " Billing address ", 2),
                  _createElementVNode("div", {
                    class: _normalizeClass(_unref(contentComputedClass))
                  }, [
                    (_ctx.billing?.name)
                      ? (_openBlock(), _createElementBlock("p", {
                          key: 0,
                          "data-testid": 
                    _unref(generateTestId)(
                      _unref(TransactionDetails_Contacts_Billing),
                      _unref(TransactionDetails_Contacts_Properties).Name
                    )
                  
                        }, _toDisplayString(_ctx.billing?.name), 9, _hoisted_6))
                      : _createCommentVNode("", true),
                    (_ctx.billing?.addressLine1)
                      ? (_openBlock(), _createElementBlock("p", {
                          key: 1,
                          "data-testid": 
                    _unref(generateTestId)(
                      _unref(TransactionDetails_Contacts_Billing),
                      _unref(TransactionDetails_Contacts_Properties).AddressLine1
                    )
                  
                        }, _toDisplayString(_ctx.billing?.addressLine1), 9, _hoisted_7))
                      : _createCommentVNode("", true),
                    (_ctx.billing?.addressLine2)
                      ? (_openBlock(), _createElementBlock("p", {
                          key: 2,
                          "data-testid": 
                    _unref(generateTestId)(
                      _unref(TransactionDetails_Contacts_Billing),
                      _unref(TransactionDetails_Contacts_Properties).AddressLine2
                    )
                  
                        }, _toDisplayString(_ctx.billing?.addressLine2), 9, _hoisted_8))
                      : _createCommentVNode("", true),
                    (_ctx.billing?.city || _ctx.billing?.country)
                      ? (_openBlock(), _createElementBlock("p", {
                          key: 3,
                          "data-testid": 
                    _unref(generateTestId)(
                      _unref(TransactionDetails_Contacts_Billing),
                      _unref(TransactionDetails_Contacts_Properties).City,
                      _unref(TransactionDetails_Contacts_Properties).Country
                    )
                  
                        }, _toDisplayString(_unref(fullCityCountry)(_ctx.billing)), 9, _hoisted_9))
                      : _createCommentVNode("", true),
                    (_ctx.billing?.zipCode)
                      ? (_openBlock(), _createElementBlock("p", {
                          key: 4,
                          "data-testid": 
                    _unref(generateTestId)(
                      _unref(TransactionDetails_Contacts_Billing),
                      _unref(TransactionDetails_Contacts_Properties).ZipCode
                    )
                  
                        }, _toDisplayString(_ctx.billing?.zipCode), 9, _hoisted_10))
                      : _createCommentVNode("", true)
                  ], 2)
                ])
              ])
            ]),
            _: 1
          }, 8, ["data-testid"])
        ], 64))
      : _createCommentVNode("", true),
    (_unref(anyProperties)(_ctx.shipping))
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("h2", {
            "data-testid": 
          _unref(generateTestId)(
            _unref(TransactionDetails_Contacts_Shipping),
            _unref(TransactionDetails_Contacts_Properties).Title
          )
        ,
            class: "on-surface-variant font-weight-medium pl-2 pb-1"
          }, " Shipping ", 8, _hoisted_11),
          _createVNode(_component_v_card, {
            "data-testid": 
          _unref(generateTestId)(
            _unref(TransactionDetails_Contacts_Shipping),
            _unref(TransactionDetails_Contacts_Properties).Card
          )
        ,
            color: "transparent"
          }, {
            text: _withCtx(() => [
              _createElementVNode("div", _hoisted_12, [
                (_ctx.shipping?.phone)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                      _createElementVNode("span", {
                        class: _normalizeClass(_unref(titleComputedClass))
                      }, "Phone", 2),
                      _createElementVNode("span", {
                        "data-testid": 
                  _unref(generateTestId)(
                    _unref(TransactionDetails_Contacts_Shipping),
                    _unref(TransactionDetails_Contacts_Properties).Phone
                  )
                ,
                        class: _normalizeClass(_unref(contentComputedClass))
                      }, _toDisplayString(_ctx.shipping?.phone), 11, _hoisted_14)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("span", {
                    class: _normalizeClass([_unref(titleComputedClass), "flex-1-0"])
                  }, " Shipping address ", 2),
                  _createElementVNode("div", {
                    class: _normalizeClass(_unref(contentComputedClass))
                  }, [
                    (_ctx.shipping?.name)
                      ? (_openBlock(), _createElementBlock("p", {
                          key: 0,
                          "data-testid": 
                    _unref(generateTestId)(
                      _unref(TransactionDetails_Contacts_Shipping),
                      _unref(TransactionDetails_Contacts_Properties).Name
                    )
                  
                        }, _toDisplayString(_ctx.shipping?.name), 9, _hoisted_16))
                      : _createCommentVNode("", true),
                    (_ctx.shipping?.addressLine1)
                      ? (_openBlock(), _createElementBlock("p", {
                          key: 1,
                          "data-testid": 
                    _unref(generateTestId)(
                      _unref(TransactionDetails_Contacts_Shipping),
                      _unref(TransactionDetails_Contacts_Properties).AddressLine1
                    )
                  
                        }, _toDisplayString(_ctx.shipping?.addressLine1), 9, _hoisted_17))
                      : _createCommentVNode("", true),
                    (_ctx.shipping?.addressLine2)
                      ? (_openBlock(), _createElementBlock("p", {
                          key: 2,
                          "data-testid": 
                    _unref(generateTestId)(
                      _unref(TransactionDetails_Contacts_Shipping),
                      _unref(TransactionDetails_Contacts_Properties).AddressLine2
                    )
                  
                        }, _toDisplayString(_ctx.shipping?.addressLine2), 9, _hoisted_18))
                      : _createCommentVNode("", true),
                    (_ctx.shipping?.city || _ctx.shipping?.country)
                      ? (_openBlock(), _createElementBlock("p", {
                          key: 3,
                          "data-testid": 
                    _unref(generateTestId)(
                      _unref(TransactionDetails_Contacts_Shipping),
                      _unref(TransactionDetails_Contacts_Properties).City,
                      _unref(TransactionDetails_Contacts_Properties).Country
                    )
                  
                        }, _toDisplayString(_unref(fullCityCountry)(_ctx.shipping)), 9, _hoisted_19))
                      : _createCommentVNode("", true),
                    (_ctx.shipping?.zipCode)
                      ? (_openBlock(), _createElementBlock("p", {
                          key: 4,
                          "data-testid": 
                    _unref(generateTestId)(
                      _unref(TransactionDetails_Contacts_Shipping),
                      _unref(TransactionDetails_Contacts_Properties).ZipCode
                    )
                  
                        }, _toDisplayString(_ctx.shipping?.zipCode), 9, _hoisted_20))
                      : _createCommentVNode("", true)
                  ], 2)
                ])
              ])
            ]),
            _: 1
          }, 8, ["data-testid"])
        ], 64))
      : _createCommentVNode("", true)
  ]))
}
}

})