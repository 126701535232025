export const label = {
  TopMenu: {
    Search: "label.TopMenu.Search"
  },
  Shared: {
    TransactionStatus: {
      Approved: "label.Shared.TransactionStatus.Approved",
      Denied: "label.Shared.TransactionStatus.Denied",
      Analyzing: "label.Shared.TransactionStatus.Analyzing",
      Canceled: "label.Shared.TransactionStatus.Canceled",
      _: "label.Shared.TransactionStatus"
    },
    TransactionActions: {
      ForceApproval: "label.Shared.TransactionActions.ForceApproval",
      Reanalyze: "label.Shared.TransactionActions.Reanalyze",
      Prioritize: "label.Shared.TransactionActions.Prioritize",
      RequestChargeback: "label.Shared.TransactionActions.RequestChargeback",
      CancelOrder: "label.Shared.TransactionActions.CancelOrder",
      _: "label.Shared.TransactionActions"
    }
  },
  SummaryCard: {
    title: "label.SummaryCard.title"
  },
  AddOrder: "label.AddOrder",
  ButtonFilter: {
    ID: {
      title: "label.ButtonFilter.ID.title",
      placeholder: "label.ButtonFilter.ID.placeholder"
    },
    Date: {
      title: "label.ButtonFilter.Date.title",
      Week: "label.ButtonFilter.Date.Week",
      MTD: "label.ButtonFilter.Date.MTD",
      Month: "label.ButtonFilter.Date.Month",
      ThreeMonths: "label.ButtonFilter.Date.ThreeMonths",
      SixMonths: "label.ButtonFilter.Date.SixMonths",
      OneYear: "label.ButtonFilter.Date.OneYear",
      Custom: "label.ButtonFilter.Date.Custom"
    },
    Store: {
      title: "label.ButtonFilter.Store.title",
      placeholder: "label.ButtonFilter.Store.placeholder"
    },
    Status: "label.ButtonFilter.Status",
    Value: {
      title: "label.ButtonFilter.Value.title",
      From: "label.ButtonFilter.Value.From",
      To: "label.ButtonFilter.Value.To"
    },
    Advanced: "label.ButtonFilter.Advanced",
    BottomSheet: {
      suggestions: "label.ButtonFilter.BottomSheet.suggestions",
      Date: {
        CustomPeriod: "label.ButtonFilter.BottomSheet.Date.CustomPeriod"
      }
    }
  },
  TransactionDetailsPage: {
    title: "label.TransactionDetailsPage.title",
    Actions: "label.TransactionDetailsPage.Actions",
    DetailTransactionTabs: {
      Summary: {
        title: "label.TransactionDetailsPage.DetailTransactionTabs.Summary.title",
        Order: {
          title: "label.TransactionDetailsPage.DetailTransactionTabs.Summary.Order.title",
          ID: "label.TransactionDetailsPage.DetailTransactionTabs.Summary.Order.ID",
          Store: "label.TransactionDetailsPage.DetailTransactionTabs.Summary.Order.Store",
          Value: "label.TransactionDetailsPage.DetailTransactionTabs.Summary.Order.Value"
        },
        Status: {
          title: "label.TransactionDetailsPage.DetailTransactionTabs.Summary.Status.title",
          TransactionStatus: "label.TransactionDetailsPage.DetailTransactionTabs.Summary.Status.TransactionStatus",
          ChargebackStatus: "label.TransactionDetailsPage.DetailTransactionTabs.Summary.Status.ChargebackStatus"
        },
        Costumer: {
          title: "label.TransactionDetailsPage.DetailTransactionTabs.Summary.Costumer.title",
          Email: "label.TransactionDetailsPage.DetailTransactionTabs.Summary.Costumer.Email"
        },
        Dates: {
          title: "label.TransactionDetailsPage.DetailTransactionTabs.Summary.Dates.title",
          TransactionDate: "label.TransactionDetailsPage.DetailTransactionTabs.Summary.Dates.TransactionDate",
          ClearSaleDate: "label.TransactionDetailsPage.DetailTransactionTabs.Summary.Dates.ClearSaleDate"
        }
      },
      History: {
        title: "label.TransactionDetailsPage.DetailTransactionTabs.History.title",
        OrderHistory: {
          title: "label.TransactionDetailsPage.DetailTransactionTabs.History.OrderHistory.title"
        },
        TransactionReceived: "label.TransactionDetailsPage.DetailTransactionTabs.History.TransactionReceived",
        Analysis: "label.TransactionDetailsPage.DetailTransactionTabs.History.Analysis",
        Risks: "label.TransactionDetailsPage.DetailTransactionTabs.History.Risks",
        FraudScore: "label.TransactionDetailsPage.DetailTransactionTabs.History.FraudScore",
        Results: "label.TransactionDetailsPage.DetailTransactionTabs.History.Results",
        TransactionDenied: "label.TransactionDetailsPage.DetailTransactionTabs.History.TransactionDenied",
        ReanalysisRequested: "label.TransactionDetailsPage.DetailTransactionTabs.History.ReanalysisRequested",
        User: "label.TransactionDetailsPage.DetailTransactionTabs.History.User",
        Attachments: "label.TransactionDetailsPage.DetailTransactionTabs.History.Attachments",
        Comments: "label.TransactionDetailsPage.DetailTransactionTabs.History.Comments",
        TransactionApproved: "label.TransactionDetailsPage.DetailTransactionTabs.History.TransactionApproved",
        ChargebackRequested: "label.TransactionDetailsPage.DetailTransactionTabs.History.ChargebackRequested",
        ChargebackApproved: "label.TransactionDetailsPage.DetailTransactionTabs.History.ChargebackApproved",
        ChargebackPaid: "label.TransactionDetailsPage.DetailTransactionTabs.History.ChargebackPaid",
        ShowMore: "label.TransactionDetailsPage.DetailTransactionTabs.History.ShowMore",
        ShowLess: "label.TransactionDetailsPage.DetailTransactionTabs.History.ShowLess"
      },
      Items: {
        title: "label.TransactionDetailsPage.DetailTransactionTabs.Items.title",
        Breakdown: {
          title: "label.TransactionDetailsPage.DetailTransactionTabs.Items.Breakdown.title",
          Total: "label.TransactionDetailsPage.DetailTransactionTabs.Items.Breakdown.Total",
          Categories: "label.TransactionDetailsPage.DetailTransactionTabs.Items.Breakdown.Categories"
        }
      },
      Payment: {
        title: "label.TransactionDetailsPage.DetailTransactionTabs.Payment.title",
        Device: {
          title: "label.TransactionDetailsPage.DetailTransactionTabs.Payment.Device.title",
          IPAddress: "label.TransactionDetailsPage.DetailTransactionTabs.Payment.Device.IPAddress"
        },
        PaymentType: {
          Name: "label.TransactionDetailsPage.DetailTransactionTabs.Payment.PaymentType.Name",
          Number: "label.TransactionDetailsPage.DetailTransactionTabs.Payment.PaymentType.Number",
          Amount: "label.TransactionDetailsPage.DetailTransactionTabs.Payment.PaymentType.Amount"
        }
      },
      Contacts: {
        title: "label.TransactionDetailsPage.DetailTransactionTabs.Contacts.title",
        Billing: {
          title: "label.TransactionDetailsPage.DetailTransactionTabs.Contacts.Billing.title"
        },
        Phone: "label.TransactionDetailsPage.DetailTransactionTabs.Contacts.Phone",
        Address: "label.TransactionDetailsPage.DetailTransactionTabs.Contacts.Address",
        Shipping: {
          title: "label.TransactionDetailsPage.DetailTransactionTabs.Contacts.Shipping.title"
        },
        ViewMap: "label.TransactionDetailsPage.DetailTransactionTabs.Contacts.ViewMap"
      }
    }
  }
}