import { useLocalStorage } from '@/utils/localstorage/localstorage';
import { jwtDecode, JwtPayload } from "jwt-decode";



const authUrl = process.env.VUE_APP_AUTH_URL || ''
const clientId = process.env.VUE_APP_AUTH_CLIENT_ID || ''
const redirectUri = process.env.VUE_APP_AUTH_REDIRECT_URI || ''
const userFlow = process.env.VUE_APP_AUTH_FLOW || ''
const tenant = process.env.VUE_APP_AUTH_TENANT || ''
const scopes = ['openid', 'offline_access']

export async function redirectToLogin(url?: string) {
  const generateRandomString = (length: number) => {
    const chars =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const values = crypto.getRandomValues(new Uint8Array(length))
    return values.reduce((acc, x) => acc + chars[x % chars.length], '')
  }

  const sha256 = async (plain: string) => {
    const encoder = new TextEncoder()
    const data = encoder.encode(plain)
    return crypto.subtle.digest('SHA-256', data)
  }

  const b64encode = (input: ArrayBuffer) => {
    return btoa(String.fromCharCode(...new Uint8Array(input)))
      .replace(/=/g, '')
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
  }

  const codeVerifier = generateRandomString(64)
  const codeChallenge = b64encode(await sha256(codeVerifier))
  const codeChallengeMethod = 'S256'

  const authorizeUrl = new URL(`${authUrl}/${tenant}/oauth2/v2.0/authorize`)

  authorizeUrl.searchParams.set('p', userFlow)
  authorizeUrl.searchParams.set('client_id', clientId)
  authorizeUrl.searchParams.set('code_challenge', codeChallenge)
  authorizeUrl.searchParams.set('code_challenge_method', codeChallengeMethod)
  authorizeUrl.searchParams.set('response_type', 'code')
  authorizeUrl.searchParams.set('scope', scopes.join(' '))

  localStorage.setItem('auth.code-verifier', codeVerifier)

  if (url)
    useLocalStorage().setStorage('before-route-path', url)

  window.location.href = authorizeUrl.toString() + `&redirect_uri=${redirectUri}`

}

export function generateToken() {
  const header = {
    alg: "HS256",
    typ: "JWT"
  };

  const payload = {
    aud: "your-client-id",
    exp: Math.floor(Date.now() / 1000) + (60 * 60), // 1 hour from now
  };

  const base64Encode = (obj: object) => btoa(JSON.stringify(obj));

  const headerBase64 = base64Encode(header);
  const payloadBase64 = base64Encode(payload);
  const signature = "dummy-signature"; // For simulation purposes

  const token = `${headerBase64}.${payloadBase64}.${signature}`;

  return token;
}

export function validateSession() {
  const tokenString = useLocalStorage().getStorage('token')

  if (!tokenString || tokenString == 'undefined' || !tokenString.includes('{')) {
    useLocalStorage().removeStorage('token')
    return false
  }

  const token = JSON.parse(tokenString) as any

  if (!token.access_token) {
    useLocalStorage().removeStorage('token')
    return false
  }

  const decoded_access_token = jwtDecode(token.access_token);
  const expiresAt = new Date(decoded_access_token.exp as number * 1000);
  if (decoded_access_token.aud != clientId || expiresAt <= new Date()) {
    useLocalStorage().removeStorage('token')
    return false
  }

  return true
}

export function getTokenName(): string {
  type JwtWithName = JwtPayload & { name?: string };

  const tokenString = useLocalStorage().getStorage("token");

  if (!tokenString) return "";

  const token = JSON.parse(tokenString);

  const { name } = jwtDecode(token.access_token) as JwtWithName;

  if (!name) return "";

  const nameWords = name.trim().split(" ");

  if (nameWords.length >= 2)
    return `${nameWords[0]} ${nameWords[nameWords.length - 1]}`;
  else return name;
}

export async function oidcCallback(code: string) {
  const codeVerifier = localStorage.getItem('auth.code-verifier') || '';
  const tokenUrl = `${authUrl}/${tenant}/${userFlow}/oauth2/v2.0/token`;

  const response = await fetch(tokenUrl, {
    method: 'POST',
    body: new URLSearchParams({
      code: code,
      code_verifier: codeVerifier,
      grant_type: 'authorization_code',
      scope: `offline_access openid openid offline_access ${clientId}`,
      redirect_uri: redirectUri
    })
  })
  return await response.json()
}


export async function redirectToLogout() {
  const logoutUrl = new URL(`${authUrl}/${tenant}/${userFlow}/oauth2/v2.0/logout`)

  const idToken = JSON.parse(useLocalStorage().getStorage('token') as any).id_token;
  useLocalStorage().removeStorage('token')
  window.location.href = logoutUrl.toString() + `?post_logout_redirect_uri=${process.env.VUE_APP_AUTH_REDIRECT_URI}&id_token_hint=${idToken}`
}
