<template>
  <div
    style="position: relative; z-index: 1"
    :style="{ padding: `${mdAndUp ? '8px 32px' : '8px 8px 16px'}` }"
  >
    <cs-top-bar>
      <div slot="prepend" style="max-width: 360px">
        <v-sheet
          class="w-100 rounded-pill px-4 d-flex align-center"
          height="40px"
          :class="
            themeName === 'dark' ? 'bg-surface' : 'bg-surface-variant-bright'
          "
          style="gap: 8px"
          v-if="mdAndUp"
        >
          <v-icon icon="mdi-magnify"></v-icon>
          <input
            type="text"
            class="text-on-surface-variant w-100"
            style="outline: none"
            placeholder="Search on ClearSale"
          />
        </v-sheet>
        <div v-else>
          <v-btn
            @click="emits('openMenu')"
            variant="text"
            icon="mdi-menu"
            color="grey-darken-4"
          ></v-btn>
          <v-btn
            icon="mdi-magnify"
            variant="text"
            color="grey-darken-4"
          ></v-btn>
        </div>
      </div>
      <div v-if="!mdAndUp" slot="title">
        <img
          :src="imgClearsaleOptions.src"
          :alt="imgClearsaleOptions.alt"
          class="ml-10"
        />
      </div>
      <div slot="append">
        <div class="d-flex align-center">
          <div v-if="mdAndUp">
            <v-btn
              variant="text"
              icon="mdi-help-circle-outline"
              color="grey-darken-4"
            ></v-btn>
            <v-btn variant="text" icon="mdi-apps" color="grey-darken-4"></v-btn>
          </div>
          <v-btn
            variant="text"
            icon="mdi-bell-outline"
            color="grey-darken-4"
          ></v-btn>
          <v-btn
            variant="text"
            icon="mdi-account-circle-outline"
            color="grey-darken-4"
            @click="redirectToLogout"
          ></v-btn>
          <v-btn
            variant="text"
            icon="mdi-brightness-4"
            color="grey-darken-4"
            @click="toggleTheme"
          ></v-btn>
        </div>
      </div>
    </cs-top-bar>
  </div>
</template>
<script setup lang="ts">
import { useDisplay, useTheme } from "vuetify";
import { defineEmits, ref } from "vue";
import imgClearsale from "@/assets/logo_mobile.png";
import { redirectToLogout } from "@/services/auth";

const emits = defineEmits<{ openMenu: [] }>();
const { mdAndUp } = useDisplay();
const { global, name: themeName } = useTheme();

function toggleTheme() {
  global.name.value = global.current.value.dark ? "light" : "dark";
}

const imgClearsaleOptions = ref({
  src: imgClearsale,
  alt: "Logo Clear Sale",
});
</script>
