import { defineStore } from "pinia";
import { storeKeys } from "./storeKeys";
import { ITransaction, ITransactionStatus } from "@/api/resources/Transactions/transactionResource";



type Filters = {
  entityIds: string[],
  codes: string[],
  amountFrom: number,
  amountTo: number,
  status: ITransactionStatus[],
  dateFrom: Date | null,
  dateTo: Date | null,

}
export const useTransactionStore = defineStore(storeKeys.transaction, {
  state: () => ({
    transactionList: [] as ITransaction[],
    summaryTransaction: {
      [ITransactionStatus.Approved]: 0,
      [ITransactionStatus.Denied]: 0,
      [ITransactionStatus.Analyzing]: 0,
      [ITransactionStatus.Canceled]: 0
    } as Record<ITransactionStatus, number>,
    filters: {
      entityIds: [] as string[],
      codes: [] as string[],
      amountFrom: 0,
      amountTo: 0,
      status: [] as ITransactionStatus[],
      dateFrom: null,
      dateTo: null,
    } as Filters,
    page: 1,
    offset: 20
  }),
  actions: {
    addTransaction(transaction: ITransaction) {
      this.transactionList.push(transaction)
    },
    removeTransaction(transaction: ITransaction) {
      const index = this.transactionList.findIndex(t => t.id === transaction.id)
      if (index === -1) return
      this.transactionList.splice(index, 1)
    },
    updateTransactionList(transactionList: ITransaction[]) {
      this.transactionList = transactionList
    },
    updateSummaryTransaction(summaryTransaction: Record<ITransactionStatus, number>) {
      this.summaryTransaction = summaryTransaction
    }
  },
  getters: {
    getTransactionList(): ITransaction[] {
      return this.transactionList
    },
    getSummaryTransaction(): Record<ITransactionStatus, number> {
      return this.summaryTransaction
    },

  },

})


