<template>
  <div
    :data-testid="
      generateTestId(TransactionSummaryConstants.TransactionSummary)
    "
    :class="['content', displaySizeClass]"
  >
    <h2 class="text-h5 mt-8 mb-4 ml-4">Order</h2>
    <section class="elevation-1 rounded-xl pa-6">
      <div class="d-flex justify-space-between">
        <span>ID</span>
        <span>#{{ summary?.id }}</span>
      </div>
      <div class="d-flex justify-space-between">
        <span>Store</span>
        <span>{{ summary?.entityName }}</span>
      </div>
      <div class="d-flex justify-space-between">
        <span>Value</span>
        <span>${{ summary?.totalAmount }}</span>
      </div>
    </section>

    <h2 class="text-h5 mt-8 mb-4 ml-4">Status</h2>
    <section class="elevation-1 rounded-xl pa-6">
      <div class="d-flex justify-space-between">
        <span>Order status</span>
        <span
          v-if="summary?.status"
          :class="['status', theme, transactionStatusTheme]"
          class="d-flex align-center"
        >
          <v-icon :icon="transactionStatusIcon[summary.status]" :size="18" />
          {{ ITransactionStatus[summary.status] }}
        </span>
      </div>
      <div
        class="d-flex justify-space-between"
        v-if="summary?.chargebackStatus"
      >
        <span>Chargeback status</span>
        <span
          :class="['status', theme, chargebackStatusTheme]"
          class="d-flex align-center"
        >
          <v-icon
            :icon="transactionStatusIcon[summary?.chargebackStatus]"
            :size="18"
          />
          {{ ITransactionStatus[summary?.chargebackStatus] }}
        </span>
      </div>
    </section>

    <h2 class="text-h5 mt-8 mb-4 ml-4">Costumer</h2>
    <section class="elevation-1 rounded-xl pa-6">
      <div class="d-flex justify-space-between">
        <span>Email</span>
        <span>
          <v-icon icon="mdi-email-outline" :size="18" />
          {{ summary?.email }}
        </span>
      </div>
    </section>

    <h2 class="text-h5 mt-8 mb-4 ml-4">Dates</h2>
    <section class="elevation-1 rounded-xl pa-6">
      <div class="d-flex justify-space-between">
        <span>Order date</span>
        <span>{{ orderDate }}</span>
      </div>
      <div class="d-flex justify-space-between">
        <span>ClearSale date</span>
        <span>{{ clearSaleDate }}</span>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import { generateTestId } from "@/helpers/generateTestId";
import {
  ITransaction,
  ITransactionStatus,
} from "@/api/resources/Transactions/transactionResource";
import {
  useClassByTheme,
  useClassByThemeStatus,
} from "@/components/hooks/useClassByTheme";
import { transactionStatusIcon } from "@/model/transaction";
import { computed } from "vue";
import TransactionSummaryConstants from "./constants/TransactionSummaryId";
import { useDate, useDisplay, useTheme } from "vuetify/lib/framework.mjs";
import { TransactionDetailsSummaryProps } from "./constants/TransactionSummaryProps";

const props = defineProps<{ summary?: TransactionDetailsSummaryProps }>();

const { name: theme } = useTheme();
const transactionStatusTheme = computed(() => {
  if (!props.summary?.status) return "";
  return ITransactionStatus[props.summary?.status].toLowerCase();
});

const chargebackStatusTheme = computed(() => {
  if (!props.summary?.chargebackStatus) return "";
  //TODO add chargeback status
  return ITransactionStatus[props.summary?.chargebackStatus]?.toLowerCase();
});

const display = useDisplay();
const date = useDate();

const orderDate = computed(() => {
  return isTodayDate(props.summary?.consumerRequestedAt as Date)
    ? date.format(props.summary?.consumerRequestedAt, "fullTime12h")
    : date.format(props.summary?.consumerRequestedAt, "fullDate");
});

const clearSaleDate = computed(() => {
  return isTodayDate(props.summary?.clearSaleDate as Date)
    ? date.format(props.summary?.clearSaleDate, "fullTime12h")
    : date.format(props.summary?.clearSaleDate, "fullDate");
});

const displaySizeClass = computed(() => (display.mdAndUp.value ? "md" : "sm"));

function isTodayDate(date: Date) {
  const dateNow = new Date(date);
  const todayDate = new Date();
  todayDate.setHours(0, 0, 0, 0);
  dateNow.setHours(0, 0, 0, 0);

  return dateNow.getTime() === todayDate.getTime();
}
</script>

<style scoped lang="scss">
.content {
  &.sm {
    font-size: var(--v-typescale-body-medium-size);
    .text-h5 {
      font-size: var(--v-typescale-title-medium-size) !important;
    }
  }

  &.md {
    font-size: var(--v-typescale-body-large-size);
    h2.text-h5 {
      font-size: var(--v-typescale-title-large-size) !important;
    }
  }
}

section {
  display: grid;
  row-gap: 1rem;
}

.status {
  &.light {
    &.approved {
      color: rgb(var(--mdui-ref-palette-success50));
    }

    &.denied {
      color: rgb(var(--mdui-ref-palette-error30));
    }

    &.analyzing {
      color: rgb(var(--mdui-ref-palette-information40));
    }

    &.canceled {
      color: rgb(var(--mdui-ref-palette-neutral-variant30));
    }
  }

  &.dark {
    &.approved {
      color: rgb(var(--mdui-ref-palette-success80));
    }

    &.denied {
      color: rgb(var(--mdui-ref-palette-error70));
    }

    &.analyzing {
      color: rgb(var(--mdui-ref-palette-information80));
    }

    &.canceled {
      color: rgb(var(--mdui-ref-palette-neutral-variant80));
    }
  }
}
</style>
