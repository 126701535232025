<template>
  <v-card
    v-if="$slots.default"
    v-bind="$attrs"
    :data-testid="generateTestId(TransactionCardElement)"
    id="transaction-card"
    v-ripple
    :elevation="themeName === 'dark' ? 1 : 0"
    :style="
      themeName === 'light'
        ? 'box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05) !important'
        : ''
    "
    flat
    :class="className"
  >
    <slot />
  </v-card>
  <template v-if="$slots.left && $slots.right">
    <div :data-testid="generateTestId(TransactionCardElement)" id="container">
      <v-card
        v-bind="$attrs"
        ref="left"
        variant="flat"
        id="left"
        :class="className"
        :elevation="themeName === 'dark' ? 1 : 0"
        :style="
          themeName === 'light'
            ? 'box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05) !important'
            : ''
        "
        flat
      >
        <slot name="left" />
      </v-card>
      <v-card :class="className" ref="right" variant="flat" id="right">
        <slot name="right" />
      </v-card>
    </div>
  </template>
</template>

<script setup lang="ts">
import { defineOptions, onMounted, ref } from "vue";
import { useDisplay, useTheme } from "vuetify";
import type { VCard } from "vuetify/components";
import { swipeUtility } from "@clearsale/material-ds";
import { generateTestId } from "@/helpers/generateTestId";
import { TransactionCardElement } from "./constants/TransactionCardId";
import { useClassByTheme } from "@/components/hooks/useClassByTheme";

defineOptions({
  inheritAttrs: true,
});

const props = defineProps<{
  active?: boolean;
}>();
const left = ref<VCard | null>(null);
const right = ref<VCard | null>(null);

const { mobile } = useDisplay();
const { name: themeName } = useTheme();

const classTheme = {
  light: "bg-cs_on_primary",
  dark: "bg-cs_surface_container_low",
};
const className = useClassByTheme(classTheme);

onMounted(() => {
  if (left.value && right.value) {
    let swipe = swipeUtility(left.value.$el, {
      leftElement: right.value.$el,
      rightElement: left.value.$el,
      type: "LEFT",
    });

    if (mobile && props.active) {
      swipe.hammer.destroy();
      return;
    }
    swipe.onLoadEvents(swipe.hammer);
  }
});
</script>

<style lang="scss">
#container {
  position: relative;

  & #left {
    z-index: 1;
  }

  & #right {
    top: 0;
    height: 100%;
    right: 0;
    margin-left: 20px;
    position: absolute;
    display: flex;
  }
}

.checked {
  &.light {
    background-color: rgb(var(--mdui-ref-palette-information95));
  }
  &.dark {
    background-color: rgb(var(--mdui-ref-palette-information30));
  }
}
</style>
