import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import ComponentPreview from '../views/ComponentPreview.vue'
import TransactionView from '@/views/TransactionView.vue'
import TransactionDetailsView from '@/views/TransactionDetailsView.vue'
import { useOIDCCallback } from '@/hooks/useOIDCCallback'
import { redirectToLogin, validateSession } from '@/services/auth'

const activeSSOOnDemo = process.env.VUE_APP_ENV == "development"
  && localStorage.getItem("secret") === process.env.VUE_APP_SECRET_BY_PASS_SSO;

const activeSSOOnProduction = process.env.VUE_APP_ENV === "production";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/shield',
    name: 'transactionView',
    component: TransactionView,
  },
  {
    path: '/shield/:transactionId',
    name: 'transactionDetailView',
    component: TransactionDetailsView
  },
  {
    path: '/shield/preview',
    name: 'preview',
    component: ComponentPreview
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  function redirect() {
    next({
      path: localStorage.getItem("before-route-path-shield") as string,
      replace: true
    })

    localStorage.removeItem("before-route-path-shield")
  }

  if (activeSSOOnDemo || activeSSOOnProduction) {

    if (to.query.code) {
      useOIDCCallback(to.query.code as string, redirect);
      return
    }

    if (!validateSession()) {
      redirectToLogin(to.fullPath);
    }
  }

  next()
})


export default router
