<template>
  <div
    :style="{
      position: 'sticky',
      top: 0,
      width: '100%',
    }"
    :class="classTheme"
    :data-testid="
      generateTestId(ContainerFixedTopElements.ContainerFixedTop, name)
    "
    ref="containerFixedTop"
  >
    <div
      :data-testid="
        generateTestId(ContainerFixedTopElements.ContainerFixedTopContent, name)
      "
    >
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { generateTestId } from "@/helpers/generateTestId";
import { ContainerFixedTopElements } from "./constants/ContainerFixedTopId";
import { useContainerFixedTop } from "./useContainerFixedTop";
import { defineEmits } from "vue";
import { useTheme } from "vuetify/lib/framework.mjs";
import { useClassByTheme } from "@/components/hooks/useClassByTheme";

defineProps<{ name?: string }>();

const emit = defineEmits<{
  (e: "handleTop", isTop: boolean): void;
}>();

const { containerFixedTop } = useContainerFixedTop(emit);

const containerClass = {
  light: "bg-cs_on_primary",
  dark: "bg-cs_surface_container_low",
};

const classTheme = useClassByTheme(containerClass);
</script>
