export const label = {
  TopMenu: {
    Search: "Buscar en ClearSale"
  },
  Shared: {
    TransactionStatus: {
      Approved: "Aprobado",
      Denied: "Denegado",
      Analyzing: "Analizando",
      Canceled: "Cancelado",
      _: "label.Shared.TransactionStatus"
    },
    TransactionActions: {
      ForceApproval: "Forzar aprobación",
      Reanalyze: "Reanalizar",
      Prioritize: "Priorizar",
      RequestChargeback: "Solicitar devolución de cargo",
      CancelOrder: "Cancelar pedido",
      _: "label.Shared.TransactionActions"
    }
  },
  SummaryCard: {
    title: "Pedidos"
  },
  AddOrder: "Añadir",
  ButtonFilter: {
    ID: {
      title: "ID",
      placeholder: "Introducir IDs"
    },
    Date: {
      title: "Fecha",
      Week: "1 semana",
      MTD: "Mes hasta la fecha",
      Month: "1 mes",
      ThreeMonths: "3 meses",
      SixMonths: "6 meses",
      OneYear: "1 año",
      Custom: "Personalizado"
    },
    Store: {
      title: "Tienda",
      placeholder: "Introducir nombres de tiendas"
    },
    Status: "Estado",
    Value: {
      title: "Valor",
      From: "Desde:",
      To: "Hasta:"
    },
    Advanced: "Avanzado",
    BottomSheet: {
      suggestions: "Sugerencias",
      Date: {
        CustomPeriod: "Periodo personalizado"
      }
    }
  },
  TransactionDetailsPage: {
    title: "Detalles del pedido",
    Actions: "Acciones",
    DetailTransactionTabs: {
      Summary: {
        title: "Resumen",
        Order: {
          title: "Pedido",
          ID: "ID",
          Store: "Tienda",
          Value: "Valor"
        },
        Status: {
          title: "Estado",
          TransactionStatus: "Estado del pedido",
          ChargebackStatus: "Estado de devolución de cargo"
        },
        Costumer: {
          title: "Cliente",
          Email: "Correo electrónico"
        },
        Dates: {
          title: "Fechas",
          TransactionDate: "Fecha del pedido",
          ClearSaleDate: "Fecha de ClearSale"
        }
      },
      History: {
        title: "Historial",
        OrderHistory: {
          title: "Historial del pedido"
        },
        TransactionReceived: "Pedido recibido",
        Analysis: "Análisis",
        Risks: "Riesgos",
        FraudScore: "Puntuación de fraude",
        Results: "Resultados",
        TransactionDenied: "Pedido denegado",
        ReanalysisRequested: "Reanálisis",
        User: "Usuario",
        Attachments: "Adjuntos",
        Comments: "Comentarios",
        TransactionApproved: "Pedido aprobado",
        ChargebackRequested: "Devolución de cargo solicitada",
        ChargebackApproved: "Devolución de cargo aprobada",
        ChargebackPaid: "Devolución de cargo pagada",
        ShowMore: "Mostrar más",
        ShowLess: "Mostrar menos"
      },
      Items: {
        title: "Artículos",
        Breakdown: {
          title: "Desglose",
          Total: "Total",
          Categories: "Categorías"
        }
      },
      Payment: {
        title: "Pago",
        Device: {
          title: "Dispositivo",
          IPAddress: "Dirección IP"
        },
        PaymentType: {
          Name: "Nombre",
          Number: "Número",
          Amount: "Cantidad"
        }
      },
      Contacts: {
        title: "Contactos",
        Billing: {
          title: "Facturación"
        },
        Phone: "Teléfono",
        Address: "Dirección",
        Shipping: {
          title: "Envío"
        },
        ViewMap: "Ver mapa"
      }
    }
  }
}