<template>
  <div
    style="
      background-color: white;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
    "
    v-if="showApp"
  >
    <v-theme-provider>
      <div
        :class="className"
        style="flex: 1; display: flex; flex-direction: column"
      >
        <NavigationMenu />
        <RouterView />
      </div>
    </v-theme-provider>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from "vue";
import { useClassByTheme } from "./components/hooks/useClassByTheme";
import NavigationMenu from "./components/one/NavigationMenu/NavigationMenu.vue";
import { validateSession } from "./services/auth";

const classTheme = {
  light: "bg-cs_on_primary",
  dark: "bg-cs_surface_container_low",
};

const showApp = ref(false);

const className = useClassByTheme(classTheme);

const activeSSOOnDemo = process.env.VUE_APP_ENV == "development" &&
  localStorage.getItem("secret") === process.env.VUE_APP_SECRET_BY_PASS_SSO;
  
const activeSSOOnProduction =  process.env.VUE_APP_ENV === "production";

addEventListener("access-token", () => {
  showApp.value = true;
});

onMounted(() => {
  if ((activeSSOOnDemo || activeSSOOnProduction) && !validateSession()) {
    showApp.value = false;
    return;
  }
  showApp.value = true;
});
</script>

<style></style>
