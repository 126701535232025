<template>
  <SummaryCardTransaction></SummaryCardTransaction>
  <div
    id="transactions-page"
    :class="{ mobile: mdAndUp }"
    style="margin-top: 16px"
    :style="{ padding: `${mdAndUp ? '8px 40px' : '8px 16px'}` }"
  >
    <ContainerFixedTop
      style="z-index: 9"
      @handle-top="
        (isTop) => {
          topControllIsActive = isTop;
        }
      "
    >
      <div class="d-flex">
        <template v-if="mdAndUp">
          <ButtonFilterContainer>
            <ButtonFilterID />
            <ButtonFilterDate />
            <ButtonFilterStore />
            <ButtonFilterStatus />
            <ButtonFilterValue />
          </ButtonFilterContainer>
        </template>
        <template v-else>
          <ContainerSwiper>
            <div class="d-flex flex-row ga-2">
              <ButtonFilterIDMobile />
              <ButtonFilterDateMobile />
              <ButtonFilterStoreMobile />
              <ButtonFilterStatusMobile />
              <ButtonFilterValueMobile />
            </div>
          </ContainerSwiper>
        </template>
        <div
          v-if="hiddenButtonsOnScrollTop"
          class="d-flex justify-end align-center ga-2"
        >
          <AddButton></AddButton>
          <ExportButton></ExportButton>
        </div>
      </div>
      <div
        style="position: relative"
        ref="actionBarRef"
        :style="{ opacity: animatedNumber.opacity }"
      >
        <ActionBar
          style="position: absolute"
          :height="+animatedNumber.height.toFixed(0)"
          :lenght="transactionSelected.length"
        ></ActionBar>
      </div>
    </ContainerFixedTop>
    <div ref="transactionListRef" style="margin-top: 24px; flex: 1">
      <div v-if="!mdAndUp" class="list mobile">
        <ListMobileTransactionsSkeleton
          v-if="loading || transactionStore.transactionList.length < 1"
        ></ListMobileTransactionsSkeleton>
        <ListMobileTransactions
          :data="transactionStore.transactionList"
          @fetch-data="scrollInfinityFetchAPI"
          @icon-click="handleTransactionSelected"
          @container-click="handleRouteDetails"
        />
      </div>
      <div v-else class="list">
        <ListDesktopTransactionsSkeleton
          v-if="loading || transactionStore.transactionList.length < 1"
          :lines="20"
        ></ListDesktopTransactionsSkeleton>
        <ListDesktopTransactions
          :data="transactionStore.transactionList"
          @fetch-data="scrollInfinityFetchAPI"
          @icon-click="handleTransactionSelected"
          @container-click="handleRouteDetails"
        />
      </div>
    </div>
  </div>
  <div
    style="
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 0px;
    "
  >
    <img
      style="width: 24px"
      :src="imgClearsaleOptions.src"
      :alt="imgClearsaleOptions.alt"
    />
  </div>
</template>

<script setup lang="ts">
import { useDisplay } from "vuetify";
import { Ref, onMounted, reactive, ref, watch } from "vue";
import gsap from "gsap";

import ListDesktopTransactions from "@/components/transactions/ListDesktopTransactions.vue";
import ListMobileTransactions from "@/components/transactions/ListMobileTransactions.vue";
import SummaryCardTransaction from "@/components/transactions/SummaryCardTransaction.vue";
import ExportButton from "@/components/shared/ExportButton/ExportButton.vue";
import AddButton from "@/components/shared/AddButton/AddButton.vue";
import ActionBar from "@/components/shared/ActionBar/ActionBar.vue";
import ContainerFixedTop from "@/components/shared/ContainerFixedTop/ContainerFixedTop.vue";
import ContainerSwiper from "@/components/shared/ContainerSwiper/ContainerSwiper.vue";

import ButtonFilterContainer from "@/components/shared/ButtonFilterContainer/ButtonFilterContainer.vue";
//desktop
import ButtonFilterDate from "@/components/shared/ButtonFilterDate/ButtonFilterDate.vue";
import ButtonFilterValue from "@/components/shared/ButtonFilterValue/ButtonFilterValue.vue";
import ButtonFilterStatus from "@/components/shared/ButtonFilterStatus/ButtonFilterStatus.vue";
import ButtonFilterID from "@/components/transactions/ButtonFilterID/ButtonFilterID.vue";
import ButtonFilterStore from "@/components/transactions/ButtonFilterStore/ButtonFilterStore.vue";
//mobile
import ButtonFilterDateMobile from "@/components/shared/ButtonFilterDateMobile/ButtonFilterDateMobile.vue";
import ButtonFilterValueMobile from "@/components/shared/ButtonFilterValueMobile/ButtonFilterValueMobile.vue";
import ButtonFilterStatusMobile from "@/components/shared/ButtonFilterStatusMobile/ButtonFilterStatusMobile.vue";
import ButtonFilterIDMobile from "@/components/transactions/ButtonFilterIDMobile/ButtonFilterIDMobile.vue";
import ButtonFilterStoreMobile from "@/components/transactions/ButtonFilterStoreMobile/ButtonFilterStoreMobile.vue";

import ListDesktopTransactionsSkeleton from "@/components/transactions/ListDesktopTransactionsSkeleton.vue";
import ListMobileTransactionsSkeleton from "@/components/transactions/ListMobileTransactionsSkeleton.vue";

import { useTransactionStore } from "@/store/TransactionStore";
import {
  getTotalTransactionStatusAPI,
  getTransactionAPIPOST,
  ITransaction,
} from "@/api/resources/Transactions/transactionResource";
import imgClearsale from "@/assets/logo_mobile.png";
import { useRouter } from "vue-router";

const router = useRouter();
const transactionStore = useTransactionStore();
const { mdAndUp } = useDisplay();
const topControllIsActive = ref(false);
const hiddenButtonsOnScrollTop = ref(true);
const transactionSelected: Ref<ITransaction[]> = ref([]);

const finished = ref(false);
const loading = ref(false);

const transactionListRef = ref<HTMLElement | null>(null);
const actionBarRef = ref<HTMLElement | null>(null);
const animatedNumber = reactive({
  height: 0,
  opacity: 0,
});

const imgClearsaleOptions = ref({
  src: imgClearsale,
  alt: "Logo Clear Sale",
});

function handleTransactionSelected(transaction: ITransaction) {
  const index = transactionSelected.value.findIndex(
    (o) => o.id === transaction.id
  );
  const removeOneTransaction = 1;
  const transactionIsSelected = index !== -1;

  if (transactionIsSelected) {
    transactionSelected.value.splice(index, removeOneTransaction);
    return;
  }
  transactionSelected.value.push(transaction);
}

async function handleRouteDetails(transaction: ITransaction) {
  await router.push({
    name: "transactionDetailView",
    params: { transactionId: transaction.id },
  });
}

function scrollInfinityFetchAPI() {
  if (!finished.value)
    transactionStore.$patch({
      page: transactionStore.$state.page + 1,
    });
}

async function fetchTransactionList() {
  loading.value = true;
  const dateResult = await fetch(
    `transactions?page=${transactionStore.$state.page}&offset=${transactionStore.$state.offset}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...transactionStore.$state.filters,
        page: transactionStore.$state.page,
        offset: transactionStore.$state.offset,
      }),
    }
  )
    .then(async (response) => {
      return response.json();
    })
    .finally(() => {
      loading.value = false;
    });

  finished.value =
    dateResult.result.length < transactionStore.$state.offset ||
    dateResult.result.length === 0;
  transactionStore.updateTransactionList(dateResult.result);
}

watch(
  () => transactionStore.$state.filters,
  async () => {
    fetchTransactionList();
  },
  {
    deep: true,
  }
);

watch(
  () => transactionStore.$state.page,
  async () => {
    loading.value = true;
    getTransactionAPIPOST({
      ...transactionStore.$state.filters,
      page: transactionStore.$state.page,
      offset: transactionStore.$state.offset,
    })
      .then((data) => {
        if (finished.value) return;

        const copyTransactionData = structuredClone(
          transactionStore.transactionList
        );

        copyTransactionData.push(...data.result);
        transactionStore.updateTransactionList(copyTransactionData);

        finished.value =
          data.result.length < transactionStore.$state.offset ||
          data.result.length === 0;
      })
      .finally(() => {
        loading.value = false;
      });
  },
  {
    deep: true,
  }
);

onMounted(() => {
  getTransactionAPIPOST({
    ...transactionStore.$state.filters,
    page: transactionStore.$state.page,
    offset: transactionStore.$state.offset,
  })
    .then((data) => {
      transactionStore.updateTransactionList(data.result);
    })
    .finally(() => {
      loading.value = false;
    });

  getTotalTransactionStatusAPI().then((data) => {
    transactionStore.updateSummaryTransaction(data.valueTransactionStatus);
  });
});

watch(
  transactionSelected,
  (value) => {
    if (value.length === 0) {
      gsap.to(animatedNumber, {
        duration: 0.5,
        opacity: 0,
        ease: "power1.inOut",
      });

      gsap.to(actionBarRef.value, {
        duration: 0.5,
        opacity: 0,
        height: 0,
        ease: "power1.out",
      });
      animatedNumber.height = 0;
      return;
    }

    gsap.to(actionBarRef.value, {
      duration: 0.5,
      opacity: 1,
      height: 48,
      ease: "power1.out",
      onStart: () => {
        setTimeout(() => {
          animatedNumber.height = 40;
        }, 100);
      },
    });

    gsap.to(animatedNumber, {
      duration: 0.5,
      opacity: 1,
      ease: "power1.inOut",
    });
  },
  {
    deep: true,
  }
);

watch(topControllIsActive, (value) => {
  hiddenButtonsOnScrollTop.value = mdAndUp.value || !value;
});
</script>

<style lang="scss" scoped>
#orders-view {
  overflow: hidden;

  &.mobile {
    & .title {
      background-image: none;
    }

    & h1 {
      display: none;
    }
  }
}

.list {
  width: 100%;
  box-sizing: border-box;
}
#transactions-page {
  display: flex;
  flex-direction: column;
  flex: 1;
}
</style>
