<template>
  <ButtonFilterTextMobile
    v-model:input="inputValue"
    :options="suggestionBoxListFiltered"
    :loadingSuggestions="loadingSuggestionBox"
    label="ID"
    placeholder="Enter IDs"
    :showOptions="suggestionBoxListFiltered.length > 0"
    @submit="
      (value) => {
        $patch({ filters: { codes: value.map((chip) => chip.label) } });
        $patch({ page: 1, offset: 20 });
      }
    "
    @clear="
      () => {
        $patch({ filters: { codes: [] } });
        $patch({ page: 1, offset: 20 });
      }
    "
    name="filter-id"
  />
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { useDebounceFn } from "@vueuse/core";

import ButtonFilterTextMobile from "@/components/shared/ButtonFilterTextMobile/ButtonFilterTextMobile.vue";
import { getSuggestionAPIPOST } from "@/api/resources/Transactions/transactionResource";
import { useTransactionStore } from "@/store/TransactionStore";
import { Chip } from "@/model/chip";

const TIME_DEBOUNCE_INPUT_VALUE = 500;

const inputValue = ref("");
const suggestionBoxListFiltered = ref<Chip[]>([]);
const loadingSuggestionBox = ref(false);

const { $patch } = useTransactionStore();

const debounceInputValue = useDebounceFn((value: string) => {
  getSuggestionAPIPOST({ type: 1, limit: 10, value })
    .then((data) => {
      suggestionBoxListFiltered.value = data.result.map((r) => ({
        chipId: r.id,
        label: r.subValue ? `${r.value} - ${r.subValue}` : r.value,
      }));
    })
    .finally(() => {
      loadingSuggestionBox.value = false;
    });
}, TIME_DEBOUNCE_INPUT_VALUE);

watch(inputValue, (value) => {
  if (inputValue.value !== "") {
    loadingSuggestionBox.value = true;
    debounceInputValue(value);
  }
});
</script>
