<template>
  <div
    :data-testid="generateTestId(CustomHeroElements.Content)"
    id="hero"
    :style="{
      backgroundImage: `url('${srcImage}')`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% 100%',
      padding: `${mdAndUp ? '24px' : '12px'}`,
    }"
    class="h-100 w-100 position-relative rounded-xl"
  >
    <h1
      :style="{
        fontSize: mdAndUp ? '57px' : '36px',
        lineHeight: 'initial',
        fontWeight: '400',
      }"
    >
      {{ label }}
    </h1>
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { useDisplay } from "vuetify";
import { generateTestId } from "@/helpers/generateTestId";
import { CustomHeroElements } from "./constants/CustomHeroElements";
const { mdAndUp } = useDisplay();

defineProps<{ label?: string; srcImage?: string }>();
</script>

<style scoped lang="scss"></style>
