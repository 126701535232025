<template>
  <transaction-card-list v-if="data.length" :onOnLoadLastItem="fetchData">
    <transaction-card
      v-for="(transaction, index) in data"
      :key="index"
      variant="flat"
      :class="[themeName, checkedList[index] ? 'checked' : '', 'rounded-pill']"
      @mouseenter="(e) => show(e, index)"
      @mouseleave="(e) => show(e, index)"
      @click.prevent.stop="containerClick(transaction)"
      @touchStart.prevent.stop="containerClick(transaction)"
    >
      <template #default>
        <div class="container">
          <div class="status-icon checkbox">
            <v-icon
              :icon="transactionStatusIcon[transaction.status]"
              :class="[classNameWhole[transaction.status], 'whole']"
            />
            <v-checkbox
              :model-value="checkedList[index]"
              width="32px"
              color="information"
              @click.prevent.stop="iconClick(transaction, index)"
              @touchStart.prevent.stop="iconClick(transaction, index)"
            />
          </div>
          <div class="id">
            <span>#</span><span>{{ ellipsisText(transaction.code) }}</span>
          </div>
          <div v-if="transaction.entityName" class="client-name">
            <span>{{ ellipsisText(transaction.entityName) }}</span>
          </div>

          <div class="value">
            {{
              transaction.totalAmount.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })
            }}
          </div>
          <div class="status">
            <div
              :class="[themeName, classNameText[transaction.status], 'text']"
            >
              <v-icon :icon="transactionStatusIcon[transaction.status]" />
                <span>{{ t(`${label.Shared.TransactionStatus._}.${ITransactionStatus[transaction.status]}`) }}</span>
            </div>
          </div>
          <div class="date">
            <span>{{ d(transaction.consumerRequestedAt, 'short') }}</span>
          </div>
          <div class="actions">
            <v-btn
              variant="text"
              icon="mdi-check"
              color="information"
              @click.prevent.stop="() => console.log('mdi-check')"
            />
            <v-btn
              variant="text"
              icon="mdi-repeat"
              color="information"
              @click.prevent.stop="() => console.log('mdi-repeat')"
            />
            <v-btn
              variant="text"
              icon="mdi-priority-high"
              color="information"
              @click.prevent.stop="() => console.log('mdi-priority-high')"
            />
            <v-btn
              variant="text"
              icon="mdi-rotate-left"
              color="information"
              @click.prevent.stop="() => console.log('mdi-rotate-left')"
            />
            <v-btn
              variant="text"
              icon="mdi-square"
              color="information"
              @click.prevent.stop="() => console.log('mdi-square')"
            />
          </div>
        </div>
      </template>
    </transaction-card>
  </transaction-card-list>
</template>

<script setup lang="ts">
import TransactionCard from "@/components/shared/TransactionCard/TransactionCard.vue";
import TransactionCardList from "@/components/shared/TransactionCardList/TransactionCardList.vue";

import { transactionStatusIcon } from "@/model/transaction";
import { useDate, useTheme } from "vuetify";
import { ref } from "vue";
import {
  ITransaction,
  ITransactionStatus,
} from "@/api/resources/Transactions/transactionResource";
import {
  useClassByThemeStatus,
  UseClassByThemeStatusProps,
} from "../hooks/useClassByTheme";
import { useI18n } from "vue-i18n";
import { label } from "@/plugins/locales/global";

defineProps<{ data: ITransaction[] }>();

const { t,d } = useI18n();
const emits = defineEmits<{
  (e: "iconClick", transaction: ITransaction): void;
  (e: "containerClick", transaction: ITransaction): void;
  (e: "fetchData"): void;
}>();

const { name: themeName } = useTheme();

function isTodayDate(date: string | Date) {
  const dateNow = new Date(date);
  const todayDate = new Date();
  todayDate.setHours(0, 0, 0, 0);
  dateNow.setHours(0, 0, 0, 0);

  return dateNow.getTime() === todayDate.getTime();
}

const checkedList = ref<{ [x: number]: boolean }>([]);

function ellipsisText(input: string): string {
  const INPUT_LENGTH = 14;
  return input.length > INPUT_LENGTH
    ? `${input.substring(0, INPUT_LENGTH)}...`
    : input;
}

function show(evt: MouseEvent, index: number) {
  const statusIconElement = getStatusIconElement(evt.target as HTMLElement);
  const checkboxElement = getCheckboxElement(evt.target as HTMLElement);
  const dateElement = getDateElement(evt.target as HTMLElement);
  const actionsElement = getActionsElement(evt.target as HTMLElement);

  const anyChecked = Object.values(checkedList.value).find(
    (checked) => checked
  );

  if (evt.type == "mouseenter") {
    handleMouseEnter(
      dateElement,
      actionsElement,
      statusIconElement,
      checkboxElement,
      anyChecked
    );
  }

  if (evt.type == "mouseleave") {
    handleMouseLeave(
      dateElement,
      actionsElement,
      statusIconElement,
      checkboxElement,
      index
    );
  }
}

function getStatusIconElement(target: HTMLElement): HTMLElement | null {
  return target.querySelector<HTMLElement>(".status-icon.checkbox > .v-icon");
}

function getCheckboxElement(target: HTMLElement): HTMLElement | null {
  return target.querySelector<HTMLElement>(
    ".status-icon.checkbox > .v-checkbox"
  );
}

function getDateElement(target: HTMLElement): HTMLElement | null {
  return target.querySelector<HTMLElement>(".date");
}

function getActionsElement(target: HTMLElement): HTMLElement | null {
  return target.querySelector<HTMLElement>(".actions");
}

function handleMouseEnter(
  dateElement: HTMLElement | null,
  actionsElement: HTMLElement | null,
  statusIconElement: HTMLElement | null,
  checkboxElement: HTMLElement | null,
  anyChecked: boolean | undefined
) {
  if (dateElement && actionsElement && statusIconElement && checkboxElement) {
    if (!anyChecked) {
      dateElement.style.display = "none";
      actionsElement.style.display = "flex";
    }

    statusIconElement.style.display = "none";
    checkboxElement.style.display = "flex";
  }
}

function handleMouseLeave(
  dateElement: HTMLElement | null,
  actionsElement: HTMLElement | null,
  statusIconElement: HTMLElement | null,
  checkboxElement: HTMLElement | null,
  index: number
) {
  if (dateElement && actionsElement && statusIconElement && checkboxElement) {
    statusIconElement.style.display = checkedList.value[index]
      ? "none"
      : "flex";
    checkboxElement.style.display = checkedList.value[index] ? "flex" : "none";
    dateElement.style.display = "initial";
    actionsElement.style.display = "none";
  }
}

function iconClick(transaction: ITransaction, index: number) {
  emits("iconClick", transaction);
  if (!checkedList.value[index]) {
    checkedList.value[index] = true;
    return;
  }

  checkedList.value[index] = !checkedList.value[index];
}

function containerClick(transaction: ITransaction) {
  emits("containerClick", transaction);
}

function fetchData(): void {
  emits("fetchData");
}

const classThemeWholeDefault = {
  [ITransactionStatus.Approved]: "bg-success-container text-success",
  [ITransactionStatus.Denied]: "bg-error-container text-error",
  [ITransactionStatus.Analyzing]: "bg-information-container text-information",
  [ITransactionStatus.Canceled]: "bg-surface-dim text-on-surface-variant",
  [ITransactionStatus.None]: "",
};

const classThemeWhole: UseClassByThemeStatusProps = {
  light: {
    ...classThemeWholeDefault,
  },
  dark: {
    ...classThemeWholeDefault,
    [ITransactionStatus.Approved]:
      "bg-cs_ref_palette_success80 text-cs_ref_palette_success10",
    [ITransactionStatus.Denied]:
      "bg-cs_ref_palette_error95 text-cs_ref_palette_error10",
    [ITransactionStatus.Analyzing]:
      "bg-cs_ref_palette_information90 text-cs_ref_palette_information10",
  },
};

const classNameWhole = useClassByThemeStatus(classThemeWhole);

const classThemeTextDefault = {
  [ITransactionStatus.Approved]: "text-success",
  [ITransactionStatus.Denied]: "text-error",
  [ITransactionStatus.Analyzing]: "text-information",
  [ITransactionStatus.Canceled]: "text-dim",
  [ITransactionStatus.None]: "",
};

const classThemeText = {
  light: {
    ...classThemeTextDefault,
  },
  dark: {
    ...classThemeTextDefault,
  },
};

const classNameText = useClassByThemeStatus(classThemeText);
</script>

<style scoped lang="scss">
:deep(#transaction-card) {
  border-radius: var(--mdui-shape-corner-extra-large);
  box-shadow: var(--mdui-elevation-level1);
}

.container {
  display: flex;
  height: 48px;
  padding: 8px 20px 8px 8px;
  box-sizing: border-box;
  align-items: center;
  border-radius: var(--mdui-shape-corner-extra-large);

  & > div {
    &.id,
    &.value,
    &.status,
    &.date,
    &.actions {
      flex: 1;
    }
  }

  .status-icon.checkbox {
    width: 32px;
    margin-right: 16px;

    .v-icon {
      min-width: 32px;
      height: 32px;
      border-radius: var(--mdui-shape-corner-large);
    }

    .v-checkbox {
      display: none;
      justify-content: center;
      min-width: 32px;
    }
  }

  .status {
    .v-icon {
      $size: var(--mdui-typescale-body-small-size);

      width: $size;
      min-width: $size;
      height: $size;
      font-size: $size;

      margin-right: 4px;
    }
  }

  .date {
    text-align: right;
  }

  .actions {
    display: none;
    justify-content: flex-end;

    & > .v-btn {
      $size: 32px;
      width: $size;
      min-width: $size;
      height: $size;
    }
  }
}
</style>
