export const AddButtonStyleMobile = {
  borderRadius: "0.75rem",
  padding: "0.62rem 1.5rem 0.62rem 1rem",
  bottom: " 1.5rem",
  right: "1rem",
  zIndex: 9,
  height: "3.5rem",
};

export const AddButtonStyleDesktop = { height: '2.5rem' };

