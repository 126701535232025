const Label = "Value";
const Content = "value-button-filter";

const FromLabel = "From:";
const FromProperty = "from";
const ToLabel = "To:";
const ToProperty = "to";

export const ButtonFilterValueContent = {
  Label,
  Content,
  FromLabel,
  FromProperty,
  ToLabel,
  ToProperty,
};
