<template>
  <TabsSwitchComponentDesktop
    v-if="mdAndUp"
    :options="options"
    @click="(id) => emits('click', id)"
  />
  <TabsSwitchComponentMobile
    v-else
    :options="options"
    @click="(id) => emits('click', id)"
  />
</template>
<script setup lang="ts">
import TabsSwitchComponentDesktop from "@/components/one/TabsSwitchComponent/TabsSwitchComponentDesktop/TabsSwitchComponentDesktop.vue";
import TabsSwitchComponentMobile from "@/components/one/TabsSwitchComponent/TabsSwitchComponentMobile/TabsSwitchComponentMobile.vue";
import { TabsSwitchItem } from "@/model/tabSwitch";
import { DetailTransactionTabs } from "@/model/transaction";
import { useDisplay } from "vuetify";

defineProps<{ options: TabsSwitchItem<DetailTransactionTabs>[] }>();
const emits = defineEmits<(e: "click", id: DetailTransactionTabs) => void>();

const { mdAndUp } = useDisplay();
</script>
