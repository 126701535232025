import { useLocalStorage } from '@/utils/localstorage/localstorage';
import { oidcCallback } from "@/services/auth";
import { useRouter } from "vue-router";

export async function useOIDCCallback(code = '', redirect: () => void) {
  const router = useRouter()
  if (!code) {
    router.replace("/shield");
    return
  }

  useLocalStorage().removeStorage("token")
  localStorage.setItem("code", code);

  const response = await oidcCallback(code);
  const eventAddAccessToken = new CustomEvent('access-token');
  if (response?.access_token) {
    useLocalStorage().setStorage('token', JSON.stringify(response))
    dispatchEvent(eventAddAccessToken);

    localStorage.removeItem("code");

    const url = new URL(window.location.href);
    url.searchParams.delete('code');
    window.history.replaceState({}, document.title, url.toString());
    redirect()
  }
}
