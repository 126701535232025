<template>
  <div
    :data-testid="generateTestId(CustomHeroElements.Content)"
    id="hero"
    :style="{
      backgroundImage: `url('${srcImage}')`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% 100%',
      padding: `${mdAndUp ? '24px' : '12px'}`,
    }"
    class="h-100 w-100 position-relative rounded-xl"
  >
    <h1
      class="on-surface"
      :style="{
        fontSize: fontSize,
        lineHeight: lineHeight,
        fontWeight: fontWeight,
      }"
    >
      {{ label }}
    </h1>
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { useDisplay } from "vuetify";
import { generateTestId } from "@/helpers/generateTestId";
import { CustomHeroElements } from "./constants/CustomHeroElements";
import { tokens } from "@clearsale/tokens-ds";

const { mdAndUp } = useDisplay();

const fontSize = mdAndUp
  ? tokens.typography["typescale-display-large-size"]
  : tokens.typography["typescale-display-small-size"];
const lineHeight = mdAndUp
  ? tokens.typography["typescale-display-large-line-height"]
  : tokens.typography["typescale-display-small-line-height"];
const fontWeight = mdAndUp
  ? tokens.typography["typescale-display-large-weight"]
  : tokens.typography["typescale-display-small-weight"];

defineProps<{ label?: string; srcImage?: string }>();
</script>

<style scoped lang="scss"></style>
