import { OptionsItem } from "@clearsale/material-ds";

export const navigationItems = [
    {
        active: false,
        children: [],
        icon: "home--outlined",
        isOpen: false,
        label: "Casa",
        path: "/shield",
    },
    {
        active: false,
        children: [],
        icon: "dashboard--outlined",
        isOpen: false,
        label: "Dashboard",
        path: "/shield/dashboard",
    },
    {
        active: false,
        children: [],
        icon: "shopping_cart--outlined",
        isOpen: false,
        label: "Orders",
        path: "/shield/orders",
    },
    {
        active: false,
        children: [],
        icon: "rotate_left--outlined",
        isOpen: false,
        label: "Chargebacks",
        path: "/shield/chargebacks",
    },
    {
        active: false,
        isOpen: false,
        icon: "insert_chart--outlined",
        label: "Reports",
        path: "/shield/reports",
        children: [
            {
                active: false,
                isOpen: false,
                icon: "home--outlined",
                label: "Hello",
                path: "/hello",
                children: [
                    {
                        active: false,
                        icon: "home--outlined",
                        isOpen: false,
                        label: "Casa",
                        path: "/shield",
                        children: [],
                    }
                ],
            }
        ],
    },
] as OptionsItem[];