<template>
  <div
    :data-testid="generateTestId(ButtonFilterMobileId)"
    :class="filterClasses"
    @click="openBottomSheet"
  >
    <span>{{ label }}</span>
    <v-icon v-if="!active" icon="mdi-chevron-down" :size="IconSize" />
    <v-icon
      v-if="active"
      icon="mdi-close"
      :size="IconSize"
      :data-testid="generateTestId(ButtonFilterMobileClearButtonId)"
      @click.stop="clearFilter"
    />
  </div>
  <Teleport to="body">
    <BottomSheet
      v-model:active="activeBottomSheet"
      :height="height"
      @closed="onCloseBottomSheet"
    >
      <slot></slot>
    </BottomSheet>
  </Teleport>
</template>

<script setup lang="ts">
import BottomSheet from "@/components/shared/BottomSheet/BottomSheet.vue";
import {
  ButtonFilterMobileEmits,
  ButtonFilterMobileProps,
  useButtonFilterMobile,
} from "./hooks/useButtonFilterMobile";
import {
  ButtonFilterMobileId,
  ButtonFilterMobileClearButtonId,
  IconSize,
} from "./constants/ButtonFilterMobile";
import { generateTestId } from "@/helpers/generateTestId";

const props = withDefaults(defineProps<ButtonFilterMobileProps>(), {
  height: 90,
  active: false,
});

const emits = defineEmits<ButtonFilterMobileEmits>();

const {
  activeBottomSheet,
  filterClasses,
  openBottomSheet,
  onCloseBottomSheet,
  clearFilter,
} = useButtonFilterMobile(props, emits);
</script>

<style scoped>
div {
  box-sizing: border-box;
  padding: 0px 24px;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

span {
  font-size: 14px;
  font-weight: 500;
  margin-right: 8px;
}
</style>
