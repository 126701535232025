<template>
  <v-btn
    :icon="ExportButtonContentElements.ExportButtonIcon"
    class="export-button text-primary"
    :color="themeName === 'dark' ? 'surface' : 'surface-variant-bright'"
    :data-testeid="
      generateTestId(ExportButtonContentElements.ExportButtonContent)
    "
    :elevation="!mdAndUp ? 1 : 0"
    :style="mdAndUp ? {} : ExportButtonStyleMobile"
    :position="mdAndUp ? 'relative' : 'fixed'"
  >
  </v-btn>
</template>

<script lang="ts" setup>
import { useDisplay, useTheme } from "vuetify";

import { generateTestId } from "@/helpers/generateTestId";
import { ExportButtonContentElements } from "./constants/ExportButtonContent";
import { ExportButtonStyleMobile } from "./ExportButton.style";

const { mdAndUp } = useDisplay();
const { name: themeName } = useTheme();
</script>

<style lang="scss" scoped>
.export-button {
  width: 40px;
  height: 40px;
}
</style>
