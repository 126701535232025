<template>
  <div :style="{ padding: `${display.mdAndUp ? '0px 40px' : '0px 16px'}` }">
    <div :style="{ height: display.mdAndUp ? '220px' : '120px' }">
      <CustomHero
        class="d-flex align-end justify-space-between"
        label="Order details"
        :src-image="srcImage"
      />
    </div>
    <TabsSwitch
      :options="tabsSwitchOptions"
      @click="(id) => (currentTab = id)"
    />
    <div
      style="
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr minmax(100px, 600px) 1fr;
        justify-content: space-evenly;
        padding-top: 40px;
      "
    >
      <div style="grid-column: 2 / 3">
        <TransactionSummary
          v-if="currentTab == DetailTransactionTabs.Summary"
          :summary="transactionDetails?.summary"
        />
        <TransactionHistory
          v-if="currentTab == DetailTransactionTabs.History"
          :history="transactionDetails?.history"
        />
        <TransactionContacts
          v-if="currentTab == DetailTransactionTabs.Contacts"
          :billing="transactionDetails.contacts?.billing"
          :shipping="transactionDetails.contacts?.shipping"
        />
      </div>
      <ActionDetails
        :options="actionDetailsOptions"
        @click="(id) => console.log(id)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, computed, reactive } from "vue";
import { useDisplay, useTheme } from "vuetify";

import CustomHero from "@/components/shared/CustomHero/CustomHero.vue";
import TransactionSummary from "@/components/shared/TransactionSummary/TransactionSummary.vue";
import TransactionHistory from "@/components/shared/TransactionHistory/TransactionHistory.vue";
import TransactionContacts from "@/components/transactions/details/TransactionDetailsContacts/TransactionDetailsContacts.vue";
import TabsSwitch from "@/components/one/TabsSwitchComponent/TabsSwitch.vue";
import ActionDetails from "@/components/one/ActionDetailsComponent/ActionDetails.vue";

import imgDesktopLight from "@/assets/backgrounds/transaction-desktop-light.png";
import imgDesktopDark from "@/assets/backgrounds/transaction-desktop-dark.png";

import { DetailTransactionTabs } from "@/model/transaction";
import { TabsSwitchItem } from "@/model/tabSwitch";
import { ActionItem, ActionTransactionType } from "@/model/actionsDetails";
import { useRoute } from "vue-router";
import { ITransactionDetails } from "@/api/resources/Transactions/transactionResource";

const display = useDisplay();
const { name } = useTheme();
const route = useRoute();

const currentTab = ref(DetailTransactionTabs.Summary);

const transactionDetails = reactive<Partial<ITransactionDetails>>({});

onMounted(async () => {
  const { transactionId } = route.params;
  await fetchTransactionDetails(transactionId as string);
});

const srcImage = computed(() => {
  if (name.value == "light") {
    return imgDesktopLight;
  } else {
    return imgDesktopDark;
  }
});

const tabsSwitchOptions: TabsSwitchItem<DetailTransactionTabs>[] = [
  {
    id: DetailTransactionTabs.Summary,
    label: "Summary",
  },
  {
    id: DetailTransactionTabs.History,
    label: "History",
  },
  {
    id: DetailTransactionTabs.Items,
    label: "Items",
  },
  {
    id: DetailTransactionTabs.Payment,
    label: "Payment",
  },
  {
    id: DetailTransactionTabs.Contacts,
    label: "Contacts",
  },
];

const actionDetailsOptions: ActionItem<ActionTransactionType>[] = [
  {
    id: ActionTransactionType.ForceApproval,
    icon: "mdi-check",
    label: "Force approval",
  },
  {
    id: ActionTransactionType.Reanalyze,
    icon: "mdi-repeat",
    label: "Reanalyze",
  },
  {
    id: ActionTransactionType.Prioritize,
    icon: "mdi-priority-high",
    label: "Prioritize",
  },
  {
    id: ActionTransactionType.RequestChargeback,
    icon: "mdi-rotate-left",
    label: "Request chargeback",
  },
  {
    id: ActionTransactionType.CancelOrder,
    icon: "mdi-square",
    label: "Cancel order",
  },
];

async function fetchTransactionDetails(transactionId: string) {
  const dateResult = await fetch(`transactions/${transactionId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then(async (response) => {
    return response.json();
  });

  transactionDetails.summary = dateResult.summary;
  transactionDetails.contacts = dateResult.contacts;
  transactionDetails.history = dateResult.history;
}
</script>
