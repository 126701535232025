import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["data-testid"]

import { ref, watch } from "vue";
import { useDisplay, useTheme } from "vuetify";
import type { VCard } from "vuetify/components";
import { generateTestId } from "@/helpers/generateTestId";
import { TransactionCardElement } from "./constants/TransactionCardId";
import { useClassByTheme } from "@/components/hooks/useClassByTheme";
import { swipeUtility } from "@/utils/swipeUtility";


export default /*@__PURE__*/_defineComponent({
  ...{
  inheritAttrs: true,
},
  __name: 'TransactionCard',
  props: {
    active: { type: Boolean }
  },
  setup(__props: any) {



const props = __props;

const left = ref<VCard | null>(null);
const right = ref<VCard | null>(null);

const { mobile } = useDisplay();
const { name: themeName } = useTheme();

const classTheme = {
  light: "bg-cs_on_primary",
  dark: "bg-cs_surface_container_low",
};
const className = useClassByTheme(classTheme);

let hammer: any;
let onLoadEvents: any;

watch(
  [left, right, () => props.active],
  ([leftElement, rightElement, newActive]) => {
    if (!(leftElement && rightElement)) return;
    if (!mobile.value) return;

    if (newActive) {
      hammer.destroy();

      hammer = null;
      onLoadEvents = null;
    } else {
      if (!(hammer && onLoadEvents)) {
        registerSwipe(leftElement, rightElement);

        onLoadEvents(hammer);
        hammer.on();
      }
    }
  }
);

function registerSwipe(
  leftElement: typeof left.value,
  rightElement: typeof right.value
) {
  if (hammer && onLoadEvents) return;
  if (!(leftElement && rightElement)) return;

  let swipe = swipeUtility(leftElement.$el, {
    leftElement: rightElement.$el,
    rightElement: leftElement.$el,
    type: "LEFT",
  });

  hammer = swipe.hammer;
  onLoadEvents = swipe.onLoadEvents;
}

return (_ctx: any,_cache: any) => {
  const _component_v_card = _resolveComponent("v-card")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.$slots.default)
      ? _withDirectives((_openBlock(), _createBlock(_component_v_card, _mergeProps({ key: 0 }, _ctx.$attrs, {
          "data-testid": _unref(generateTestId)(_unref(TransactionCardElement)),
          id: "transaction-card",
          elevation: _unref(themeName) === 'dark' ? 1 : 0,
          style: 
      _unref(themeName) === 'light'
        ? 'box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05) !important'
        : ''
    ,
          flat: "",
          class: _unref(className)
        }), {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default")
          ]),
          _: 3
        }, 16, ["data-testid", "elevation", "style", "class"])), [
          [_directive_ripple]
        ])
      : _createCommentVNode("", true),
    (_ctx.$slots.left && _ctx.$slots.right)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          "data-testid": _unref(generateTestId)(_unref(TransactionCardElement)),
          id: "container"
        }, [
          _createVNode(_component_v_card, _mergeProps(_ctx.$attrs, {
            ref_key: "left",
            ref: left,
            variant: "flat",
            id: "left",
            class: _unref(className),
            elevation: _unref(themeName) === 'dark' ? 1 : 0,
            style: 
          _unref(themeName) === 'light'
            ? 'box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05) !important'
            : ''
        ,
            flat: ""
          }), {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "left")
            ]),
            _: 3
          }, 16, ["class", "elevation", "style"]),
          _createVNode(_component_v_card, {
            class: _normalizeClass(_unref(className)),
            ref_key: "right",
            ref: right,
            variant: "flat",
            id: "right"
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "right")
            ]),
            _: 3
          }, 8, ["class"])
        ], 8, _hoisted_1))
      : _createCommentVNode("", true)
  ], 64))
}
}

})