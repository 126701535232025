<template>
  <v-toolbar
    class="action-bar bg-information-container text-tertiary"
    rounded="pill"
    :height="height"
  >
    <v-badge
      color="error"
      offset-y="8"
      offset-x="6"
      :content="lenght"
      class="action-bar__badge-content"
    >
      <v-checkbox-btn slot="default" indeterminate></v-checkbox-btn>
    </v-badge>
    <ContainerSwiper>
      <div class="action-bar__button-group">
        <v-btn
          v-for="(item, index) in ActionBarElements.ActionBarButtonVariations"
          :key="index"
          variant="text"
          :prepend-icon="item.icon"
          class="action-bar__button-group__button"
        >
          {{ t(`${label.Shared.TransactionActions._}.${item.label}`) }}
        </v-btn>
      </div>
    </ContainerSwiper>
  </v-toolbar>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import { ActionBarElements } from "./constants/ActionBarElements";
import ContainerSwiper from "@/components/shared/ContainerSwiper/ContainerSwiper.vue";
import { label } from "@/plugins/locales/global";

const { t } = useI18n();
defineProps<{ lenght: number; height: number }>();
</script>

<style lang="scss">
.action-bar {
  display: flex;
  align-items: center;
  align-self: stretch;
  gap: 0.25rem;
  padding: 0 0.5rem;

  &__badge-content {
    .v-badge__wrapper {
      .v-badge__badge {
        display: inline-flex;
        min-width: 1rem;
        max-width: 2.125rem;
        max-block-size: 1rem;
        padding: 0rem 0.25rem;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &__button-group {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    &__button {
      text-transform: none;
    }
  }
}
</style>
