import { ITransactionStatus } from "@/api/resources/Transactions/transactionResource";
import { Chip } from "@/model/chip";
import { useTransactionStore } from "@/store/TransactionStore";
import { computed, onMounted, reactive, ref, watch, withDefaults } from "vue";

// export type ButtonFilterStatusEmits = {};
export type ButtonFilterStatusProps = { open: boolean };

export enum ButtonFilterStatus {
  Analyzing = 0,
  Approved = 1,
  Denied = 2,
  Canceled = 3,
}

const statusOptions: Chip[] = [
  {
    chipId: ITransactionStatus.Analyzing,
    label: "Analyzing",
  },
  {
    chipId: ITransactionStatus.Approved,
    label: "Approved",
  },
  {
    chipId: ITransactionStatus.Denied,
    label: "Denied",
  },
  {
    chipId: ITransactionStatus.Canceled,
    label: "Canceled",
  },
];

export function useButtonFilterStatus() {
  const store = useTransactionStore();

  const statusActive = ref<Chip[]>([]);

  const isValid = computed(() => {
    return statusActive.value.length > 0;
  })

  function handleClearAll() {
    statusActive.value = [];
    store.$patch({ filters: { status: [] } });
  }

  function handleSubmitFilter() {
    const resultStatus: ITransactionStatus[] = statusActive.value.map(v => (v.chipId as number));

    store.$patch({ filters: { status: resultStatus } });
  }

  return {
    isValid,
    statusActive,
    statusOptions,
    handleClearAll,
    handleSubmitFilter,
  };
}
