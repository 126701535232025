import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  style: {"padding-top":"4px","transition":"padding-top ease-in-out 0.3s,\n            padding-bottom ease-in-out 0.3s"},
  class: "d-flex flex-row ga-2"
}

import TabsSwitch from "@/components/shared/TabsSwitch/TabsSwitch.vue";
import ContainerSwiper from "@/components/shared/ContainerSwiper/ContainerSwiper.vue";
import ContainerFixedTop from "@/components/shared/ContainerFixedTop/ContainerFixedTop.vue";

import { TabsSwitchItem } from "@/model/tabSwitch";
import { DetailTransactionTabs } from "@/model/transaction";
import { useStyleByTheme } from "@/components/hooks/useStyleByTheme";


export default /*@__PURE__*/_defineComponent({
  __name: 'TabsSwitchComponentMobile',
  props: {
    options: {}
  },
  emits: ["click"],
  setup(__props: any, { emit: __emit }) {

const returnButtonStyle = {
  light: {
    backgroundColor: "rgba(var(--v-ref-palette-neutral95))",
    color: "rgba(var(--v-ref-palette-neutral-variant30))",
  },
  dark: {
    backgroundColor: "rgba(var(--v-theme-surface-container-high))",
    color: "rgba(var(--v-theme-on-surface-variant))",
  },
};


const emits = __emit;

const computedReturnButtonStyle = useStyleByTheme(returnButtonStyle);

return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createBlock(ContainerFixedTop, { style: {"z-index":"9"} }, {
    default: _withCtx(() => [
      _createVNode(ContainerSwiper, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_v_btn, {
              ref: "refIcon",
              variant: "flat",
              density: "comfortable",
              size: "40px",
              style: _normalizeStyle(_unref(computedReturnButtonStyle)),
              color: "",
              icon: "mdi-arrow-left",
              to: "./"
            }, null, 8, ["style"]),
            _createVNode(TabsSwitch, {
              style: {"width":"545px"},
              options: _ctx.options,
              onSelected: _cache[0] || (_cache[0] = (id) => emits('click', id))
            }, null, 8, ["options"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})