<template>
  <div
    ref="refButtonFilter"
    :class="btnClasses.ButtonFilterBaseClass"
    class="position-relative"
  >
    <div
      :data-testid="generateTestId(btnIds.ButtonFilterContainerElement)"
      :class="buildCssClasses(themeName)"
      style="
        transition: background-color 1s;
        padding-right: 24px;
        padding-left: 24px;
        min-height: 40px;
      "
      @click.stop="handleOpenFilter"
    >
      <div style="display: flex; flex: 1 1 auto; align-items: center">
        <span
          :class="btnClasses.ButtonFilterLabelClass"
          class="position-relative d-flex align-center"
        >
          <span class="align-self-start" style="padding-top: 1px">{{
            label
          }}</span>
          <v-icon
            :class="btnClasses.ButtonFilterIconNormalClass"
            :style="displayNormalIcon"
            icon="mdi-chevron-down"
            :size="ICON_SIZE"
          />
          <v-icon
            :data-testid="generateTestId(btnIds.ButtonFilterButtonClearElement)"
            :class="btnClasses.ButtonFilterButtonClearClass"
            :style="displayClearButton"
            icon="mdi-close"
            :size="ICON_SIZE"
            @click.stop="handleClearFilter"
          />
        </span>
        <div
          :data-testid="generateTestId(btnIds.ButtonFilterSlotContainerElement)"
          :class="btnClasses.ButtonFilterSlotContainerClass"
          class="w-100"
          style="display: none; opacity: 0; visibility: hidden"
        >
          <slot />
        </div>
        <v-btn
          :data-testid="generateTestId(btnIds.ButtonFilterButtonSubmitElement)"
          :class="[btnClasses.ButtonFilterButtonSubmitClass, 'ml-auto']"
          :style="btnHidden"
          icon="mdi-check"
          variant="text"
          color=""
          size="24px"
          rounded="extra-large"
          @click.stop="handleSubmitFilter"
        />
      </div>
    </div>
    <v-btn
      :data-testid="generateTestId(btnIds.ButtonFilterButtonCloseElement)"
      :class="btnClasses.ButtonFilterButtonCloseClass"
      :style="btnHidden"
      class="top-0 right-0 position-absolute"
      icon="mdi-close"
      variant="flat"
      color="surface-container"
      size="40px"
      rounded="extra-large"
      @click.stop="handleCloseFilter"
    />
  </div>
</template>

<script setup lang="ts">
import { useTheme } from "vuetify";

import { generateTestId } from "@/helpers/generateTestId";

import * as btnIds from "./constants/ButtonFilterIds";
import * as btnClasses from "./constants/ButtonFilterClasses";
import { ICON_SIZE } from "./constants/ButtonFilterValues";
import {
  useButtonFilter,
  ButtonFilterEmits,
  ButtonFilterProps,
} from "./hooks/useButtonFilter";
import TransitionHooks from "./TransitionHooks.vue";
import { Ref, ref } from "vue";

const props = withDefaults(defineProps<ButtonFilterProps>(), {
  label: "test",
  open: false,
  active: false,
});

const emits = defineEmits<ButtonFilterEmits>();

const refButtonFilter = ref<Element | undefined>() as Ref<Element>;

const { name: themeName } = useTheme();
const {
  btnOpen,
  btnActive,
  btnHidden,
  buildCssClasses,
  displayNormalIcon,
  displayClearButton,
  handleOpenFilter,
  handleSubmitFilter,
  handleCloseFilter,
  handleClearFilter,
} = useButtonFilter(refButtonFilter, emits, props);
</script>

<style scoped lang="scss">
.button-filter {
  & &__label {
    font-size: 14px;
    font-weight: 500;
  }
}
</style>
