import { ref, watch } from "vue";
import { useTransactionStore } from "@/store/TransactionStore";

import { Chip } from "@/model/chip";

export enum TimePeriodEnum {
  ONE_WEEK = 0,
  MTD = 1,
  ONE_MONTH = 2,
  THREE_MONTHS = 3,
  SIX_MONTHS = 4,
  ONE_YEAR = 5,
  CUSTOM = 6,
}

export interface PayloadSubmitButtonFilter {
  chipId: TimePeriodEnum;
  label: string;
  date: Date[];
}

const timePeriodOptions = [
  {
    chipId: TimePeriodEnum.ONE_WEEK,
    label: "1 week",
  },
  {
    chipId: TimePeriodEnum.MTD,
    label: "MTD",
  },
  {
    chipId: TimePeriodEnum.ONE_MONTH,
    label: "1 month",
  },
  {
    chipId: TimePeriodEnum.THREE_MONTHS,
    label: "3 months",
  },
  {
    chipId: TimePeriodEnum.SIX_MONTHS,
    label: "6 months",
  },
  {
    chipId: TimePeriodEnum.ONE_YEAR,
    label: "1 year",
  },
  {
    chipId: TimePeriodEnum.CUSTOM,
    label: "Custom",
  },
];

export function useButtonFilterDate() {
  const actualDate = new Date();
  const dialogModel = ref(false);
  const datePickerModel = ref<Date[]>([]);
  const dateChips = ref<Date[]>([new Date(), actualDate]);
  const selectedTimePeriodMemory = ref<Chip | null>();
  const selectedTimePeriod = ref<Chip | null>();
  const selectedTimePeriodOld = ref<Chip | null>();
  const isDatePickerCancelledWithoutSelection = ref(false);

  const selectedTimePeriodModel = ref(selectedTimePeriodMemory.value ?? selectedTimePeriod)

  const transactionStore = useTransactionStore();

  function getStartDateForPeriod(
    period: TimePeriodEnum,
    currentDate: Date
  ): Date {
    const startDate = new Date(currentDate);

    switch (period) {
      case TimePeriodEnum.ONE_WEEK:
        startDate.setDate(startDate.getDate() - 8);
        break;
      case TimePeriodEnum.MTD:
        startDate.setDate(1);
        break;
      case TimePeriodEnum.ONE_MONTH:
        startDate.setMonth(startDate.getMonth() - 1);
        break;
      case TimePeriodEnum.THREE_MONTHS:
        startDate.setMonth(startDate.getMonth() - 3);
        break;
      case TimePeriodEnum.SIX_MONTHS:
        startDate.setMonth(startDate.getMonth() - 6);
        break;
      case TimePeriodEnum.ONE_YEAR:
        startDate.setFullYear(startDate.getFullYear() - 1);
        break;
    }

    return startDate;
  }

  function submitDatePicker(date: Date[]) {
    datePickerModel.value = date;
    dialogModel.value = false;
  }

  function cancelDatePicker() {
    dialogModel.value = false;
    cancelWithoutDateSelected();
  }

  function cancelWithoutDateSelected() {
    if (!datePickerModel.value.length) {
      isDatePickerCancelledWithoutSelection.value = true;
      selectOldTimePeriod();
      return;
    }
    isDatePickerCancelledWithoutSelection.value = false;
  }

  function selectChip() {
    if(selectedTimePeriod.value && selectedTimePeriod.value.chipId !== TimePeriodEnum.CUSTOM) {
      dateChips.value[0] = getStartDateForPeriod(
        selectedTimePeriodModel.value?.chipId as TimePeriodEnum,
        actualDate
      );
      return
    }
    selectCustomChip()
  }

  function selectCustomChip() {
    if(!selectedTimePeriod.value && selectedTimePeriodMemory.value?.chipId === TimePeriodEnum.CUSTOM) {
      dialogModel.value = true
      selectedTimePeriod.value = {
        chipId: TimePeriodEnum.CUSTOM,
        label: "Custom",
      }
    }
    if(!selectedTimePeriod.value) {
      return
    }
    if(selectedTimePeriod.value && selectedTimePeriod.value.chipId === TimePeriodEnum.CUSTOM) {
      dialogModel.value = true
      return
    }
    if(selectedTimePeriodMemory.value && selectedTimePeriodMemory.value.chipId === TimePeriodEnum.CUSTOM) {
      dialogModel.value = true
      selectedTimePeriod.value = selectedTimePeriodMemory.value
    }
  }

  function selectOldTimePeriod() {
    if (selectedTimePeriodOld.value) {
      selectedTimePeriodModel.value = selectedTimePeriodOld.value;
      return;
    }
    selectedTimePeriodModel.value = null;
  }

  function selectOldTimePeriodByWatch(oldValue: Chip | null | undefined) {
    if (oldValue && oldValue.chipId !== TimePeriodEnum.CUSTOM) {
      selectedTimePeriodOld.value = oldValue;
    }
  }

  function submitButtonFilter(submitFilterFn: () => void) {
    if (selectedTimePeriodModel.value && selectedTimePeriodModel.value?.chipId !== TimePeriodEnum.CUSTOM) {
      selectedTimePeriodMemory.value = selectedTimePeriod.value
      const [dateFrom, dateTo] = dateChips.value;

      submitFilterFn();

      transactionStore.$patch({
        filters: { dateFrom, dateTo },
      });
      transactionStore.$patch({ page: 1, offset: 20 });

      datePickerModel.value = []

      return;
    } else if (selectedTimePeriodModel.value?.chipId === TimePeriodEnum.CUSTOM) {
      selectedTimePeriodMemory.value = selectedTimePeriod.value
      submitFilterFn();

      transactionStore.$patch({
        filters: {
          dateFrom: datePickerModel.value[0],
          dateTo: datePickerModel.value[datePickerModel.value.length - 1],
        },
      });
      transactionStore.$patch({ page: 1, offset: 20 });
      return
    }
    submitFilterFn();
    transactionStore.$patch({
      filters: {
        dateFrom: null,
        dateTo: null
      },
    });
    transactionStore.$patch({ page: 1, offset: 20 });
  }

  function clearAllFilter() {
    selectedTimePeriod.value = null;
    selectedTimePeriodMemory.value = null;
    selectedTimePeriodOld.value = null;
    datePickerModel.value = [];
    dateChips.value = [new Date(), actualDate];
    dialogModel.value = false;
    isDatePickerCancelledWithoutSelection.value = false;

    transactionStore.$patch({ filters: { dateFrom: null, dateTo: null } });
    transactionStore.$patch({ page: 1, offset: 20 });
  }

  function handleCloseFilter() {
    if (selectedTimePeriodMemory.value) {
      selectedTimePeriod.value = selectedTimePeriodMemory.value;
      return
    }
    selectedTimePeriod.value = null
  }

  watch(selectedTimePeriodModel, (_, oldValue) => {
    selectChip()
    selectOldTimePeriodByWatch(oldValue)
  });

  return {
    timePeriodOptions,
    selectedTimePeriodMemory,
    selectedTimePeriodModel,
    submitButtonFilter,
    clearAllFilter,
    handleCloseFilter,
    dialogModel,
    datePickerModel,
    cancelDatePicker,
    submitDatePicker,
  };
}
