<template>
  <ButtonFilterMobile
    :label="'Value'"
    :active="isValid"
    :height="30"
    @close-filter="handleSubmitFilter"
    @clear-filter="handleClearAll"
  >
    <h1 class="bg-on-surface text-left text-h5 mb-6">Value</h1>
    <div class="d-flex ga-6">
      <div>
        <input
          :data-testid="
            generateTestId(btnContent.ButtonFilterValueMobileFromElement)
          "
          class="w-100 pb-1 border-b-thin"
          type="number"
          name="from"
          id="from"
          placeholder="From"
          v-model="fromInput"
        />
      </div>
      <div>
        <input
          :data-testid="
            generateTestId(btnContent.ButtonFilterValueMobileToElement)
          "
          class="w-100 pb-1 border-b-thin"
          type="number"
          name="to"
          id="to"
          placeholder="To"
          v-model="toInput"
        />
      </div>
    </div>
  </ButtonFilterMobile>
</template>
<script setup lang="ts">
import { ref, computed } from "vue";
import ButtonFilterMobile from "@/components/shared/ButtonFilterMobile/ButtonFilterMobile.vue";
import { generateTestId } from "@/helpers/generateTestId";
import { ButtonFilterValueMobileContent as btnContent } from "./constants/ButtonFilterValueMobileElements";
import { useButtonFilterValueMobile } from "./hooks/useButtonFilterValueMobile";

const { fromInput, toInput, isValid, handleSubmitFilter, handleClearAll } =
  useButtonFilterValueMobile();
</script>

<style>
input[type="number"] {
  outline: none;

  &::placeholder {
    color: rgb(var(--mdui-ref-palette-neutral70));
  }
}
</style>
