// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/backgrounds/transaction-mobile-light.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@/assets/backgrounds/transaction-mobile-dark.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#summary-card[data-v-54c2b42f]{box-sizing:border-box;border-radius:28px!important;width:180px;display:flex;flex-direction:column}#summary-card.light.transaction[data-v-54c2b42f]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}#summary-card.light.approved[data-v-54c2b42f]{background-color:rgb(var(--mdui-ref-palette-success90));color:rgb(var(--mdui-ref-palette-success50))}#summary-card.light.denied[data-v-54c2b42f]{background-color:rgb(var(--mdui-ref-palette-error90));color:rgb(var(--mdui-ref-palette-error30))}#summary-card.light.analyzing[data-v-54c2b42f]{background-color:rgb(var(--mdui-ref-palette-information80));color:rgb(var(--mdui-ref-palette-information40))}#summary-card.light.canceled[data-v-54c2b42f]{background-color:rgb(var(--mdui-ref-palette-neutral80));color:rgb(var(--mdui-ref-palette-neutral-variant30))}#summary-card.light p[data-v-54c2b42f]{color:rgb(var(--mdui-ref-palette-black))}#summary-card.dark.transaction[data-v-54c2b42f]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}#summary-card.dark.Approved[data-v-54c2b42f]{background-color:rgb(var(--mdui-ref-palette-success30));color:rgb(var(--mdui-ref-palette-success80))}#summary-card.dark.Denied[data-v-54c2b42f]{background-color:rgb(var(--mdui-ref-palette-error15));color:rgb(var(--mdui-ref-palette-error70))}#summary-card.dark.Analyzing[data-v-54c2b42f]{background-color:rgb(var(--mdui-ref-palette-information20));color:rgb(var(--mdui-ref-palette-information80))}#summary-card.dark.Canceled[data-v-54c2b42f]{background-color:rgb(var(--mdui-ref-palette-neutral20));color:rgb(var(--mdui-ref-palette-neutral-variant80))}#summary-card.dark p[data-v-54c2b42f]{color:rgb(var(--mdui-ref-palette-neutral90))}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
