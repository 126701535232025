import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["data-testid"]
const _hoisted_2 = { class: "on-surface-variant" }
const _hoisted_3 = { class: "elevation-1 rounded-xl pa-6" }
const _hoisted_4 = { class: "d-flex justify-space-between" }
const _hoisted_5 = { class: "d-flex justify-space-between" }
const _hoisted_6 = { class: "d-flex justify-space-between" }
const _hoisted_7 = { class: "on-surface-variant" }
const _hoisted_8 = { class: "elevation-1 rounded-xl pa-6" }
const _hoisted_9 = { class: "d-flex justify-space-between" }
const _hoisted_10 = {
  key: 0,
  class: "d-flex justify-space-between"
}
const _hoisted_11 = { class: "on-surface-variant" }
const _hoisted_12 = { class: "elevation-1 rounded-xl pa-6" }
const _hoisted_13 = { class: "d-flex justify-space-between" }
const _hoisted_14 = { class: "on-surface-variant" }
const _hoisted_15 = { class: "elevation-1 rounded-xl pa-6" }
const _hoisted_16 = { class: "d-flex justify-space-between" }
const _hoisted_17 = { class: "d-flex justify-space-between" }

import { generateTestId } from "@/helpers/generateTestId";
import { ITransactionStatus } from "@/api/resources/Transactions/transactionResource";
import { transactionStatusIcon } from "@/model/transaction";
import { computed } from "vue";
import TransactionSummaryConstants from "./constants/TransactionSummaryId";
import { useDate, useDisplay, useTheme } from "vuetify/lib/framework.mjs";
import { TransactionDetailsSummaryProps } from "./constants/TransactionSummaryProps";
import { useCardColors } from "@/components/hooks/useCardColors";
import { useI18n } from "vue-i18n";
import { label } from "@/plugins/locales/global";


export default /*@__PURE__*/_defineComponent({
  __name: 'TransactionSummary',
  props: {
    summary: {}
  },
  setup(__props: any) {

const { t } = useI18n();
const props = __props;

const { name: theme } = useTheme();
const transactionStatusTheme = computed(() => {
  if (!props.summary?.status) return "";
  return ITransactionStatus[props.summary?.status].toLowerCase();
});

const chargebackStatusTheme = computed(() => {
  if (!props.summary?.chargebackStatus) return "";
  //TODO add chargeback status
  return ITransactionStatus[props.summary?.chargebackStatus]?.toLowerCase();
});

const { mdAndUp } = useDisplay();
const date = useDate();
const { titleComputedClass, contentComputedClass } = useCardColors();

const orderDate = computed(() => {
  return isTodayDate(props.summary?.consumerRequestedAt as Date)
    ? date.format(props.summary?.consumerRequestedAt, "fullTime12h")
    : date.format(props.summary?.consumerRequestedAt, "fullDate");
});

const clearSaleDate = computed(() => {
  return isTodayDate(props.summary?.clearSaleDate as Date)
    ? date.format(props.summary?.clearSaleDate, "fullTime12h")
    : date.format(props.summary?.clearSaleDate, "fullDate");
});

const displaySizeClass = computed(() => (mdAndUp.value ? "md" : "sm"));

function isTodayDate(date: Date) {
  const dateNow = new Date(date);
  const todayDate = new Date();
  todayDate.setHours(0, 0, 0, 0);
  dateNow.setHours(0, 0, 0, 0);

  return dateNow.getTime() === todayDate.getTime();
}

return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_openBlock(), _createElementBlock("div", {
    "data-testid": 
      _unref(generateTestId)(_unref(TransactionSummaryConstants).TransactionSummary)
    ,
    class: _normalizeClass(['content', displaySizeClass.value])
  }, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_unref(t)(
          _unref(label).TransactionDetailsPage.DetailTransactionTabs.Summary.Order.title
        )), 1),
    _createElementVNode("section", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("span", {
          class: _normalizeClass(_unref(titleComputedClass))
        }, _toDisplayString(_unref(t)(_unref(label).TransactionDetailsPage.DetailTransactionTabs.Summary.Order.ID)), 3),
        _createElementVNode("span", {
          class: _normalizeClass(_unref(contentComputedClass))
        }, "#" + _toDisplayString(_ctx.summary?.id), 3)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("span", {
          class: _normalizeClass(_unref(titleComputedClass))
        }, _toDisplayString(_unref(t)(
            _unref(label).TransactionDetailsPage.DetailTransactionTabs.Summary.Order
              .Store
          )), 3),
        _createElementVNode("span", {
          class: _normalizeClass(_unref(contentComputedClass))
        }, _toDisplayString(_ctx.summary?.entityName), 3)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("span", {
          class: _normalizeClass(_unref(titleComputedClass))
        }, _toDisplayString(_unref(t)(
            _unref(label).TransactionDetailsPage.DetailTransactionTabs.Summary.Order
              .Value
          )), 3),
        _createElementVNode("span", {
          class: _normalizeClass(_unref(contentComputedClass))
        }, "$" + _toDisplayString(_ctx.summary?.totalAmount), 3)
      ])
    ]),
    _createElementVNode("h2", _hoisted_7, _toDisplayString(_unref(t)(
          _unref(label).TransactionDetailsPage.DetailTransactionTabs.Summary.Status
            .title
        )), 1),
    _createElementVNode("section", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("span", {
          class: _normalizeClass(_unref(titleComputedClass))
        }, _toDisplayString(_unref(t)(
            _unref(label).TransactionDetailsPage.DetailTransactionTabs.Summary.Status
              .TransactionStatus
          )), 3),
        (_ctx.summary?.status)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass([['status', _unref(theme), transactionStatusTheme.value], "d-flex align-center"])
            }, [
              _createVNode(_component_v_icon, {
                icon: _unref(transactionStatusIcon)[_ctx.summary.status],
                size: 18
              }, null, 8, ["icon"]),
              _createTextVNode(" " + _toDisplayString(_unref(t)(
              `${_unref(label).Shared.TransactionStatus._}.${
                _unref(ITransactionStatus)[_ctx.summary.status]
              }`
            )), 1)
            ], 2))
          : _createCommentVNode("", true)
      ]),
      (_ctx.summary?.chargebackStatus)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createElementVNode("span", {
              class: _normalizeClass(_unref(titleComputedClass))
            }, _toDisplayString(_unref(t)(
            _unref(label).TransactionDetailsPage.DetailTransactionTabs.Summary.Status
              .ChargebackStatus
          )), 3),
            _createElementVNode("span", {
              class: _normalizeClass([['status', _unref(theme), chargebackStatusTheme.value], "d-flex align-center"])
            }, [
              _createVNode(_component_v_icon, {
                icon: _unref(transactionStatusIcon)[_ctx.summary?.chargebackStatus],
                size: 18
              }, null, 8, ["icon"]),
              _createTextVNode(" " + _toDisplayString(_unref(t)(
              `${_unref(label).Shared.TransactionStatus._}.${
                _unref(ITransactionStatus)[_ctx.summary.chargebackStatus]
              }`
            )), 1)
            ], 2)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("h2", _hoisted_11, _toDisplayString(_unref(t)(
          _unref(label).TransactionDetailsPage.DetailTransactionTabs.Summary.Costumer
            .title
        )), 1),
    _createElementVNode("section", _hoisted_12, [
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("span", {
          class: _normalizeClass(_unref(titleComputedClass))
        }, _toDisplayString(_unref(t)(
            _unref(label).TransactionDetailsPage.DetailTransactionTabs.Summary.Costumer
              .Email
          )), 3),
        _createElementVNode("span", {
          class: _normalizeClass(_unref(contentComputedClass))
        }, [
          _createVNode(_component_v_icon, {
            icon: "mdi-email-outline",
            size: 18
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.summary?.email), 1)
        ], 2)
      ])
    ]),
    _createElementVNode("h2", _hoisted_14, _toDisplayString(_unref(t)(
          _unref(label).TransactionDetailsPage.DetailTransactionTabs.Summary.Dates.title
        )), 1),
    _createElementVNode("section", _hoisted_15, [
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("span", {
          class: _normalizeClass(_unref(titleComputedClass))
        }, _toDisplayString(_unref(t)(
            _unref(label).TransactionDetailsPage.DetailTransactionTabs.Summary.Dates
              .TransactionDate
          )), 3),
        _createElementVNode("span", {
          class: _normalizeClass(_unref(contentComputedClass))
        }, _toDisplayString(orderDate.value), 3)
      ]),
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("span", {
          class: _normalizeClass(_unref(titleComputedClass))
        }, _toDisplayString(_unref(t)(
            _unref(label).TransactionDetailsPage.DetailTransactionTabs.Summary.Dates
              .ClearSaleDate
          )), 3),
        _createElementVNode("span", {
          class: _normalizeClass(_unref(contentComputedClass))
        }, _toDisplayString(clearSaleDate.value), 3)
      ])
    ])
  ], 10, _hoisted_1))
}
}

})