<template>
  <v-btn
    :prepend-icon="AddButtonContentElements.AddButtonContentIcon"
    class="add-button text-primary"
    :color="themeName === 'dark' ? 'surface' : 'surface-variant-bright'"
    :data-testeid="generateTestId(AddButtonContentElements.AddButtonContent)"
    :elevation="!mdAndUp ? 1 : 0"
    :style="mdAndUp ? AddButtonStyleDesktop : AddButtonStyleMobile"
    :position="mdAndUp ? 'relative' : 'fixed'"
  >
    {{ AddButtonContentElements.AddButtonContentLabel }}
  </v-btn>
</template>

<script lang="ts" setup>
import { useDisplay, useTheme } from "vuetify";
import { generateTestId } from "@/helpers/generateTestId";
import { AddButtonContentElements } from "./constants/AddButtonContent";
import { AddButtonStyleDesktop, AddButtonStyleMobile } from "./AddButton.style";

const { mdAndUp } = useDisplay();
const { name: themeName } = useTheme();
</script>

<style scoped lang="scss">
.add-button {
  text-transform: none;
}
</style>
