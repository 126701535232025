import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { useDisplay, useTheme } from "vuetify";
import { generateTestId } from "@/helpers/generateTestId";
import { AddButtonContentElements } from "./constants/AddButtonContent";
import { AddButtonStyleDesktop, AddButtonStyleMobile } from "./AddButton.style";
import { useI18n } from "vue-i18n";
import { label } from "@/plugins/locales/global";


export default /*@__PURE__*/_defineComponent({
  __name: 'AddButton',
  setup(__props) {

const { t } = useI18n();
const { mdAndUp } = useDisplay();
const { name: themeName } = useTheme();

return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createBlock(_component_v_btn, {
    "prepend-icon": _unref(AddButtonContentElements).AddButtonContentIcon,
    class: "add-button text-primary",
    color: _unref(themeName) === 'dark' ? 'surface' : 'surface-variant-bright',
    "data-testeid": _unref(generateTestId)(_unref(AddButtonContentElements).AddButtonContent),
    elevation: !_unref(mdAndUp) ? 1 : 0,
    style: _normalizeStyle(_unref(mdAndUp) ? _unref(AddButtonStyleDesktop) : _unref(AddButtonStyleMobile)),
    position: _unref(mdAndUp) ? 'relative' : 'fixed'
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_unref(t)(_unref(label).AddOrder)), 1)
    ]),
    _: 1
  }, 8, ["prepend-icon", "color", "data-testeid", "elevation", "style", "position"]))
}
}

})