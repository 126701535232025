export const typography = {
  'typescale-display-large-weight': 400,
  'typescale-display-medium-weight': 400,
  'typescale-display-small-weight': 400,
  'typescale-display-large-line-height': '4rem',
  'typescale-display-medium-line-height': '3.25rem',
  'typescale-display-small-line-height': '2.75rem',
  'typescale-display-large-size': '3.5625rem',
  'typescale-display-medium-size': '2.8125rem',
  'typescale-display-small-size': '2.25rem',
  'typescale-display-large-tracking': '0rem',
  'typescale-display-medium-tracking': '0rem',
  'typescale-display-small-tracking': '0rem',
  'typescale-headline-large-weight': 400,
  'typescale-headline-medium-weight': 400,
  'typescale-headline-small-weight': 400,
  'typescale-headline-large-line-height': '2.5rem',
  'typescale-headline-medium-line-height': '2.25rem',
  'typescale-headline-small-line-height': '2rem',
  'typescale-headline-large-size': '2rem',
  'typescale-headline-medium-size': '1.75rem',
  'typescale-headline-small-size': '1.5rem',
  'typescale-headline-large-tracking': '0rem',
  'typescale-headline-medium-tracking': '0rem',
  'typescale-headline-small-tracking': '0rem',
  'typescale-title-large-weight': 400,
  'typescale-title-medium-weight': 500,
  'typescale-title-small-weight': 500,
  'typescale-title-large-line-height': '1.75rem',
  'typescale-title-medium-line-height': '1.5rem',
  'typescale-title-small-line-height': '1.25rem',
  'typescale-title-large-size': '1.375rem',
  'typescale-title-medium-size': '1rem',
  'typescale-title-small-size': '0.875rem',
  'typescale-title-large-tracking': '0rem',
  'typescale-title-medium-tracking': '0.009375rem',
  'typescale-title-small-tracking': '0.00625rem',
  'typescale-label-large-weight': 500,
  'typescale-label-medium-weight': 500,
  'typescale-label-small-weight': 500,
  'typescale-label-large-line-height': '1.25rem',
  'typescale-label-medium-line-height': '1rem',
  'typescale-label-small-line-height': '0.375rem',
  'typescale-label-large-size': '0.875rem',
  'typescale-label-medium-size': '0.75rem',
  'typescale-label-small-size': '0.6875rem',
  'typescale-label-large-tracking': '0.00625rem',
  'typescale-label-medium-tracking': '0.03125rem',
  'typescale-label-small-tracking': '0.03125rem',
  'typescale-body-large-weight': 400,
  'typescale-body-medium-weight': 400,
  'typescale-body-small-weight': 400,
  'typescale-body-large-line-height': '1.5rem',
  'typescale-body-medium-line-height': '1.25rem',
  'typescale-body-small-line-height': '1rem',
  'typescale-body-large-size': '1rem',
  'typescale-body-medium-size': '0.875rem',
  'typescale-body-small-size': '0.75rem',
  'typescale-body-large-tracking': '0.009375rem',
  'typescale-body-medium-tracking': '0.015625rem',
  'typescale-body-small-tracking': '0.025rem'
}
