<template>
  <div :class="['content', displaySizeClass]">
    <template v-if="device">
      <h2 class="on-surface-variant">
        {{
          t(
            label.TransactionDetailsPage.DetailTransactionTabs.Payment.Device
              .title
          )
        }}
      </h2>
      <section class="elevation-1 rounded-xl pa-6">
        <div class="d-flex justify-space-between">
          <span :class="titleComputedClass">{{
            t(
              label.TransactionDetailsPage.DetailTransactionTabs.Payment.Device
                .IPAddress
            )
          }}</span>
          <span :class="contentComputedClass">{{ device.ipAddress }}</span>
        </div>
      </section>
    </template>

    <template v-for="([, payments], i) of computedPaymentsMap" :key="i">
      <template v-for="(payment, j) in payments" :key="payment.id">
        <h2 class="on-surface-variant">
          {{ IPaymentType[payment.type] }}
          <span v-if="(payments?.length ?? 0) > 1"> #{{ j + 1 }} </span>
        </h2>
        <section class="card elevation-1 rounded-xl pa-6">
          <div class="d-flex justify-space-between">
            <span :class="titleComputedClass">{{
              t(
                label.TransactionDetailsPage.DetailTransactionTabs.Payment
                  .PaymentType.Name
              )
            }}</span>
            <span :class="contentComputedClass">{{ payment.name }}</span>
          </div>
          <div class="d-flex justify-space-between">
            <span :class="titleComputedClass">{{
              t(
                label.TransactionDetailsPage.DetailTransactionTabs.Payment
                  .PaymentType.Number
              )
            }}</span>
            <span :class="contentComputedClass">
              <v-icon
                v-if="payment.type == IPaymentType.CartaoDebito"
                icon="mdi-exit-to-app"
              />
              <v-icon
                v-if="payment.type == IPaymentType.CreditCard"
                icon="mdi-credit-card-outline"
              />
              {{ payment.number }}
            </span>
          </div>
          <div class="d-flex justify-space-between">
            <span :class="titleComputedClass">{{
              t(
                label.TransactionDetailsPage.DetailTransactionTabs.Payment
                  .PaymentType.Amount
              )
            }}</span>
            <span :class="contentComputedClass">{{
              payment.amount.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })
            }}</span>
          </div>
        </section>
      </template>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { IPaymentType } from "@/api/resources/Transactions/transactionResource";
import {
  TransactionPaymentProps,
  useTransactionPayments,
} from "./useTransactionPayments";
import { useDisplay } from "vuetify";
import { computed } from "vue";
import { useCardColors } from "@/components/hooks/useCardColors";
import { useI18n } from "vue-i18n";
import { label } from "@/plugins/locales/global";

const props = defineProps<TransactionPaymentProps>();
const { computedPaymentsMap } = useTransactionPayments(props.payments ?? []);
const { titleComputedClass, contentComputedClass } = useCardColors();

const { t } = useI18n();
const display = useDisplay();
const displaySizeClass = computed(() => (display.mdAndUp.value ? "md" : "sm"));
</script>

<style scoped lang="scss">
.card {
  display: grid;
}

.content {
  &.sm {
    font-size: var(--v-typescale-body-medium-size);

    section {
      &.card {
        gap: 0.5rem;
      }

      &:not(:last-of-type) {
        margin-bottom: 16px;
      }
    }

    h2 {
      font-size: var(--v-typescale-title-medium-size) !important;
      font-weight: var(--v-typescale-title-small-weight);
      margin-left: 8px;
      margin-bottom: 4px;
    }
  }

  &.md {
    font-size: var(--v-typescale-body-large-size);

    section {
      &.card {
        gap: 1rem;
      }

      &:not(:last-of-type) {
        margin-bottom: 40px;
      }
    }

    h2 {
      font-size: var(--v-typescale-title-large-size) !important;
      font-weight: var(--v-typescale-title-large-weight);
      margin-left: 16px;
      margin-bottom: 8px;
    }
  }
}
</style>
