import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "list_transaction__container" }


export default /*@__PURE__*/_defineComponent({
  __name: 'ListDesktopTransactionsSkeleton',
  props: {
  lines: {
    type: Number,
    default: 5,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.lines, (skeleton) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "transaction__container",
        key: skeleton
      }, _cache[0] || (_cache[0] = [
        _createElementVNode("div", { class: "transaction__container_icon" }, [
          _createElementVNode("span")
        ], -1),
        _createElementVNode("div", { class: "transaction__container_content" }, [
          _createElementVNode("div", null, [
            _createElementVNode("span")
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("span")
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("span")
          ]),
          _createElementVNode("div", { class: "last-child" }, [
            _createElementVNode("span")
          ])
        ], -1)
      ])))
    }), 128))
  ]))
}
}

})